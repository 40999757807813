export const INSIGHT_CONFIG = [
  {
    id: 'occupancyType',
    label: 'Occupancy Type',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Residential',
      },
      {
        value: 2,
        label: 'Commercial',
      },
    ],
  },
  {
    id: 'roofType',
    label: 'Roof Type',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Asphalt',
      },
      {
        value: 2,
        label: 'Wood shingles',
      },
      {
        value: 3,
        label: 'Clay Tiles',
      },
      {
        value: 4,
        label: 'Metal Roof',
      },
    ],
  },
  {
    id: 'fireResistiveVents',
    label: 'Fire Resistive Vents',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes - fire resistive vents installed',
      },
      {
        value: 2,
        label: 'No - fire resistive vents not installed',
      },
    ],
  },
  {
    id: 'woodDeck',
    label: 'Wood Deck',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'No deck',
      },
      {
        value: 2,
        label: 'Wood deck attached, debris/vegetation clearance unknown',
      },
      {
        value: 3,
        label: 'Wood deck attached, debris/vegetation cleared from under deck',
      },
      {
        value: 4,
        label: 'Wood deck attached, debris/vegetation present under deck',
      },
    ],
  },
  {
    id: 'windowPaneType',
    label: 'Window Pane Type',
    comments:
      'No Windows is valid for commercial occupancy type structures only.',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Multiple pane',
      },
      {
        value: 2,
        label: 'Single pane',
      },
      {
        value: 3,
        label: 'No windows',
      },
    ],
  },
  {
    id: 'enclosedEaves',
    label: 'Enclosed Eaves',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Enclosed',
      },
      {
        value: 2,
        label: 'Not enclosed',
      },
    ],
  },
  {
    id: 'exteriorMaterial',
    label: 'Exterior Material',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Wood siding',
      },
      {
        value: 2,
        label: 'Concrete or masonry siding',
      },
      {
        value: 3,
        label: 'Metal siding',
      },
    ],
  },
  {
    id: 'noncombustibleVerticalClearance',
    label: 'Noncombustible Vertical Clearance',
    comments:
      'As measured from the ground to the bottom of the exterior surface of the building.',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'More than 6 inches of non-combustible vertical clearance',
      },
      {
        value: 2,
        label: 'Less than 6 inches of non-combustible vertical clearance',
      },
    ],
  },
  {
    id: 'wildfirePreparedCommunity',
    label: 'Wildfire Prepared Community',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes',
      },
      {
        value: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'wildfireMitigationCertification',
    label: 'Wildfire Mitigation Certification',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes',
      },
      {
        value: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'constructionType',
    label: 'Construction Type',
    comments:
      'Mobile home is valid for `unknown` and `residential` occupancy type structures only.',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Frame',
      },
      {
        value: 2,
        label: 'Joisted masonry',
      },
      {
        value: 3,
        label: 'Non-combustible',
      },
      {
        value: 4,
        label: 'Masonry non-combustible',
      },
      {
        value: 5,
        label: 'Modified fire-resistive',
      },
      {
        value: 6,
        label: 'Fire resistive',
      },
      {
        value: 99,
        label: 'Mobile home',
      },
    ],
  },
  {
    id: 'defensibleZone1',
    label: 'Defensible Zone 1',
    comments:
      'Clearance of vegetation cover and combustible materials within 5 feet of building',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes',
      },
      {
        value: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'defensibleZone2',
    label: 'Defensible Zone 2',
    comments:
      'Clearance of vegetation cover and combustible materials between 5 to 30 feet of building',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes',
      },
      {
        value: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'defensibleZone3',
    label: 'Defensible Zone 3',
    comments:
      'Effective fuel reduction and other mitigation measures greater than 30 feet to 100 feet of building',
    options: [
      {
        value: 0,
        label: 'Unknown',
      },
      {
        value: 1,
        label: 'Yes',
      },
      {
        value: 2,
        label: 'No',
      },
    ],
  },
  {
    id: 'yearBuilt',
    label: 'Year Built',
    comments:
      'Enter the year the structure was built or 0 if unknown. Valid years are 1700 to 2024.',
    options: [
      {
        type: 'number',
        placeholder: '1999',
      },
    ],
    validation: (data) => {
      if (data === 0) return false;
      return data < 1700 || data > new Date().getFullYear();
    },
    errorMessage: 'Invalid year value.',
  },
];
export const INSIGHT_CONFIG_SECTIONS = [
  {
    name: 'Primary',
    ids: ['occupancyType', 'constructionType', 'yearBuilt'],
  },
  {
    name: 'Secondary',
    ids: [
      'roofType',
      'fireResistiveVents',
      'woodDeck',
      'windowPaneType',
      'enclosedEaves',
      'exteriorMaterial',
      'noncombustibleVerticalClearance',
    ],
  },
  {
    name: 'Community',
    ids: ['wildfirePreparedCommunity', 'wildfireMitigationCertification'],
  },
  {
    name: 'Defensible Space',
    ids: ['defensibleZone1', 'defensibleZone2', 'defensibleZone3'],
  },
];

export const INSIGHT_INIT_VALUES = INSIGHT_CONFIG.reduce(
  (obj, item) => ({ ...obj, [item.id]: 0 }),
  {},
);
