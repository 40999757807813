export function AcreageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g id="20dp/area-location">
        <g id="vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 4.58333C8.50539 4.58333 7.375 5.86088 7.375 7.33333C7.375 8.80579 8.50539 10.0833 10 10.0833C11.4946 10.0833 12.625 8.80579 12.625 7.33333C12.625 5.86088 11.4946 4.58333 10 4.58333ZM8.875 7.33333C8.875 6.59665 9.42354 6.08333 10 6.08333C10.5765 6.08333 11.125 6.59665 11.125 7.33333C11.125 8.07002 10.5765 8.58333 10 8.58333C9.42354 8.58333 8.875 8.07002 8.875 7.33333Z"
            fill="black"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 1.25C6.7795 1.25 4.25 4.01993 4.25 7.33333C4.25 9.07244 5.01042 10.2498 6.02452 11.2938C6.45446 11.7364 6.94601 12.1712 7.44501 12.6126L7.6663 12.8086C8.24923 13.3258 8.85266 13.8728 9.45285 14.513C9.59463 14.6642 9.79269 14.75 10 14.75C10.2073 14.75 10.4054 14.6642 10.5472 14.513C11.1473 13.8728 11.7508 13.3258 12.3337 12.8086C12.4075 12.7431 12.4813 12.6778 12.555 12.6126C13.054 12.1712 13.5455 11.7364 13.9755 11.2938C14.9896 10.2498 15.75 9.07244 15.75 7.33333C15.75 4.01993 13.2205 1.25 10 1.25ZM5.75 7.33333C5.75 4.7557 7.69765 2.75 10 2.75C12.3023 2.75 14.25 4.7557 14.25 7.33333C14.25 8.53975 13.7604 9.36239 12.8995 10.2487C12.5122 10.6474 12.0678 11.0406 11.5639 11.4866L11.3382 11.6865C10.9151 12.0619 10.4624 12.4694 10 12.926C9.53765 12.4694 9.08487 12.0619 8.66183 11.6865L8.43614 11.4866C7.93215 11.0406 7.48775 10.6474 7.10048 10.2487C6.23958 9.36239 5.75 8.53975 5.75 7.33333Z"
            fill="black"
          />
          <path
            d="M4.69565 12.6892C5.07632 12.5259 5.25255 12.085 5.08927 11.7043C4.92599 11.3236 4.48503 11.1474 4.10435 11.3107C3.30723 11.6526 2.6139 12.0809 2.10906 12.5958C1.60092 13.114 1.25 13.7585 1.25 14.4997C1.25 15.2311 1.59194 15.8688 2.08945 16.3834C2.58346 16.8944 3.2621 17.3204 4.04251 17.6618C5.60556 18.3457 7.71243 18.7499 10 18.7499C12.2876 18.7499 14.3944 18.3457 15.9575 17.6618C16.7379 17.3204 17.4165 16.8944 17.9106 16.3834C18.4081 15.8688 18.75 15.2311 18.75 14.4997C18.75 13.7585 18.3991 13.114 17.8909 12.5958C17.3861 12.0809 16.6928 11.6526 15.8956 11.3107C15.515 11.1474 15.074 11.3236 14.9107 11.7043C14.7474 12.085 14.9237 12.5259 15.3044 12.6892C15.9883 12.9826 16.495 13.3146 16.8199 13.6459C17.1415 13.9739 17.25 14.2616 17.25 14.4997C17.25 14.7348 17.1442 15.018 16.8321 15.3409C16.5165 15.6674 16.0235 15.9957 15.3562 16.2876C14.0238 16.8706 12.1307 17.2499 10 17.2499C7.86929 17.2499 5.97616 16.8706 4.64378 16.2876C3.97648 15.9957 3.48355 15.6674 3.16791 15.3409C2.85577 15.018 2.75 14.7348 2.75 14.4997C2.75 14.2616 2.85854 13.9739 3.18012 13.6459C3.50501 13.3146 4.01168 12.9826 4.69565 12.6892Z"
            fill="black"
          />
        </g>
      </g>
    </svg>
  );
}
