import { useState, useRef, useEffect } from 'react';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import { CheckIcon } from '../icons/CheckIcon.jsx';
import { ClickOutside } from './ClickOutside.jsx';
import { classStr } from '../helpers.js';
import './Select.scss';

const registerOpenSelectHandlers = ({
  activeIndex,
  setActiveIndex,
  onChange,
  options,
  setOpen,
}) => {
  const keyDownCallback = (e) => {
    switch (e.key) {
      case 'Up': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'Down': // IE/Edge specific value
        if (e.srcElement.id === 'button-selected') break;
        e.preventDefault();
        setActiveIndex(
          activeIndex + 1 === options.length ? 0 : activeIndex + 1,
        );
        break;
      case 'ArrowDown':
        if (e.srcElement.id === 'button-selected') break;
        e.preventDefault();
        setActiveIndex(
          activeIndex + 1 === options.length ? 0 : activeIndex + 1,
        );
        break;
      case 'Enter':
        e.preventDefault();
        onChange(options[activeIndex].value);
        setOpen(false);
        break;
      case ' ': // Space
        e.preventDefault();
        // TODO(Baad): there is a bug where this case is closing the the select when we try to open it.
        // e.stopPropagation is disabling this whole case statement.
        if (e.srcElement.id === 'button-selected') break;
        onChange(options[activeIndex].value);
        setOpen(false);
        break;
      case 'Esc': // IE/Edge specific value
        e.preventDefault();
        setOpen(false);
        break;
      case 'Escape':
        e.preventDefault();
        setOpen(false);
        break;
      case 'PageUp': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(0);
        break;
      case 'Home':
        e.preventDefault();
        setActiveIndex(0);
        break;
      case 'PageDown': // IE/Edge specific value
        e.preventDefault();
        setActiveIndex(options.length - 1);
        break;
      case 'End':
        e.preventDefault();
        setActiveIndex(options.length - 1);
        break;
      case 'Tab':
        setOpen(false);
        break;
      default:
    }
  };
  document.addEventListener('keydown', keyDownCallback);

  return () => {
    document.removeEventListener('keydown', keyDownCallback);
  };
};

/*
  __ Props___
  id: a unique string to identify this select item
  options: list is expected to {value: xx, label: xx}
  onChange: fn to execute on change. args will be a string of the new value
  value: current value of the select
*/
export function Select(props) {
  const {
    className,
    disabled,
    placeholder,
    onChange,
    id,
    options,
    value,
    required,
  } = props;

  const ref = useRef('select');
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex((o) => o.value === value),
  );
  const selectedOption = options.filter((i) => i.value === value)?.[0];

  ClickOutside(ref, (e) => {
    // let the select's label act natively
    if (e.target.htmlFor !== id) setOpen(false);
  });

  useEffect(() => {
    if (open) {
      const activeElementId = document.activeElement.id;
      if (activeElementId === 'button-selected') {
        document.getElementById('button-selected').blur();
        document.querySelector(`#${id}_0`)?.focus();
      } else {
        document.querySelector(`#${id}_${activeIndex}`)?.focus();
      }

      return registerOpenSelectHandlers({
        activeIndex,
        setActiveIndex,
        options,
        onChange,
        setOpen,
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, activeIndex]);

  const buttonKeyDownHandler = (e) => {
    switch (e.key) {
      case 'Down': // IE/Edge specific value
        e.preventDefault();
        setOpen(true);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setOpen(true);
        break;
      case ' ':
        setOpen(true);
        break;
      case 'Enter':
        e.preventDefault();
        break;
      default:
    }
  };

  return (
    <div
      className={`${classStr({
        component: true,
        select: true,
        open,
        disabled,
        placeholder: value == undefined, // eslint-disable-line
      })} ${className ?? ''}`.trim()}
      ref={ref}
    >
      <button
        id={id}
        className={`select-button${open ? ' open' : ''}${
          value == undefined ? ' placeholder' : '' // eslint-disable-line
        }`}
        type="button"
        onClick={() => setOpen(!open)}
        onKeyDown={buttonKeyDownHandler}
        role="combobox"
        disabled={disabled}
        aria-haspopup="listbox"
        aria-controls={`${id}_select`}
        aria-labelledby={`${id}_label`}
        aria-expanded={open}
        aria-activedescendant={`${id}_element_${value}`}
      >
        <div>{selectedOption?.value ? selectedOption?.label : placeholder}</div>
        <input
          readOnly
          value={value ?? ''}
          required={required}
          disabled={disabled}
        />
        <ChevronIcon
          orientation={open ? 'up' : 'down'}
          className={`icon ${open ? 'open' : 'closed'}`}
        />
      </button>

      <div className="options-container">
        <ul
          className={`options ${open ? '' : 'hidden'}`.trim()}
          role="listbox"
          id={`${id}_select`}
          tabIndex={-1}
          aria-multiselectable={false}
        >
          {open &&
            options.map((item, index) => (
              <li
                key={item.value ?? "lol react doesn't allow a symbol here"}
                id={`${id}_${index}`}
                role="option"
                aria-selected={index === activeIndex}
                tabIndex="0"
                className={`option ${value === item.value ? 'selected' : ''}`}
                aria-controls={`${id}_select`}
                aria-labelledby={`${id}_label`}
                onClick={() => {
                  setOpen(!open);
                  onChange(item.value);
                }}
                onMouseOver={() => setActiveIndex(index)}
                onKeyDown={() => setActiveIndex(index)}
                onFocus={() => setActiveIndex(index)}
              >
                <span>{item.label}</span>
                {value === item.value && <CheckIcon />}
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
