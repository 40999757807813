import { useEffect } from 'react';
import { useSource } from './Source.jsx';

export function Layer(props) {
  const { filter, id, onClick } = props;
  const { deregisterLayer, registerLayer, updateLayer } = useSource();

  useEffect(() => {
    if (!id) throw new Error('layers must have an `id` prop');
    registerLayer(id, props);

    return () => {
      deregisterLayer(id);
    };
  }, []);

  useEffect(() => {
    updateLayer(id, props);
  }, [filter, onClick]);

  return null;
}
