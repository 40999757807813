import { useMapEffect } from '@redzone/map';
import { useMemo } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import './LineCollector.scss';

export function LineCollector(props) {
  const { onChange, value, showValue, ...rest } = props;

  const draw = useMemo(
    () =>
      new MapboxDraw({
        controls: {
          line_string: false,
          point: false,
          polygon: false,
          trash: false,
          combine_features: false,
          uncombine_features: false,
        },
        defaultMode: 'draw_line_string',
      }),
    [],
  );

  useMapEffect(
    (readyMap) => {
      readyMap.addControl(draw, 'bottom-right');
    },
    (readyMap) => {
      readyMap.removeControl(draw);
    },
    [],
  );

  const handleClear = () => {
    draw.deleteAll();
  };

  const handleFinish = () => {
    const polygon = draw.getAll();
    onChange(polygon);
  };

  return (
    <div className="line-collector component">
      <button {...rest} className="redwall secondary" type="button" onClick={handleFinish}>
        Calculate
      </button>
      <button {...rest} className="redwall secondary clear" type="button" onClick={handleClear}>
        Clear
      </button>
    </div>
  );
}
