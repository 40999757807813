const FORMAT_FORMATTERS = {
  'timestamp:milliseconds': (val) => {
    const date = new Date(Number(val));
    const formatter = new Intl.DateTimeFormat('en-US');

    return formatter.format(date);
  },
  'timestamp-milliseconds:datetime': (val) => {
    const date = new Date(Number(val));
    const formatter = new Intl.DateTimeFormat('en-US', {
      dateStyle: 'medium',
      timeStyle: 'long',
    });

    return formatter.format(date);
  },
  url: (val) => ({
    type: 'url',
    value: val,
  }),
};

const TYPE_FORMATTERS = {};

export function formatSchemaAttribute(schema, property, val) {
  if (!schema.attributes[property]) return val;

  const { format, type } = schema.attributes[property];

  if (format && FORMAT_FORMATTERS[format])
    return FORMAT_FORMATTERS[format](val);
  if (type && TYPE_FORMATTERS[type]) return TYPE_FORMATTERS[type](val);

  return val;
}
