import React, { useEffect, useState } from 'react';
import { Dropdown } from '@redzone/shared';
import { Step } from './Step.jsx';
import { AddressSearch } from '../location-search/AddressSearch.jsx';
import { CoordinatesSearch } from '../location-search/CoordinatesSearch.jsx';
import { PinSearch } from '../location-search/PinSearch.jsx';
import { SEARCH_TYPES } from '../../constants/location-type.js';
import './SelectLocation.scss';

export function SelectLocation(props) {
  const {
    location,
    pointLocation,
    setLocation,
    titleText,
    currentStep,
    setCurrentStep,
    handleNextStep,
    totalSteps,
    searchType,
    setSearchType,
    resetForm,
  } = props;

  // Tabs state
  const [lng, setLng] = useState(pointLocation?.[0] ?? '');
  const [lat, setLat] = useState(pointLocation?.[1] ?? '');
  const isComplete = currentStep > 1;

  const description = 'Please provide report location';

  const resetHandler = () => {
    setLng('');
    setLat('');
    setLocation(null);
    resetForm();
  };

  useEffect(() => {
    if (!pointLocation) {
      /* reset all location related items */
      resetHandler();
      // setSearchType(SEARCH_TYPES.ADDRESS);
      return;
    }
    /* if user drag the marker - update latLng */
    setLng(pointLocation[0]);
    setLat(pointLocation[1]);
  }, [pointLocation]);

  return (
    <section
      className={`step-wrapper ${
        currentStep === 1 && 'active'
      } component select-location`}
    >
      <Step
        titleText={titleText}
        step={1}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        totalSteps={totalSteps}
        isComplete={isComplete}
        description={description}
      />

      <div className={`step-container ${!isComplete ? 'show' : ''}`}>
        <Dropdown
          label="Search Type"
          options={Object.keys(SEARCH_TYPES).map((key) => ({
            label: SEARCH_TYPES[key],
            value: SEARCH_TYPES[key],
          }))}
          value={searchType}
          onChange={(e) => {
            resetHandler();
            setSearchType(e);
          }}
          namespace="search-type"
          withBorder
          wide
          data-cy="search-type-dropdown"
        />
        {searchType === SEARCH_TYPES.ADDRESS && (
          <AddressSearch
            location={location}
            setLocation={setLocation}
            pointLocation={pointLocation}
          />
        )}
        {searchType === SEARCH_TYPES.COORDINATES && (
          <CoordinatesSearch
            setLocation={setLocation}
            lng={lng}
            setLng={setLng}
            lat={lat}
            setLat={setLat}
          />
        )}
        {searchType === SEARCH_TYPES.PINSEARCH && (
          <PinSearch
            location={location}
            pointLocation={pointLocation}
            setLocation={setLocation}
          />
        )}
        <div className="button-container">
          <button
            type="button"
            className="redwall primary"
            disabled={!location}
            onClick={() => handleNextStep({ location, center: pointLocation })}
            data-cy="confirm-location"
          >
            Confirm
          </button>
          <button
            type="button"
            className="redwall secondary"
            disabled={!(location || lng || lat)}
            onClick={resetHandler}
          >
            Reset
          </button>
        </div>
      </div>
    </section>
  );
}
