import { useEffect, useMemo, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { useMap } from '@redzone/map';

export function InnerPolygonCollector(props) {
  const { instructions, collecting, toggleCollecting, onChange } = props;
  const { map } = useMap();
  const [error, setError] = useState(false);

  const draw = useMemo(
    () =>
      new MapboxDraw({
        controls: {
          line_string: false,
          point: false,
          polygon: true,
          trash: true,
          combine_features: false,
          uncombine_features: false,
        },
        defaultMode: 'draw_polygon',
      }),
    [],
  );

  useEffect(() => {
    if (collecting) {
      map.addControl(draw);
    }

    return () => {
      if (collecting) {
        map.removeControl(draw);
      }
    };
  }, [collecting, draw, map]);

  const handleClear = () => {
    draw.deleteAll();
  };

  const handleFinish = () => {
    const polygon = draw.getAll();
    const { coordinates } = polygon.features[0].geometry;

    if (coordinates[0].length > 3) {
      setError(false);
      onChange(polygon);
    } else {
      setError(true);
    }
  };

  return (
    <>
      {instructions && <div className="instructions">{instructions}</div>}
      <footer>
        <button
          type="button"
          className="redwall secondary"
          onClick={handleFinish}
        >
          Finish
        </button>
        <button
          type="button"
          className="redwall unstyled"
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          type="button"
          className="redwall unstyled cancel"
          onClick={toggleCollecting}
        >
          Cancel
        </button>
      </footer>
      {error && (
        <div className="error">
          Draw a polygon before pressing &ldquo;Finish.&rdquo;
        </div>
      )}
    </>
  );
}
