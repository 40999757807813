import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { Auth0Provider } from '@auth0/auth0-react';
import { RouterProvider } from 'react-router-dom';
import { RiskProvider } from './context/RiskContext.jsx';
import { router } from './components/app/Router.jsx';
import packagejson from '../package.json';

if (process.env.NODE_ENV === 'production') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'frontend-peril-dashboard-risk',
    env: process.env.REACT_APP_DEPLOY_ENVIRONMENT,
    // pull in version from package.json
    version: packagejson.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    allowedTracingUrls: [/https:\/\/.*\.(redzone\.zone|redzone\.co)/],
    traceSampleRate: 100,
  });

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_LOGS_CLIENT_TOKEN,
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <Auth0Provider
    domain={process.env.REACT_APP_API_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_API_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/init`,
      audience: process.env.REACT_APP_API_AUTH0_AUDIENCE,
    }}
    onRedirectCallback={(appState) => {
      localStorage.setItem('initialURL', appState.returnTo);
    }}
    // tied to auth0 application config, see https://auth0.com/docs/secure/tokens/refresh-tokens
    useRefreshTokens
  >
    <RiskProvider>
      <RouterProvider router={router} />
    </RiskProvider>
  </Auth0Provider>,
);
