import { useState, useRef, useEffect } from 'react';
import { ClickOutside } from '@redzone/shared';
import { classStr, toggle } from '../helpers.js';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import './MultiSelect.scss';

/*
  __ Props___
  namespace: a unique string to identify this select item
  options: list is expected to {value: xx, label: xx}
  onChange: fn to execute on change. args will be an array of the new values
  value: current values of the select
  header?: string to pass if the use want a header label for the select
  withBorder?: boolean. add border to the selected item and the list
  wide?: boolean. wide select btn - can be imporved.
  shouldFloat?: should the dropdown float is the options content is too long
  alwaysOpen?: the dropdown is being managed externally and should not close
*/
export function MultiSelect(props) {
  const {
    options,
    onChange,
    value = [],
    header,
    withBorder,
    wide,
    namespace,
    shouldFloat,
    placeholder,
    alwaysOpen,
  } = props;

  if (!namespace) {
    throw new Error('MultiSelect components must have a `namespace` prop.');
  }

  const ref = useRef('menu');
  const [activeIndex, setActiveIndex] = useState(
    options.findIndex((o) => o.value === value),
  );
  const [align, setAlignment] = useState('');

  const [open, _setOpen] = useState(alwaysOpen ?? false);
  const setOpen = (nextVal) => {
    _setOpen(alwaysOpen || nextVal);
  };

  const selectedOptions = options.filter((o) => value.includes(o.value));

  ClickOutside(ref, () => setOpen(false));

  const isElementFullyVisible = (element) => {
    const bound = element.getBoundingClientRect();
    const containerWidth = document
      .getElementsByClassName('drawer-wrapper')[0]
      .getBoundingClientRect().width;
    return containerWidth > bound.left + bound.width;
  };

  useEffect(() => {
    if (open && shouldFloat) {
      const list = document.getElementById(`${namespace}_dropdown`);
      if (isElementFullyVisible(list)) {
        setAlignment('');
      } else {
        setAlignment('left-align');
      }
    }
    return () => {
      setAlignment('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // TODO: focus first selected element or first element on open

  const buttonKeyDownHandler = (e) => {
    switch (e.key) {
      case 'Down': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
        break;
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        setOpen(true);
        break;
      case ' ':
        setOpen(true);
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        break;
      default:
    }
  };

  const keyDownCallback = (e) => {
    switch (e.key) {
      case 'Up': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'ArrowUp':
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(activeIndex <= 0 ? options.length - 1 : activeIndex - 1);
        break;
      case 'Down': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex((activeIndex + 1) % options.length);
        break;
      case 'ArrowDown':
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex((activeIndex + 1) % options.length);
        break;
      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        onChange(toggle(value, options[activeIndex].value));
        setOpen(false);
        break;
      case ' ': // Space
        e.preventDefault();
        e.stopPropagation();
        // TODO(Baad): there is a bug where this case is closing the the dropdown when we try to open it.
        // e.stopPropagation is disabling this whole case statement.
        if (e.srcElement.id === 'button-selected') break;
        onChange(toggle(value, options[activeIndex].value));
        setOpen(false);
        break;
      case 'Esc': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
        break;
      case 'Escape':
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
        break;
      case 'PageUp': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(0);
        break;
      case 'Home':
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(0);
        break;
      case 'PageDown': // IE/Edge specific value
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(options.length - 1);
        break;
      case 'End':
        e.preventDefault();
        e.stopPropagation();
        setActiveIndex(options.length - 1);
        break;
      case 'Tab':
        setOpen(false);
        break;
      default:
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={classStr({
        component: true,
        dropdown: true,
        multiple: true,
        open,
        wide,
      })}
      ref={ref}
      onKeyDown={keyDownCallback}
    >
      {header && <label htmlFor={header}>{header}</label>}
      {!alwaysOpen && (
        <button
          id="button-selected"
          className={classStr({
            'button-selected': true,
            'with-border': withBorder,
          })}
          type="button"
          onClick={() => setOpen(!open)}
          onKeyDown={buttonKeyDownHandler}
          role="combobox"
          aria-haspopup="listbox"
          aria-controls={`${namespace}_dropdown`}
          aria-labelledby={`${namespace}_label`}
          aria-expanded={open}
          aria-activedescendant={`${namespace}_element_${value}`}
        >
          {selectedOptions.length === 0 ? (
            <div className="no-selections">
              {placeholder ?? 'no selections'}
            </div>
          ) : (
            <ul className="selected-options">
              {selectedOptions.map(({ label }) => (
                <li key={label}>{label}</li>
              ))}
            </ul>
          )}

          <ChevronIcon orientation="down" className="icon" />
        </button>
      )}

      <div className="options-container">
        <ul
          className={classStr({
            options: true,
            hidden: !open,
            'with-border': withBorder,
            align,
          })}
          role="listbox"
          id={`${namespace}_dropdown`}
          tabIndex={-1}
          aria-multiselectable={false}
        >
          {open &&
            options.map((option, index) => (
              <li
                key={option.value ?? null}
                id={`${namespace}_${index}`}
                role="option"
                aria-selected={index === activeIndex}
                tabIndex="0"
                className={classStr({
                  'list-item': true,
                  option: true,
                  selected: value.includes(option.value),
                })}
                aria-controls={`${namespace}_dropdown`}
                aria-labelledby={`${namespace}_label`}
                onClick={() => {
                  onChange(toggle(value, option.value));
                }}
                onMouseOver={() => setActiveIndex(index)}
                onKeyDown={() => setActiveIndex(index)}
                onFocus={() => setActiveIndex(index)}
              >
                <span>{option.label}</span>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
