export function ControlsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="20dp/sliders">
        <path
          id="vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.333 3.4165C13.7472 3.4165 14.083 3.75229 14.083 4.1665V5.9167L17.5 5.9167C17.9142 5.9167 18.25 6.25249 18.25 6.6667C18.25 7.08092 17.9142 7.4167 17.5 7.4167H14.083V9.1665C14.083 9.58072 13.7472 9.9165 13.333 9.9165C12.9188 9.9165 12.583 9.58072 12.583 9.1665V4.1665C12.583 3.75229 12.9188 3.4165 13.333 3.4165ZM1.75 6.6667C1.75 6.25249 2.08579 5.9167 2.5 5.9167L10 5.9167C10.4142 5.9167 10.75 6.25249 10.75 6.6667C10.75 7.08092 10.4142 7.4167 10 7.4167L2.5 7.4167C2.08579 7.4167 1.75 7.08092 1.75 6.6667ZM6.66699 10.0835C7.0812 10.0835 7.41699 10.4193 7.41699 10.8335L7.41699 13.3335L7.41699 15.8335C7.41699 16.2477 7.0812 16.5835 6.66699 16.5835C6.25277 16.5835 5.91699 16.2477 5.91699 15.8335L5.91699 14.0835H2.50032C2.08611 14.0835 1.75032 13.7477 1.75032 13.3335C1.75032 12.9193 2.08611 12.5835 2.50032 12.5835H5.91699V10.8335C5.91699 10.4193 6.25278 10.0835 6.66699 10.0835ZM9.25 13.3334C9.25 12.9192 9.58579 12.5834 10 12.5834L17.5 12.5834C17.9142 12.5834 18.25 12.9192 18.25 13.3334C18.25 13.7476 17.9142 14.0834 17.5 14.0834L10 14.0834C9.58579 14.0834 9.25 13.7476 9.25 13.3334Z"
        />
      </g>
    </svg>
  );
}
