import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { omit, pick } from 'lodash-es';

const eventsDefs = {
  onClick: { prop: 'onClick', mapboxEvent: 'click' },
  onMouseEnter: { prop: 'onMouseEnter', mapboxEvent: 'mouseenter' },
  onMouseLeave: { prop: 'onMouseLeave', mapboxEvent: 'mouseleave' },
  onMouseMove: { prop: 'onMouseMove', mapboxEvent: 'mousemove' },
};

const specialLayerProps = ['stopEventPropagation'];

const MapContext = createContext();

export function useMap() {
  const context = useContext(MapContext);
  return omit(context, ['registerSource', 'deregisterSource']);
}

export function useInternalMap() {
  const context = useContext(MapContext);
  return context;
}

export function MapProvider(props) {
  const { children } = props;

  const awaitingLoad = useRef([]);
  const mapRef = useRef();
  const mapLoaded = useRef(false);
  const refreshScheduled = useRef(false);
  const possibleDeletions = useRef(new Set());
  const activeEventsRef = useRef({});

  // these are the main things to babysit here
  const registry = useRef({
    activeSources: {}, // sources within the context of a user's navigation
    passiveSources: {}, // sources that have been checked "on" in the panel
  });

  const [map, setMap] = useState();
  mapRef.current = map;

  const repopulateMapIfNeeded = () => {
    if (mapLoaded.current) {
      const sources = {
        ...registry.current.passiveSources,
        ...registry.current.activeSources,
      };

      Object.entries(sources).forEach(([sourceId, source]) => {
        const { icons, mapboxSource, layers } = source;

        if (!mapRef.current.getSource(sourceId)) {
          mapRef.current.addSource(sourceId, mapboxSource);

          (icons ?? []).forEach((icon) => {
            const { id: imageId, sdf, url } = icon;

            if (!map.hasImage(imageId)) {
              map.loadImage(url, (error, image) => {
                if (error) console.error('Error loading icon', error);
                if (!map.hasImage(imageId)) {
                  map.addImage(imageId, image, { sdf });
                }
              });
            }
          });
        }

        Object.entries(layers).forEach(([layerId, layer]) => {
          const events = pick(layer, Object.keys(eventsDefs));
          const { stopEventPropagation } = layer;
          const { mapboxLayer } = omit(layer, [
            ...Object.keys(eventsDefs),
            ...specialLayerProps,
          ]);

          if (!mapRef.current.getLayer(layerId)) {
            mapRef.current.addLayer({
              source: layer.source,
              ...mapboxLayer,
              id: layerId,
            });
          }

          Object.entries(events).forEach(([event, fn]) => {
            const wrappedFn = (e) => {
              if (!e.stopEventPropagation) {
                fn(e);
              }

              if (stopEventPropagation) {
                e.stopEventPropagation = true;
              }
            };

            if (activeEventsRef.current[layerId]?.[event]) {
              mapRef.current.off(
                eventsDefs[event].mapboxEvent,
                layerId,
                activeEventsRef.current[layerId][event],
              );
            }

            mapRef.current.on(
              eventsDefs[event].mapboxEvent,
              layerId,
              wrappedFn,
            );

            activeEventsRef.current = {
              ...activeEventsRef.current,
              [layerId]: {
                ...(activeEventsRef.current?.[layerId] ?? {}),
                [event]: wrappedFn,
              },
            };
          });

          if (layer.filter) {
            mapRef.current.setFilter(layer.id, layer.filter);
          }
        });
      });

      [...possibleDeletions.current].forEach((sourceId) => {
        if (
          !registry.current.activeSources[sourceId] &&
          !registry.current.passiveSources[sourceId] &&
          mapRef.current.getSource(sourceId)
        ) {
          // TODO: this could do without the flash when removing a source that gets
          //       added as part of the same refresh cycle
          mapRef.current.removeSource(sourceId);
        }
      });

      awaitingLoad.current.forEach((fn) => {
        fn(mapRef.current);
      });
      awaitingLoad.current = [];
    }
  };

  const scheduleRefresh = () => {
    if (!refreshScheduled.current) {
      refreshScheduled.current = true;
      setTimeout(() => {
        repopulateMapIfNeeded();
        refreshScheduled.current = false;
      }, 0);
    }
  };

  const onMapLoad = (fn) => {
    awaitingLoad.current = [...awaitingLoad.current, fn];
    scheduleRefresh();
  };

  const offMapLoad = (fn, cleanup) => {
    if (awaitingLoad.current.includes(fn)) {
      awaitingLoad.current = awaitingLoad.current.filter((al) => al !== fn);
    } else {
      cleanup(mapRef.current);
    }
  };

  const registerSource = (sourceId, active, source) => {
    const sourceKey = active ? 'activeSources' : 'passiveSources';

    if (sourceId in registry.current[sourceKey]) {
      throw new Error(`${sourceId} is already a registered source ID`);
    }

    registry.current[sourceKey][sourceId] = source;
    scheduleRefresh();
  };

  const deregisterSource = (sourceId, active) => {
    const sourceKey = active ? 'activeSources' : 'passiveSources';
    Object.keys(registry.current[sourceKey][sourceId].layers).forEach(
      (layerId) => {
        mapRef.current.removeLayer(layerId);
        delete activeEventsRef.current[layerId];
      },
    );

    possibleDeletions.current.add(sourceId);
    delete registry.current[sourceKey][sourceId];

    scheduleRefresh();
  };

  const setMapStyle = (style) => {
    mapRef.current.setStyle(style);
  };

  useEffect(() => {
    if (map) {
      map.on('load', () => {
        mapLoaded.current = true;
        repopulateMapIfNeeded();
      });

      map.on('style.load', () => {
        scheduleRefresh();
      });
    }
  }, [map]);

  const value = useMemo(() => ({
    map,
    setMap,
    onMapLoad,
    offMapLoad,
    setMapStyle,
    registerSource,
    deregisterSource,
    scheduleRefresh,
  }));

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
