import { sortBy } from 'lodash';
import { toggle } from '@redzone/shared';

export function EnumCollector(props) {
  const { options, value, setValue } = props;

  const handleChange = (opt) => {
    if (!value) {
      setValue(options.filter((o) => o !== opt));
    } else {
      // if all options are selected, make sure the value is `undefined`
      const nextValue = toggle(value, opt);
      setValue(nextValue.length === options.length ? undefined : nextValue);
    }
  };

  const handleSelectAll = () => {
    setValue(value && value.length === 0 ? undefined : []);
  };

  return (
    <ul className="organization-filters">
      {options.length > 4 && (
        <li className="select-all input-row">
          <input
            id="enum-select-all"
            className="redwall"
            type="checkbox"
            onChange={handleSelectAll}
            checked={!value || value.length > 0}
          />
          <label htmlFor="enum-select-all">select all/none</label>
        </li>
      )}
      {sortBy(options).map((option) => (
        <li key={option} className="input-row">
          <input
            id={`enum-option-${option}`}
            className="redwall"
            type="checkbox"
            onChange={() => handleChange(option)}
            checked={value === undefined || value.includes(option)}
          />
          <label htmlFor={`enum-option-${option}`}>{option}</label>
        </li>
      ))}
    </ul>
  );
}
