import { createContext, useContext, useMemo, useState } from 'react';
import { PolygonCollector, Select } from '@redzone/shared';

const SchemaFormContext = createContext({});

export function useSchemaForm() {
  return useContext(SchemaFormContext);
}

export const attributeTypeToInputType = { integer: 'number', string: 'text' };

export function SFLabel(props) {
  const { id, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const usedId = id ?? attribute;
  const shownLabel =
    resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <label {...rest} htmlFor={usedId}>
      {shownLabel}
    </label>
  );
}

export function SFCheckbox(props) {
  const { id, attribute, ...rest } = props;
  const { resource, setAttribute } = useContext(SchemaFormContext);

  const value = resource[attribute] ?? false;

  return (
    <input
      {...rest}
      type="checkbox"
      name={attribute}
      id={id}
      onChange={() => setAttribute(attribute, !value)}
      checked={value}
    />
  );
}

export function SFCheckboxRow(props) {
  const { id, label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const usedId = id ?? attribute;
  const shownLabel =
    label ?? resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <div className="input-row">
      <SFCheckbox {...rest} id={usedId} attribute={attribute} />
      <label htmlFor={usedId}>{shownLabel}</label>
    </div>
  );
}

export function SFPolygonCollector(props) {
  const { id, attribute, ...rest } = props;
  const { resource, setAttribute } = useContext(SchemaFormContext);

  const value = resource[attribute];

  return (
    <PolygonCollector
      {...rest}
      id={id}
      value={value}
      onChange={(newPolygon) => setAttribute(attribute, newPolygon)}
    />
  );
}

export function SFPolygonCollectorRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const shownLabel =
    label ?? resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <SFPolygonCollector {...rest} attribute={attribute} />
    </div>
  );
}

export function SFInput(props) {
  const { attribute, ...rest } = props;
  const { resource, setAttribute } = useContext(SchemaFormContext);

  const value = resource[attribute] ?? '';

  return (
    <input
      {...rest}
      id={attribute}
      value={value}
      onChange={(e) => setAttribute(attribute, e.target.value)}
    />
  );
}

export function SFInputRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const attributeSchema = resourceSchema?.attributes?.[attribute];
  const shownLabel = label ?? attributeSchema?.title ?? attribute;

  const schemaProps = {};
  if (attributeSchema) {
    if (attributeSchema.maximum !== undefined)
      schemaProps.max = attributeSchema.maximum;
    if (attributeSchema.minimum !== undefined)
      schemaProps.min = attributeSchema.minimum;
    if (attributeSchema.type)
      schemaProps.type =
        attributeTypeToInputType[attributeSchema.type] ?? attributeSchema.type;
  }

  const inputProps = { ...schemaProps, ...rest };

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <SFInput {...inputProps} attribute={attribute} />
    </div>
  );
}

export function SFRadioGroup(props) {
  const { options, attribute, ...rest } = props;
  const { resource, resourceSchema, setAttribute } =
    useContext(SchemaFormContext);

  const attributeSchema = resourceSchema?.attributes?.[attribute];
  const usedOptions =
    options ?? attributeSchema.enum.map((e) => ({ label: e, value: e }));

  const value = resource[attribute] ?? '';

  return (
    <ul className="radio-group">
      {usedOptions.map((option) => {
        const id = `${attribute}--${option.value}`;

        return (
          <li key={option.value} className="input-row">
            <input
              {...rest}
              type="radio"
              name={attribute}
              id={id}
              onChange={() => setAttribute(attribute, option.value)}
              checked={value === option.value}
            />
            <label htmlFor={id}>{option.label}</label>
          </li>
        );
      })}
    </ul>
  );
}

export function SFRadioGroupRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const shownLabel =
    label ?? resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <SFRadioGroup {...rest} attribute={attribute} />
    </div>
  );
}

export function SFTextarea(props) {
  const { attribute, ...rest } = props;
  const { resource, setAttribute } = useContext(SchemaFormContext);

  const value = resource[attribute] ?? '';

  return (
    <textarea
      {...rest}
      value={value}
      onChange={(e) => setAttribute(attribute, e.target.value)}
    />
  );
}

export function SFTextareaRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const shownLabel =
    label ?? resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <SFTextarea {...rest} attribute={attribute} />
    </div>
  );
}

export function SFSelect(props) {
  const { options, attribute, ...rest } = props;
  const { resource, resourceSchema, setAttribute } =
    useContext(SchemaFormContext);

  const attributeSchema = resourceSchema?.attributes?.[attribute];
  const allOptions =
    options ?? attributeSchema.enum.map((e) => ({ label: e, value: e }));

  const value = resource[attribute] ?? undefined;

  return (
    <Select
      {...rest}
      id={attribute}
      value={value}
      onChange={(val) => setAttribute(attribute, val)}
      options={allOptions}
    />
  );
}

export function SFSelectRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useContext(SchemaFormContext);

  const shownLabel =
    label ?? resourceSchema?.attributes?.[attribute]?.title ?? attribute;

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <SFSelect {...rest} attribute={attribute} />
    </div>
  );
}

export function SchemaForm(props) {
  const {
    children,
    onSubmit,
    resource,
    setResource,
    resourceType,
    schema,
    ...rest
  } = props;
  const resourceSchema = schema.resources[resourceType];

  const [wasFormSubmitted, setWasFormSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState([]);

  const setAttribute = (attribute, newValue) =>
    setResource({ ...resource, [attribute]: newValue });

  const handleInvalid = (ev) => {
    if (!wasFormSubmitted) setWasFormSubmitted(true);
    ev.target
      ?.closest('form')
      ?.querySelector('input:invalid')
      ?.closest('.input-row')
      ?.scrollIntoView();
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setFormErrors([]);
    onSubmit(resource);
  };

  const value = useMemo(() => ({
    formErrors,
    resource,
    resourceSchema,
    setAttribute,
  }));

  return (
    <SchemaFormContext.Provider value={value}>
      <form onInvalid={handleInvalid} onSubmit={handleSubmit} {...rest}>
        {children}
      </form>
    </SchemaFormContext.Provider>
  );
}
