import '../styles/redwall/index.scss';
import './RedwallLayout.scss';
import 'react-datepicker/dist/react-datepicker.css';

export function RedwallLayout(props) {
  const { className, children } = props;

  return (
    <div className={`redwall-layout grid-root ${className}`.trim()}>
      {children}
    </div>
  );
}
