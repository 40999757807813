export function BuildingIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="white" />
      <path d="M11 8H8V6H11V8Z" fill="black" />
      <path d="M13 8H16V6H13V8Z" fill="black" />
      <path d="M11 13H8V11H11V13Z" fill="black" />
      <path d="M13 13H16V11H13V13Z" fill="black" />
      <path d="M11 18H8V16H11V18Z" fill="black" />
      <path d="M13 18H16V16H13V18Z" fill="black" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 2C5.89543 2 5 2.89543 5 4V20H3C2.44772 20 2 20.4477 2 21C2 21.5523 2.44772 22 3 22L21 22C21.5523 22 22 21.5523 22 21C22 20.4477 21.5523 20 21 20L19 20V4C19 2.89543 18.1046 2 17 2H7ZM17 20V4H7V20H17Z"
        fill="black"
      />
    </svg>
  );
}
