export function XFillIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <g id="20dp/x-circle-close-fill">
        <path
          id="vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM13.0896 6.91058C13.415 7.23602 13.415 7.76366 13.0896 8.08909L11.1788 9.99984L13.0896 11.9106C13.415 12.236 13.415 12.7637 13.0896 13.0891C12.7641 13.4145 12.2365 13.4145 11.9111 13.0891L10.0003 11.1783L8.08958 13.0891C7.76414 13.4145 7.23651 13.4145 6.91107 13.0891C6.58563 12.7637 6.58563 12.236 6.91107 11.9106L8.82181 9.99984L6.91107 8.08909C6.58563 7.76366 6.58563 7.23602 6.91107 6.91058C7.23651 6.58514 7.76414 6.58514 8.08958 6.91058L10.0003 8.82133L11.9111 6.91058C12.2365 6.58514 12.7641 6.58514 13.0896 6.91058Z"
          fill="black"
        />
      </g>
    </svg>
  );
}
