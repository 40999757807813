import { useState } from 'react';
import getCircle from '@turf/circle';
import getBbox from '@turf/bbox';
import intersects from '@turf/boolean-intersects';
import { useMapEffect, useMap, Source, Layer } from '@redzone/map';

const sourceId = 'map-circle-collector-src';
const layerId = 'map-circle-collector-layer';

function Collector(props) {
  const { layers, onChange, radius } = props;
  const { map } = useMap();

  const source = {
    id: sourceId,
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  const handleMove = (e) => {
    const mapboxSource = map.getSource(sourceId);
    if (mapboxSource) {
      const center = [e.lngLat.lng, e.lngLat.lat];
      const circlePolygon = getCircle(center, radius, { units: 'miles' });
      const sourceData = {
        type: 'FeatureCollection',
        features: circlePolygon ? [circlePolygon] : [],
      };

      mapboxSource.setData(sourceData);
    }
  };

  useMapEffect(
    () => {
      map.on('mousemove', handleMove);
    },
    () => {
      map.off('mousemove', handleMove);
    },
    [radius],
  );

  return (
    <Source {...source}>
      <Layer
        id={layerId}
        mapboxLayer={{
          id: layerId,
          type: 'fill',
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.2,
            'fill-outline-color': 'blue',
          },
        }}
        onClick={(e) => {
          const center = [e.lngLat.lng, e.lngLat.lat];
          const circlePolygon = getCircle(center, radius, { units: 'miles' });
          const bbox = getBbox(circlePolygon);
          const mapboxBbox = [
            map.project([bbox[0], bbox[1]]),
            map.project([bbox[2], bbox[3]]),
          ];

          const boundedFeatures = layers
            ? map.queryRenderedFeatures(mapboxBbox, {
                layers,
              })
            : [];
          const features = boundedFeatures.filter((f) =>
            intersects(circlePolygon, f),
          );

          const polygonFeatureSet = {
            type: 'FeatureCollection',
            features: [circlePolygon],
          };

          onChange(polygonFeatureSet, features);
        }}
      />
    </Source>
  );
}

export function MapCircleCollector(props) {
  const { id, layers, onChange, radius = 10, value, ...rest } = props;
  const [collecting, setCollecting] = useState(false);

  const handleFinish = (...args) => {
    setCollecting(false);
    onChange(...args);
  };

  return collecting ? (
    <>
      <button
        {...rest}
        type="button"
        onClick={() => {
          setCollecting(false);
        }}
      >
        Cancel
      </button>
      <Collector layers={layers} onChange={handleFinish} radius={radius} />
    </>
  ) : (
    <button
      type="button"
      {...rest}
      onClick={() => {
        setCollecting(true);
      }}
    />
  );
}
