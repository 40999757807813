import { Outlet, useNavigation } from 'react-router-dom';
import { MAP_STYLES, MAPBOX_TOKEN, MapProvider } from '@redzone/map';
import { MapStateProvider } from '@redzone/shared';
import { UserProvider, useUser } from '../../context/UserContext.jsx';
import { MAP_LAYERS } from '../../constants/map-layers.js';
import './App.scss';

const riskLayer = {
  name: 'layers.risk',
  label: 'Risk',
  layer: 'redzonesoftware/ciykl08rp00162rphs69unnff',
  url: 'mapbox://styles/redzonesoftware/cjmglfwb0mj632spay380hst7',
  image: `https://api.mapbox.com/styles/v1/redzonesoftware/ciykl08rp00162rphs69unnff/static/-121.54206414462075,40.255544412684884,13.25,0/60x60?access_token=${MAPBOX_TOKEN}`,
};
const riskBaseLayers = { risk: riskLayer, ...MAP_STYLES };

const addAuthToken = (accessToken) => (url) => {
  const isPrivateTarget = url.startsWith('https://cartogram');

  return accessToken && isPrivateTarget
    ? {
        headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {},
        url,
      }
    : { url };
};

function AppWithUser() {
  const navigation = useNavigation();
  const { userToken } = useUser();

  return (
    <MapProvider transformRequest={addAuthToken(userToken)}>
      <MapStateProvider
        availableBaseLayers={riskBaseLayers}
        availableCustomLayers={MAP_LAYERS}
      >
        <div
          className={`app-root ${
            navigation.state === 'loading' ? ' loading' : ''
          }`}
        >
          <Outlet />
        </div>
      </MapStateProvider>
    </MapProvider>
  );
}

export function App() {
  return (
    <UserProvider>
      <AppWithUser />
    </UserProvider>
  );
}
