export const RISK_FE_PERMISSION = 'rz:risk:frontend:access';
export const RISK_SCORING_PERMISSION = 'rz:risk:risk_scoring:read';
export const INSIGHT_SCORING_PERMISSION = 'rz:risk:insight_scoring:read';
export const DISTANCE_TO_HIGHER_PERMISSION = 'rz:risk:distance_to_higher:read';
export const CORRELATED_RISK_ZONES_PERMISSION =
  'rz:risk:correlated_risk_zones:read';
export const AVERAGE_ANNUAL_LOSS_PERMISSION =
  'rz:risk:average_annual_loss:read';
export const FIRE_HISTORY_PERMISSION = 'rz:risk:fire_history:read';
export const FIREWISE_COMMUNITY_PERMISSION = 'rz:risk:firewise_community:read';
export const FRR_COMMUNITY_PERMISSION = 'rz:risk:frr_community:read';
export const ACTIVE_FIRES_PERMISSION = 'rz:risk:active_fires:read';
