export function RulerIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1032_195543)">
        <path
          d="M14.4998 12.5L16.4998 10.5M11.4998 9.49999L13.4998 7.49999M8.4998 6.49999L10.4998 4.49999M17.4998 15.5L19.4998 13.5M21.2998 15.3C21.5235 15.523 21.7011 15.7879 21.8222 16.0796C21.9433 16.3713 22.0057 16.6841 22.0057 17C22.0057 17.3159 21.9433 17.6286 21.8222 17.9203C21.7011 18.2121 21.5235 18.477 21.2998 18.7L18.6998 21.3C18.4768 21.5237 18.2119 21.7013 17.9202 21.8224C17.6284 21.9435 17.3157 22.0059 16.9998 22.0059C16.6839 22.0059 16.3712 21.9435 16.0794 21.8224C15.7877 21.7013 15.5228 21.5237 15.2998 21.3L2.6998 8.69999C2.25036 8.24836 1.99805 7.63714 1.99805 6.99999C1.99805 6.36283 2.25036 5.75161 2.6998 5.29999L5.2998 2.69999C5.75143 2.25054 6.36265 1.99823 6.9998 1.99823C7.63695 1.99823 8.24818 2.25054 8.6998 2.69999L21.2998 15.3Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1032_195543">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
}
