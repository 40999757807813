import { useState } from 'react';
import getBbox from '@turf/bbox';
import intersects from '@turf/boolean-intersects';
import { useMap } from '@redzone/map';
import { InnerCircleCollector } from './polygon-collector/InnerCircleCollector.jsx';
import { InnerPolygonCollector } from './polygon-collector/InnerPolygonCollector.jsx';
import './PolygonCollector.scss';

function CollectorSelector(props) {
  const { setCollector, instructions } = props;

  return (
    <>
      {instructions && (
        <div className="instructions selector-instructions">{instructions}</div>
      )}
      <div className="collector-type">
        <button
          type="button"
          className="redwall secondary"
          onClick={() => setCollector('polygon')}
        >
          Polygon
        </button>
        <button
          type="button"
          className="redwall secondary"
          onClick={() => setCollector('circle')}
        >
          Circle
        </button>
      </div>
    </>
  );
}

export function PolygonCollector(props) {
  const {
    instructions,
    polygonInstructions,
    circleInstructions,
    id,
    onChange,
    featureLayers,
  } = props;
  const [collector, setCollector] = useState();
  const { map } = useMap();

  if (!map) return null;

  const handleChange = (polygon) => {
    const bbox = getBbox(polygon);
    const mapboxBbox = [
      map.project([bbox[0], bbox[1]]),
      map.project([bbox[2], bbox[3]]),
    ];

    const boundedFeatures = featureLayers
      ? map.queryRenderedFeatures(mapboxBbox, {
          layers: featureLayers,
        })
      : [];
    const features = boundedFeatures.filter((f) => intersects(polygon, f));

    setCollector(null);
    onChange(polygon, features);
  };

  return (
    <div className="polygon-collector component">
      {collector === 'polygon' && (
        <InnerPolygonCollector
          id={`pc-polygon-${id}`}
          collecting={collector === 'polygon'}
          toggleCollecting={() => {
            setCollector(collector === 'polygon' ? null : 'polygon');
          }}
          instructions={polygonInstructions}
          onChange={handleChange}
        />
      )}

      {collector === 'circle' && (
        <InnerCircleCollector
          id={`pc-circle-${id}`}
          instructions={circleInstructions}
          collecting={collector === 'circle'}
          toggleCollecting={() => {
            setCollector(collector === 'circle' ? null : 'circle');
          }}
          onChange={handleChange}
        />
      )}

      {!collector && (
        <CollectorSelector
          setCollector={setCollector}
          instructions={instructions}
        />
      )}
    </div>
  );
}
