import { Popup } from '@redzone/map';
import { ChevronIcon } from '@redzone/shared';
import './RedwallPopup.scss';

export function RedwallPopup(props) {
  const {
    title,
    name,
    coordinates,
    features,
    setSelectedFeatureIdx,
    selectedFeatureIdx,
    children,
  } = props;

  if (!features) return null;

  return (
    <Popup coordinates={coordinates}>
      <div className="redwall-popup component">
        <div className="popup-header">{title}</div>
        <div className="redwall-popup-content">
          {children}
          {features.length > 1 && (
            <div className="redwall-popup-navigation">
              <div className="title">
                {name} {selectedFeatureIdx + 1} OF {features.length}
              </div>
              <button
                type="button"
                className="redwall unstyled"
                aria-label="previous layer"
                id="prev-layer-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    selectedFeatureIdx === 0
                      ? features.length - 1
                      : selectedFeatureIdx - 1,
                  );
                }}
              >
                <ChevronIcon orientation="right" />
              </button>
              <button
                type="button"
                className="redwall unstyled"
                aria-label="next layer"
                id="next-layer-button"
                onClick={() => {
                  setSelectedFeatureIdx(
                    (selectedFeatureIdx + 1) % features.length,
                  );
                }}
              >
                <ChevronIcon orientation="left" />
              </button>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
}
