import { useState, useRef } from 'react';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import { ClickOutside } from './ClickOutside.jsx';
import './Menu.scss';

export function Menu(props) {
  const { id, wide, title, list, className } = props;
  const ref = useRef('menu');
  const [open, setOpen] = useState(false);

  ClickOutside(ref, () => setOpen(false));

  return (
    <div className={`menu component ${wide ? 'wide' : ''}`.trim()} ref={ref}>
      <div className={className}>
        <button
          id="button-selected"
          className="menu-button"
          type="button"
          onClick={() => setOpen(!open)}
          aria-haspopup="listbox"
          aria-controls={`${id}_dropdown`}
          aria-labelledby={`${id}_label`}
          aria-expanded={open}
        >
          {title}
          <ChevronIcon orientation={open ? 'up' : 'down'} className="icon" />
        </button>
      </div>

      <div className="list-container light-theme">
        <ul
          className={`options ${open ? '' : 'hidden'}`}
          role="listbox"
          id={`${id}`}
          tabIndex={-1}
          aria-multiselectable={false}
        >
          {list.map((item) => (
            <li
              key={item.key}
              className={`list-item ${
                item.withBorder ? 'with-border-bottom' : ''
              } ${item.withHover ? 'with-hover' : ''} `.trim()}
            >
              {item.element ?? (
                <button
                  type="button"
                  onClick={item.fn}
                  className="redwall unstyled"
                >
                  {item.label}
                </button>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
