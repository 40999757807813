// helpers differ from utils because they are domain-specific and not widely
// applicable

import { get } from 'lodash';
import { setPath } from './utils.js';

export function classStr(obj) {
  return Object.keys(obj)
    .filter((k) => obj[k])
    .join(' ');
}

export function stateAtPath(statePair, path) {
  const [rootVal, setter] = statePair;

  return [
    get(rootVal, path),
    (nextVal) => setter(setPath(rootVal, path, nextVal)),
  ];
}

export function toggle(items, item, fn) {
  if (fn) {
    const itemVal = fn(item);
    const hasItem = items.some((i) => fn(i) === itemVal);

    return hasItem ? items.filter((i) => fn(i) !== itemVal) : [...items, i];
  }

  const hasItem = items.some((i) => i === item);
  return hasItem ? items.filter((i) => i !== item) : [...items, item];
}

export function stateWithListFunctions(uniquenessFn) {
  return function (target) {
    const [current, setter] = target;

    const hasItem = uniquenessFn
      ? (item) =>
          current.find(
            (curItem) => uniquenessFn(item) === uniquenessFn(curItem),
          )
      : (item) => current.find((curItem) => item === curItem);

    const add = (item) => {
      if (!hasItem(item)) {
        const nextItems = [...current, item];
        setter(nextItems);
      }
    };

    const clear = () => {
      const nextItems = [];
      setter(nextItems);
    };

    const remove = (item) => {
      const itemToRemove = hasItem(item);
      if (itemToRemove) {
        const nextItems = current.filter((curItem) => curItem !== itemToRemove);
        setter(nextItems);
      }
    };

    return [
      current,
      setter,
      {
        add,
        clear,
        remove,
        toggle: (item) => {
          setter(toggle(current, item, uniquenessFn));
        },
      },
    ];
  };
}
