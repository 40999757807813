export function NewsIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3335 5.00004H15.8335C16.0545 5.00004 16.2665 5.08784 16.4228 5.24412C16.579 5.4004 16.6668 5.61236 16.6668 5.83337V15C16.6668 15.4421 16.4912 15.866 16.1787 16.1786C15.8661 16.4911 15.4422 16.6667 15.0002 16.6667M15.0002 16.6667C14.5581 16.6667 14.1342 16.4911 13.8217 16.1786C13.5091 15.866 13.3335 15.4421 13.3335 15V4.16671C13.3335 3.94569 13.2457 3.73373 13.0894 3.57745C12.9331 3.42117 12.7212 3.33337 12.5002 3.33337H4.16683C3.94582 3.33337 3.73385 3.42117 3.57757 3.57745C3.42129 3.73373 3.3335 3.94569 3.3335 4.16671V14.1667C3.3335 14.8297 3.59689 15.4656 4.06573 15.9345C4.53457 16.4033 5.17045 16.6667 5.8335 16.6667H15.0002ZM6.66683 6.66671H10.0002M6.66683 10H10.0002M6.66683 13.3334H10.0002"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
