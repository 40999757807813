import React, { useState } from 'react';
import { useMap, useMapEffect, Layer, Source, Marker } from '@redzone/map';
import { datadogLogs } from '@datadog/browser-logs';
import { useUser } from '../../context/UserContext.jsx';
import { SelectLocation } from './SelectLocation.jsx';
import { RefineParams } from './RefineParams.jsx';
import { RunReport } from './RunReport.jsx';
import { getPointLocation } from '../helpers/helpers.js';
import { INSIGHT_INIT_VALUES } from '../../constants/Insight-config.js';
import { INIT_AAL_VALUES } from '../../constants/aal-config.js';
import { SEARCH_TYPES } from '../../constants/location-type.js';
import './Main.scss';

export function Main() {
  const { isInsight, isAal } = useUser();
  const isRefineParams = isInsight || isAal;
  const totalSteps = isRefineParams ? 3 : 2;

  /* Form Props */
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});

  /* Component Props */
  const [insightValues, setInsightValues] = useState(INSIGHT_INIT_VALUES);
  const [aalValues, setAalValues] = useState(INIT_AAL_VALUES);
  const [location, setLocation] = useState(null);
  const [pointLocation, setPointLocation] = useState(null);
  const [refineParamsKey, setRefineParamsKey] = useState(1);
  const [searchType, setSearchType] = useState(SEARCH_TYPES.ADDRESS);

  /* Map Props */
  const { map: localMap } = useMap();

  /* Form Methods */
  const handleNextStep = () => {
    setFormData({
      ...formData,
      insightScoring: { ...insightValues },
      averageAnnualLoss: { ...aalValues },
    });
    setCurrentStep(currentStep + 1);
  };

  const locationHandler = (data) => {
    datadogLogs.logger.info('MAIN - locationHandler data', {
      data,
    });
    setLocation(data);
    const point = getPointLocation(data);
    setPointLocation(point);
    // NOTE(Baad): resetting the form data every time there is a location change.
    setFormData({ ...{}, location: { ...data, center: point } });
  };

  const onMarkerChange = (_, marker) => {
    const coordinates = marker.getLngLat();

    locationHandler({
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [coordinates.lng, coordinates.lat],
          },
        },
      ],
    });

    setCurrentStep(1);
    setInsightValues(INSIGHT_INIT_VALUES);
    setAalValues(INIT_AAL_VALUES);
  };

  const resetForm = () => {
    setCurrentStep(1);
    setFormData({});
    setLocation(null);
    setPointLocation(null);
    setInsightValues(INSIGHT_INIT_VALUES);
    setAalValues(INIT_AAL_VALUES);
    setRefineParamsKey(refineParamsKey + 1);
  };

  const reset = () => {
    resetForm();
    setSearchType(SEARCH_TYPES.ADDRESS);
  };

  /* Map Methods */
  useMapEffect(
    (map) => {
      if (location) {
        map.flyTo({
          center: [pointLocation[0], pointLocation[1]],
          zoom: 15,
        });
      } else {
        map.flyTo({
          zoom: 4.5,
        });
      }
    },
    () => {},
    [location],
  );

  /* Steps */
  const locationStep = {
    component: (
      <SelectLocation
        key="select location"
        titleText="Select Location"
        location={location}
        pointLocation={pointLocation}
        setLocation={locationHandler}
        resetForm={resetForm}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        totalSteps={totalSteps}
        handleNextStep={handleNextStep}
        searchType={searchType}
        setSearchType={setSearchType}
      />
    ),
    isOptional: false,
    onNavigate: () => {
      setLocation(null);
    },
  };
  const refainParameterStep = {
    component: (
      <RefineParams
        key={refineParamsKey}
        titleText="Refine Parameters"
        pointLocation={pointLocation}
        setArgs={setFormData}
        currentStep={currentStep}
        totalSteps={totalSteps}
        setCurrentStep={setCurrentStep}
        handleNextStep={handleNextStep}
        insightValues={insightValues}
        setInsightValues={setInsightValues}
        aalValues={aalValues}
        setAalValues={setAalValues}
        isOptional
      />
    ),
    isOptional: true,
  };
  const runReportStep = {
    component: (
      <RunReport
        key="run-report"
        titleText="Run Report"
        formData={formData}
        currentStep={currentStep}
        totalSteps={totalSteps}
        setCurrentStep={setCurrentStep}
        handleNextStep={handleNextStep}
        reset={reset}
      />
    ),
    isOptional: true,
  };

  const stepsRisk = {
    1: locationStep,
    2: runReportStep,
  };
  const stepsRefineParams = {
    1: locationStep,
    2: refainParameterStep,
    3: runReportStep,
  };

  return (
    <>
      <main className="create-report" data-cy="create-report">
        <h2 className="redwall lg semibold report-title">Create Report</h2>
        <form className="redwall primary" onSubmit={(e) => e.preventDefault()}>
          {Object.values(isRefineParams ? stepsRefineParams : stepsRisk).map(
            (step) => step.component,
          )}
        </form>
      </main>

      {pointLocation && (
        <>
          <Marker
            key={JSON.stringify([pointLocation[0], pointLocation[1]])}
            map={localMap}
            onChange={onMarkerChange}
            startingLngLat={[pointLocation[0], pointLocation[1]]}
          />
          <Source
            id="user-point"
            type="geojson"
            key={JSON.stringify(location)}
            data={location}
          >
            <Layer
              id="user-point"
              mapboxLayer={{
                id: 'layer',
                type: 'circle',
                paint: {
                  'circle-radius': 5,
                  'circle-color': '#d13239',
                },
              }}
            />
          </Source>
        </>
      )}
    </>
  );
}
