export function PercentIcon(props) {
  const { className, ...rest } = props;
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...rest}
    >
      <path
        d="M15.8327 4.16663L4.16602 15.8333"
        stroke="#57534E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.41634 7.49996C6.56693 7.49996 7.49967 6.56722 7.49967 5.41663C7.49967 4.26604 6.56693 3.3333 5.41634 3.3333C4.26575 3.3333 3.33301 4.26604 3.33301 5.41663C3.33301 6.56722 4.26575 7.49996 5.41634 7.49996Z"
        stroke="#57534E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5833 16.6667C15.7339 16.6667 16.6667 15.7339 16.6667 14.5833C16.6667 13.4327 15.7339 12.5 14.5833 12.5C13.4327 12.5 12.5 13.4327 12.5 14.5833C12.5 15.7339 13.4327 16.6667 14.5833 16.6667Z"
        stroke="#57534E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
