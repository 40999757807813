import React, { cloneElement } from 'react';
import './CardContainer.scss';
import { useStateList } from '../hooks/use-state-list.js';

export function CardContainer(props) {
  const { children } = props;
  const childArray = React.Children.toArray(children);
  const [cardsToShow, { toggle: toggleCard }] = useStateList([]);

  const labels = childArray.map((child, index) => {
    const { id, icon, title } = child.props;

    if (child.props.className === 'divider') {
      return <li className="divider" key={index.toString()}></li>;
    }

    return (
      <li className="tab-label" key={id}>
        <button
          aria-label={title}
          type="button"
          className={`redwall unstyled tab-label-button ${
            cardsToShow.includes(id) && 'active'
          }`}
          onClick={() => toggleCard(id)}
        >
          {icon}
        </button>
      </li>
    );
  });

  return (
    <>
      <div className="component card-labels">
        <ul className="labels">{labels}</ul>
      </div>

      <div className="component card-container">
        <div className="card-wrapper">
          {childArray.map((child) =>
            cloneElement(child, {
              open: cardsToShow.includes(child.props.id),
              onClose: () => toggleCard(child.props.id),
            }),
          )}
        </div>
      </div>
    </>
  );
}
