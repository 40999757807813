import axios from 'axios';

function createTransport(baseURL) {
  const transport = axios.create({
    baseURL,
    withCredentials: true,
  });

  const getHeaders = (headers = {}) => {
    const accessToken = localStorage.getItem('access-token');

    return {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      ...headers,
    };
  };

  return {
    get(path, options = {}) {
      return transport.get(path, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    async getData(path, options = {}) {
      const result = await this.get(path, options);
      return result.data;
    },

    post(path, body = {}, options = {}) {
      return transport.post(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    put(path, body = {}, options = {}) {
      return transport.put(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    patch(path, body = {}, options = {}) {
      return transport.patch(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },
  };
}

export const authTransport = createTransport(
  `${process.env.REACT_APP_API_AUTH}/api/v1`,
);

export const riskTransport = createTransport(
  `${process.env.REACT_APP_API_RISK}/api/v1`,
);
