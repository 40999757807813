import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { RiskSearchIcon } from '@redzone/shared';

import './Geocoder.scss';

export function Geocoder(props) {
  const { MAPBOX_TOKEN, setLocation, location } = props;

  const geocoder = new MapboxGeocoder({
    accessToken: MAPBOX_TOKEN,
    marker: false,
    flyTo: false,
    countries: 'US',
    addressAccuracy: 'Address',
    placeholder: 'ex. 13 Ress Ave, San Francisco, CA 94132',
  });

  useEffect(() => {
    geocoder.addTo('#geocoder');

    // Hack to replace the search icon with our custom icon
    const searchIcon = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder--icon-search',
    )?.[0];
    const container = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder',
    )?.[0];

    const newIcon = document.createElement('span');
    newIcon.classList.add(
      'search-icon',
      'mapboxgl-ctrl-geocoder--icon',
      'mapboxgl-ctrl-geocoder--icon-search',
    );
    container.appendChild(newIcon);

    searchIcon.remove();
    const icon = ReactDOM.createRoot(
      document.getElementsByClassName('search-icon')?.[0],
    );
    icon.render(<RiskSearchIcon />);

    // Mapbox have bound the enter key to the "clear method"
    // I am adding an event listener here to stop geocoder from calling clear when the user hit enter while in the input
    const locationInput = document.getElementsByClassName(
      `mapboxgl-ctrl-geocoder--input`,
    )[0];

    locationInput.addEventListener('keypress', (e) => {
      if (e.key === 'Enter') e.preventDefault();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /* NOTE (Baad): mapbox geocoder is trying to set the input value to '' when calling .clear, but something is blowing up -
      so I am doing it manually. I welcome any other solutions. this does not affect the state of the app, since the user have started a new search,
      it is just a UI clean up.
     */
    const input = document.getElementsByClassName(
      'mapboxgl-ctrl-geocoder--input',
    )?.[0];
    if (!location) input.value = '';
    input.value = location?.place_name ?? '';
    // separate concern, but we need to turn off autocomplete on the input
    input.setAttribute('autocomplete', 'off');
  }, [location]);

  geocoder.on('clear', (e) => {
    setLocation(null);
  });

  geocoder.on('result', (e) => {
    setLocation(e?.result);
  });

  return <div id="geocoder" />;
}
