import './SpinnerIcon.scss';

// positions are center or local

export function SpinnerIcon(props) {
  const { className, position } = props;

  return (
    <svg
      className={`spinner-icon${position ? ` ${position}` : ''} ${
        className ? ` ${className}` : ''
      }`}
      viewBox="0 0 50 50"
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      />
    </svg>
  );
}
