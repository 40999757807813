export function InfoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#clip0_1902_942)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2.125C3.8599 2.125 2.125 3.8599 2.125 6C2.125 8.1401 3.8599 9.875 6 9.875C8.1401 9.875 9.875 8.1401 9.875 6C9.875 3.8599 8.1401 2.125 6 2.125ZM0.875 6C0.875 3.16954 3.16954 0.875 6 0.875C8.83046 0.875 11.125 3.16954 11.125 6C11.125 8.83046 8.83046 11.125 6 11.125C3.16954 11.125 0.875 8.83046 0.875 6Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 5.375C6.34518 5.375 6.625 5.65482 6.625 6V8C6.625 8.34518 6.34518 8.625 6 8.625C5.65482 8.625 5.375 8.34518 5.375 8V6C5.375 5.65482 5.65482 5.375 6 5.375Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.375 4C5.375 3.65482 5.65482 3.375 6 3.375H6.005C6.35018 3.375 6.63 3.65482 6.63 4C6.63 4.34518 6.35018 4.625 6.005 4.625H6C5.65482 4.625 5.375 4.34518 5.375 4Z"
        />
      </g>
    </svg>
  );
}
