export function getPointLocation(location) {
  if (location?.geometry?.coordinates) {
    return location.geometry.coordinates;
  }

  return location?.features[0]?.geometry?.coordinates?.map(
    (coord) => parseFloat(coord).toFixed(6), // truncate to 6 decimal places per req
  );
}

export const isDeductibleValid = (v) => Number(v) >= 0 && Number(v) <= 100;

export const isDeductibleWithTivValid = (values) =>
  Number(values.siteDeductibleAsValue) >= 0 &&
  Number(values.totalInsuredValue) >= Number(values.siteDeductibleAsValue);

export const hasPermission = (grants, org, permission) =>
  grants.some(
    (grant) =>
      grant.org_key === org &&
      grant.roles.some((role) =>
        role.permissions.some((perm) => perm.scope === permission),
      ),
  );

export const getExistingValues = (obj) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== '') newObj[key] = Number(obj[key]);
  });
  return newObj;
};

export const isEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export function getDistanceForHigherRisk(
  riskScoring,
  distanceToHigherRiskClasses,
) {
  const currentClass = riskScoring.data.overallClass;

  const validDistance = distanceToHigherRiskClasses.data
    .filter((i) => i.overallClass === 'High' || i.overallClass === 'Very High')
    .filter((i) => i.overallClass !== currentClass);

  return validDistance;
}
