import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TmpCheckMarkAnimation } from '@redzone/shared';
import { datadogLogs } from '@datadog/browser-logs';
import { Step } from './Step.jsx';
import { useUser } from '../../context/UserContext.jsx';
import { useRisk } from '../../context/RiskContext.jsx';
import {
  hasPermission,
  getExistingValues,
  isEmpty,
} from '../helpers/helpers.js';
import {
  RISK_SCORING_PERMISSION,
  DISTANCE_TO_HIGHER_PERMISSION,
  CORRELATED_RISK_ZONES_PERMISSION,
  FIRE_HISTORY_PERMISSION,
  FIREWISE_COMMUNITY_PERMISSION,
  FRR_COMMUNITY_PERMISSION,
  ACTIVE_FIRES_PERMISSION,
} from '../../constants/permissions.js';

import './RunReport.scss';

export function RunReport(props) {
  const {
    titleText,
    currentStep,
    totalSteps,
    setCurrentStep,
    formData,
    reset,
  } = props;
  const navigate = useNavigate();
  const { setReportData } = useRisk();

  const BUTTON_STATES = {
    DEFAULT: 'primary',
    LOADING: 'loading',
    COMPLETE: 'complete',
  };

  const [buttonState, setButtonState] = useState(BUTTON_STATES.DEFAULT);
  const { isInsight, isAal, token, selectedOrg, grants } = useUser();
  const [error, setError] = useState(false);
  const currentNumberOfSteps = isInsight || isAal ? 3 : 2;
  const isActive = currentStep === currentNumberOfSteps;

  const generateReport = async (args) => {
    datadogLogs.logger.info('Risk Report ARGS (formData)', {
      args,
    });

    setButtonState(BUTTON_STATES.LOADING);

    try {
      setError(null);
      const { location, insightScoring, averageAnnualLoss } = args;
      const hasAal = isAal && Boolean(averageAnnualLoss);
      const aalValidValues = hasAal && getExistingValues(averageAnnualLoss);
      const hasAalValues = hasAal && !isEmpty(aalValidValues);

      const fullAddressProperties = location.context?.reduce((obj, acc) => {
        const newKey = acc.id.split('.')[0];
        const text = acc[`text_en-US`] ?? acc.text_en ?? acc.text;
        return { ...obj, [newKey]: text };
      }, {});

      console.log('location', location);
      console.log('fullAddressProperties', fullAddressProperties);

      datadogLogs.logger.info('Risk Report location', {
        location,
      });

      datadogLogs.logger.info('Risk Report fullAddressProperties', {
        fullAddressProperties,
      });

      const requestBody = {
        metadata: {
          requestTag: 'string',
          units: {
            distance: 'miles',
          },
        },
        location: {
          ...(fullAddressProperties && {
            address: location.place_name.split(',')[0],
            city: fullAddressProperties.place,
            state: fullAddressProperties.region,
            zip: fullAddressProperties.postcode,
          }),
          latitude: location.center[1],
          longitude: location.center[0],
        },
        rzFeatures: {
          riskScoring: hasPermission(
            grants,
            selectedOrg,
            RISK_SCORING_PERMISSION,
          ),
          fireHistory: hasPermission(
            grants,
            selectedOrg,
            FIRE_HISTORY_PERMISSION,
          ),
          distanceToHigherRiskClasses: hasPermission(
            grants,
            selectedOrg,
            DISTANCE_TO_HIGHER_PERMISSION,
          ),
          firewiseCommunity: hasPermission(
            grants,
            selectedOrg,
            FIREWISE_COMMUNITY_PERMISSION,
          ),
          fireRiskReductionCommunity: hasPermission(
            grants,
            selectedOrg,
            FRR_COMMUNITY_PERMISSION,
          ),
          activeFires: hasPermission(
            grants,
            selectedOrg,
            ACTIVE_FIRES_PERMISSION,
          ),
          correlatedRiskZones: hasPermission(
            grants,
            selectedOrg,
            CORRELATED_RISK_ZONES_PERMISSION,
          ),
          ...(isInsight && { insightScoring: { ...insightScoring } }),
          ...(hasAalValues && {
            averageAnnualLoss: { ...aalValidValues },
          }),
        },
      };

      datadogLogs.logger.info('Risk Report Request', {
        url: `${process.env.REACT_APP_API_RISK}/api/v1/${selectedOrg}/rzrisk/ui`,
        body: requestBody,
      });

      const dataResponse = await axios.post(
        `${process.env.REACT_APP_API_RISK}/api/v1/${selectedOrg}/rzrisk/ui`,
        requestBody,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const pdfResponse = await axios.post(
        `${process.env.REACT_APP_API_RISK}/api/v1/${selectedOrg}/rzrisk/ui/pdf`,
        requestBody,
        {
          headers: {
            accept: 'application/pdf',
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        },
      );

      datadogLogs.logger.info('Risk Report Response', {
        dataResponse,
      });

      setReportData([dataResponse, pdfResponse]);
      setButtonState(BUTTON_STATES.COMPLETE);
      navigate('/risk/results');
    } catch (res) {
      console.log('Error', res);
      setError(res);
      setButtonState(BUTTON_STATES.DEFAULT);
    }
  };

  return (
    <div
      className={`step-wrapper ${
        !isActive && 'incomplete'
      } component run-report`}
    >
      <Step
        titleText={titleText}
        step={currentNumberOfSteps}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        totalSteps={totalSteps}
        isComplete={false}
      />
      <div className={`step-container ${isActive ? 'show' : ''}`}>
        <div className="info">
          Submission received, data retrieval in progress. Do not navigate away
          from page.
        </div>
        <div className="button-container">
          <button
            type="button"
            className={`redwall primary ${buttonState}`}
            onClick={() => generateReport(formData)}
            disabled={buttonState !== BUTTON_STATES.DEFAULT}
            data-cy="run-report"
          >
            {buttonState === BUTTON_STATES.DEFAULT && 'Run Risk Report'}
            {buttonState === BUTTON_STATES.LOADING && (
              <>
                <div className="load-circle" />
                Processing
              </>
            )}
            {buttonState === BUTTON_STATES.COMPLETE && (
              <>
                <TmpCheckMarkAnimation />
                Complete
              </>
            )}
          </button>
          <button
            type="button"
            className="redwall secondary"
            onClick={() => {
              document
                .getElementsByClassName('create-report')[0]
                .scrollIntoView({
                  behavior: 'smooth',
                });
              setError(false);
              reset();
            }}
            data-cy="cancel-report"
          >
            Cancel
          </button>
        </div>
        <p className="fine-print">
          Once you run a risk report it will be counted and billed. This cannot
          be undone.
        </p>

        {error && (
          <>
            <p className="fine-print">{error.code}</p>
            <p className="fine-print">{error.message}</p>
            {/* NOTE: this extra details is for testing. we should remove before releasing to users */}
            {error.response?.data?.detail?.map((i) => (
              <p className="fine-print" key={JSON.stringify(i.loc)}>
                {i.msg}
              </p>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
