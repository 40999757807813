import React from 'react';
import { groupBy } from 'lodash-es';
import { MAPBOX_TOKEN } from '@redzone/map';
import { Accordion, AccordionSection, classStr } from '@redzone/shared';
import { useMapState } from '../../context/MapStateContext.jsx';
import './LayersCard.scss';

const getImageSource = (layer, { lat, lng, zoom }) =>
  `https://api.mapbox.com/styles/v1/mapbox/${layer}/static/${lng},${lat},${zoom}/60x60?access_token=${MAPBOX_TOKEN}`;

function CustomLayers() {
  const {
    availableCustomLayers,
    selectedCustomLayers,
    toggleSelectedCustomLayer,
  } = useMapState();

  const layerGroups = groupBy(availableCustomLayers, (l) => l.category);

  const isSelected = (layer) =>
    selectedCustomLayers.some((scl) => scl.id === layer.id);

  return Object.entries(layerGroups).map(([category, layers]) => (
    <div className="card-section">
      <Accordion key={category} className="custom-layers">
        <AccordionSection label={category}>
          <ul className="layers">
            {layers.map((l) => (
              <li className="custom-layer-row input-row neutral" key={l.id}>
                <input
                  className="layers-checkbox"
                  type="checkbox"
                  tabIndex={-1}
                  id={`custom-layer${l.id}`}
                  checked={isSelected(l)}
                  onChange={() => toggleSelectedCustomLayer(l)}
                  readOnly
                />
                <label className="layer-name" htmlFor={`custom-layer${l.id}`}>
                  {l.label}
                </label>
              </li>
            ))}
          </ul>
        </AccordionSection>
      </Accordion>
    </div>
  ));
}

function BaseLayer() {
  const { availableBaseLayers, baseLayer, setBaseLayer } = useMapState();

  return (
    <div className="card-section">
      <Accordion className="base-layers">
        <AccordionSection label="Base Layers">
          <div className="img-card-wrapper">
            {Object.entries(availableBaseLayers).map(
              ([id, { label, layer, image }]) => {
                const imageSource =
                  image ??
                  getImageSource(layer, {
                    baseLayer: availableBaseLayers[layer],
                    lat: 40,
                    lng: -114,
                    zoom: 4,
                  });

                return (
                  <div key={id} className="img-card">
                    <button
                      className={classStr({
                        'card-button': true,
                        rw: true,
                        selected: baseLayer === id,
                      })}
                      onClick={() => {
                        setBaseLayer(id);
                      }}
                      type="button"
                    >
                      <img src={imageSource} alt={label} />
                      <div className="img-card-title">{label}</div>
                    </button>
                  </div>
                );
              },
            )}
          </div>
        </AccordionSection>
      </Accordion>
    </div>
  );
}

export function LayersCard() {
  return (
    <div className="component layers-card">
      <CustomLayers />
      <BaseLayer />
    </div>
  );
}
