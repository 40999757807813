export function ArrowIcon(props) {
  const { orientation, ...rest } = props;
  let transform = '';

  switch (orientation) {
    case 'up':
      transform = 'rotate(-90) translate(-20, 0)';
      break;
    case 'right':
      transform = 'rotate(180) translate(-20, -20)';
      break;
    case 'down':
      transform = 'rotate(90) translate(0, -20)';
      break;
    default:
      transform = '';
      break;
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g id="arrow-left">
        <path
          id="Icon"
          d="M15.8333 10H4.16667M4.16667 10L10 15.8334M4.16667 10L10 4.16669"
          stroke="#44403C"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform={transform}
        />
      </g>
    </svg>
  );
}
