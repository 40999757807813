import { MAP_STYLES, useMap } from '@redzone/map';
import { createContext, useMemo, useContext, useState } from 'react';
import { useStateList } from '../hooks/use-state-list.js';

const MapStateContext = createContext();

export function useMapState() {
  return useContext(MapStateContext);
}

export function MapStateProvider(props) {
  const {
    availableBaseLayers = MAP_STYLES,
    availableCustomLayers = [],
    children,
    initiallySelectedCustomLayers = [],
    initiallySelectedSpecialLayers = [],
  } = props;

  const { setMapStyle } = useMap();
  const [baseLayer, _setBaseLayer] = useState(MAP_STYLES.standard);
  const [selectedCustomLayers, { toggle: toggleSelectedCustomLayer }] =
    useStateList(initiallySelectedCustomLayers, (l) => l.id);

  // TODO: get imported layers working
  const [importedLayers, setImportedLayers] = useState([]);
  const [selectedImportedLayers, setSelectedImportedLayers] = useState([]);
  const [selectedSpecialLayers, setSelectedSpecialLayers] = useState(
    initiallySelectedSpecialLayers,
  );

  const setBaseLayer = (id) => {
    setMapStyle(availableBaseLayers[id].url);
    _setBaseLayer(id);
  };

  const availableIds = new Set(availableCustomLayers.map((l) => l.id));

  const value = useMemo(
    () => ({
      availableBaseLayers,
      availableCustomLayers,
      baseLayer,
      importedLayers,
      setImportedLayers,
      selectedImportedLayers,
      setSelectedImportedLayers,
      selectedCustomLayers: selectedCustomLayers.filter((cl) =>
        availableIds.has(cl.id),
      ),
      setBaseLayer,
      selectedSpecialLayers,
      setSelectedSpecialLayers,
      toggleSelectedCustomLayer,
    }),
    [
      availableBaseLayers,
      baseLayer,
      importedLayers,
      selectedCustomLayers,
      selectedSpecialLayers,
    ],
  );

  return (
    <MapStateContext.Provider value={value}>
      {children}
    </MapStateContext.Provider>
  );
}
