import { createContext, useMemo, useContext, useRef, useState } from 'react';

const RiskContext = createContext();

const INITIAL_RISK_STATE = {
  baseLayer: 'risk',
};

export function useRisk() {
  return useContext(RiskContext);
}

export function useRiskState(id) {
  const { riskState, riskStateRef, setRiskState } = useContext(RiskContext);

  const setState = (nextVal) => {
    const nextRootVal = { ...riskStateRef.current, [id]: nextVal };
    setRiskState(nextRootVal);
    riskStateRef.current = nextRootVal;
  };

  if (!(id in riskStateRef.current)) {
    throw new Error(
      `the provided state key (${id}) is not known to Risk -- please check the Risk context and create the key or check your spelling`,
    );
  }

  return [riskState[id], setState];
}

// this is kind of a "grab bag" of state that gets used acrosss the dashboard.
// it is not meant to hold any state other than UI stuff. use it to open and
// close tool drawers, add tabs to the navigation, or the like. things that
// come and go during a single session. do NOT use it for any data entered by
// the user, or that will need to be saved.
export function RiskProvider(props) {
  const { children } = props;
  const [isContentDrawerOpen, setIsContentDrawerOpen] = useState(true);
  const [selectedPublicLayers, setSelectedPublicLayers] = useState([]);

  const [riskState, setRiskState] = useState(INITIAL_RISK_STATE);
  const [riskPointLocation, setRiskPointLocation] = useState(null);
  const [reportData, setReportData] = useState(null);
  const riskStateRef = useRef();
  riskStateRef.current = riskState;

  const value = useMemo(() => ({
    setRiskState,
    isContentDrawerOpen,
    setIsContentDrawerOpen,
    riskState,
    riskStateRef,
    selectedPublicLayers,
    setSelectedPublicLayers,
    reportData,
    setReportData,
    riskPointLocation,
    setRiskPointLocation,
  }));

  return <RiskContext.Provider value={value}>{children}</RiskContext.Provider>;
}
