import { useRef, useState } from 'react';
import { ChevronIcon, ClickOutside } from '@redzone/shared';
import { DateTimeFilterCollector } from './filter-collectors/DateTimeFilterCollector.jsx';
import { NumberRangeFilterCollector } from './filter-collectors/NumberRangeFilterCollector.jsx';
import { Portal } from '../../Portal.jsx';
import { DataTableControlBody } from './DataTableControlBody.jsx';
import { BooleanCollector } from './filter-collectors/BooleanCollector.jsx';
import { EnumCollector } from './filter-collectors/EnumCollector.jsx';
import './FilterCollector.scss';

const collectorsByType = {
  boolean: BooleanCollector,
  datetime: DateTimeFilterCollector,
  enum: EnumCollector,
  enumList: EnumCollector,
  number: NumberRangeFilterCollector,
};

function SingleFilterCollector(props) {
  const { type, open, toggleOpen, value, ...rest } = props;
  const { title } = props;
  const collectorRef = useRef();
  const collectorButtonRef = useRef();
  const [bodyPosition, setBodyPosition] = useState({ top: 350, left: 350 });
  const [isClosing, setIsClosing] = useState(false);

  const Collector = collectorsByType[type];
  if (!Collector) return null;

  const isModified =
    value !== undefined &&
    (typeof value !== 'object' || Object.keys(value).length > 0);

  ClickOutside(collectorRef, () => {
    if (open) {
      toggleOpen();
      setIsClosing(true);
      setTimeout(() => {
        setIsClosing(false);
      }, 350);
    }
  });

  const handleToggleClick = () => {
    if (!isClosing) toggleOpen();
    setIsClosing(false);
    if (collectorButtonRef.current) {
      const rect = collectorButtonRef.current.getBoundingClientRect();
      setBodyPosition({ left: rect.x, top: rect.bottom + 4 });
    }
  };

  return (
    <div className={`collector ${open ? 'open' : 'closed'}`} ref={collectorRef}>
      <button
        type="button"
        ref={collectorButtonRef}
        aria-label={title}
        className={`redwall unstyled collector-button${
          isModified ? ' modified' : ''
        }`}
        onClick={handleToggleClick}
      >
        <div>{title}</div>
        <ChevronIcon orientation={open ? 'up' : 'down'} className="icon" />
      </button>
      {open && (
        <Portal className="redwall-layout">
          <div
            ref={collectorRef}
            className={`redwall filter-collector portal ${type}`}
            style={{
              left: bodyPosition.left,
              top: bodyPosition.top,
            }}
          >
            <Collector value={value} {...rest} />
          </div>
        </Portal>
      )}
    </div>
  );
}

export function FilterCollector(props) {
  const { columns, filters, setFilters } = props;

  const [openFilter, setOpenFilter] = useState(null);

  return (
    <DataTableControlBody
      title="Filters"
      onReset={() => setFilters({})}
      className="component filter-collector"
    >
      <div className="collectors">
        {Object.entries(columns).map(([id, column]) => {
          const {
            type,
            filterable,
            key: propsKey,
            options,
            title: colTitle,
            presetFilterValues,
            customPreset,
          } = column;

          if (!filterable) return null;

          const key = propsKey ?? id;

          return (
            <SingleFilterCollector
              type={type ?? 'string'}
              presetFilterValues={presetFilterValues}
              customPreset={customPreset}
              setValue={(val) => {
                setFilters({
                  ...filters,
                  [id]: {
                    type: type ?? 'string',
                    params: val,
                  },
                });
              }}
              id={`filter-collector-${key}`}
              key={key}
              options={options}
              title={colTitle ?? id}
              value={filters[id]?.params}
              open={openFilter === id}
              toggleOpen={() => setOpenFilter(openFilter === id ? null : id)}
            />
          );
        })}
      </div>
    </DataTableControlBody>
  );
}
