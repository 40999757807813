import React from 'react';
import { MAPBOX_TOKEN } from '@redzone/map';
import { Geocoder } from './Geocoder.jsx';
import './AddressSearch.scss';

export function AddressSearch(props) {
  const { location, setLocation, pointLocation } = props;

  return (
    <div className="address-wrapper">
      <div className="address-search" data-cy="address">
        <Geocoder
          MAPBOX_TOKEN={MAPBOX_TOKEN}
          setLocation={setLocation}
          location={location}
        />
      </div>
      {pointLocation && (
        <div className="result-container">
          <dl>
            <dt>Latitude</dt>
            <dd>{pointLocation[1]}</dd>
          </dl>
          <dl>
            <dt>Longitude</dt>
            <dd>{pointLocation[0]}</dd>
          </dl>
        </div>
      )}
    </div>
  );
}
