import { RAW, useDataCollection } from './DataCollection.jsx'; // eslint-disable-line import/no-cycle
import { SortArrowsIcon } from '../../icons.js';
import { classStr } from '../../helpers.js';
import './DataCollectionTable.scss';

function SortColumnHeader(props) {
  const { column, sort, setSort } = props;
  const { type, id, title } = column;

  if (!id) {
    throw new Error('DataTable columns must include an id');
  }

  const activeSort = sort.length === 1 ? sort[0] : {};

  return (
    <th className={type}>
      <button
        type="button"
        className={classStr({
          'sort-button': true,
          sorting: activeSort.property === id,
          asc: activeSort.direction === 'asc',
          desc: activeSort.direction === 'desc',
        })}
        onClick={() => {
          setSort(
            activeSort.property === column.id
              ? activeSort.direction === 'asc'
                ? [{ property: id, direction: 'desc' }]
                : []
              : [{ property: id, direction: 'asc' }],
          );
        }}
      >
        <div className="title">{title}</div>
        <SortArrowsIcon className="sort-arrows" />
      </button>
    </th>
  );
}

export function DataCollectionTable(props) {
  const { className } = props;
  const { columns, data, rowKey, sort, setSort } = useDataCollection();

  const getKey = (row) => (rowKey ? row[rowKey] : row.key) ?? row.id;

  return (
    <div className={`data-table component stacked ${className ?? ''}`.trim()}>
      <div className="data-table-body stacked">
        <table>
          <thead>
            <tr>
              {Object.entries(columns).map(([colKey, column]) =>
                column.sortable ? (
                  <SortColumnHeader
                    column={{ id: colKey, ...column }}
                    sort={sort}
                    setSort={setSort}
                    key={column.key ?? column.title}
                  />
                ) : (
                  !column.hidden && (
                    <th className={column.type} key={colKey}>
                      {column.title}
                    </th>
                  )
                ),
              )}
            </tr>
          </thead>

          <tbody>
            {data.map((datum) => (
              <tr key={getKey(datum)}>
                {Object.entries(columns).map(
                  ([key, column]) =>
                    !column.hidden && (
                      <td key={key} className={column.type}>
                        {column.format
                          ? column.format(datum[key], datum[RAW])
                          : datum[key]}
                      </td>
                    ),
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
