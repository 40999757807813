export function InfoIcon20dp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g id="20dp/info-circle">
        <g id="vector">
          <path d="M10 9.25C10.4142 9.25 10.75 9.58579 10.75 10V13.3333C10.75 13.7475 10.4142 14.0833 10 14.0833C9.58579 14.0833 9.25 13.7475 9.25 13.3333V10C9.25 9.58579 9.58579 9.25 10 9.25Z" />
          <path d="M10 5.91663C9.58579 5.91663 9.25 6.25241 9.25 6.66663C9.25 7.08084 9.58579 7.41663 10 7.41663H10.0083C10.4225 7.41663 10.7583 7.08084 10.7583 6.66663C10.7583 6.25241 10.4225 5.91663 10.0083 5.91663H10Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25098 10.0003C2.25098 5.71999 5.72084 2.25012 10.0011 2.25012C14.2814 2.25012 17.7513 5.71999 17.7513 10.0003C17.7513 14.2806 14.2814 17.7504 10.0011 17.7504C5.72084 17.7504 2.25098 14.2806 2.25098 10.0003ZM10.0011 3.75012C6.54927 3.75012 3.75098 6.54842 3.75098 10.0003C3.75098 13.4522 6.54927 16.2504 10.0011 16.2504C13.453 16.2504 16.2513 13.4522 16.2513 10.0003C16.2513 6.54842 13.453 3.75012 10.0011 3.75012Z"
          />
        </g>
      </g>
    </svg>
  );
}
