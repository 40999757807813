export function FireGroupIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2354 14.5C17.0496 13.572 17.5 12.4059 17.5 11.1965C17.5 7.13812 14.0794 5.97859 13.7685 2.5C13.4331 2.65632 13.114 2.82196 12.8128 3M10.5 2.5C7.83333 3.75616 6.125 5.56718 6.12567 9.30362C6.12567 9.30362 4.5 9.40887 4.5 7.52463C3.16667 8.78079 2.5 9.72291 2.5 11.9212C2.5 13.4204 3.13214 14.8582 4.25736 15.9183C5.38258 16.9783 6.65385 17.5 8.5 17.5C10.3462 17.5 11.6174 16.9783 12.7426 15.9183C13.8679 14.8582 14.5 13.4204 14.5 11.9212C14.5 7.52463 10.8333 6.26847 10.5 2.5ZM9.28155 9.0625C8.23988 9.61207 7.57256 10.4044 7.57282 12.0391C7.57282 12.0391 6.9378 12.0851 6.9378 11.2608C6.41696 11.8103 6.15655 12.2225 6.15655 13.1843C6.15655 13.8402 6.40348 14.4692 6.84301 14.933C7.28255 15.3968 7.77914 15.625 8.5003 15.625C9.22145 15.625 9.71804 15.3968 10.1576 14.933C10.5971 14.4692 10.844 13.8402 10.844 13.1843C10.844 11.2608 9.41175 10.7112 9.28155 9.0625Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
