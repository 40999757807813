import { useRef } from 'react';
import { ChevronIcon } from '../icons.js';

// this is styled with the other Redwall form stuff in /packages/shared/styles/redwall/theme/forms.scss
function RedwallNumberInput(props) {
  const { className, ...rest } = props;
  const inputRef = useRef();

  return (
    <div className={`redwall-number-input ${className}`.trim()}>
      <input {...rest} type="number" ref={inputRef} />
      <div className="buttons">
        <button
          type="button"
          className="step-up"
          aria-label="step up"
          onClick={() => inputRef.current.stepUp()}
        >
          <ChevronIcon orientation="up" />
        </button>
        <button
          type="button"
          className="step-down"
          aria-label="step down"
          onClick={() => inputRef.current.stepDown()}
        >
          <ChevronIcon orientation="down" />
        </button>
      </div>
    </div>
  );
}

export function RedwallInput(props) {
  const { className, type, value } = props;

  const classNameWithEmpty =
    value === '' || !value ? `${className ?? ''} empty`.trim() : className;

  if (type === 'number') {
    return <RedwallNumberInput {...props} className={classNameWithEmpty} />;
  }

  return <input {...props} className={classNameWithEmpty} />;
}
