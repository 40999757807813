import { HistoricalFiresPerimeterPopup } from '../popups/HistoricalFiresPerimeterPopup.jsx';

const colors = [`#9A3131`, `#B86F6F`, `#D7ADAD`, `#EBD6D6`];

export const historicalFiresPerimeterLayer = {
  id: 'historical_fires_perimeter',
  category: 'Risk Layers',
  layerId: 'historical_fires_perimeter',
  label: 'Historical Fire Perimeters',
  source: {
    id: 'redzonesoftware.historical_fires_perimeter',
    type: 'vector',
    url: 'mapbox://redzonesoftware.historical_fires_perimeter',
  },
  mapboxLayers: [
    {
      id: 'redzonesoftware.historical_fires_perimeter',
      type: 'fill',
      source: 'redzonesoftware.historical_fires_perimeter',
      'source-layer': 'fire-perimeter',
      filter: [
        'any',
        ['>', 'FIRE_YEAR_INT', 1973],
        // TODO: verify with Clark about filter small fires
        ['>', 'GIS_ACRES', 100],
      ],
      paint: {
        'fill-color': [
          'case',
          ['>', 2000, ['get', 'FIRE_YEAR_INT']],
          colors[3],

          ['>', 2010, ['get', 'FIRE_YEAR_INT']],
          colors[2],

          ['>', 2020, ['get', 'FIRE_YEAR_INT']],
          colors[1],
          colors[0],
        ],
        'fill-opacity': 0.7,
      },
    },
  ],
  onClick: (e, fn) =>
    fn('historical_fires_perimeter', {
      center: {
        coordinates: [e.lngLat.lng, e.lngLat.lat],
      },
      features: e.features,
    }),
  popup: HistoricalFiresPerimeterPopup,
};
