import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useMap,
  useMapEffect,
  Layer,
  Source,
  Marker,
  MAPBOX_TOKEN,
} from '@redzone/map';
import { ArrowIcon } from '@redzone/shared';
import { getDistanceForHigherRisk } from '../helpers/helpers.js';
import { useRisk } from '../../context/RiskContext.jsx';
import './Report.scss';

const MAPBOX_LAYER_ID_SATELLITE = 'ciuzs0n2n00bw2iph9l79mtgz';
const MAPBOX_LAYER_ID_OVERALL = 'ciykl08rp00162rphs69unnff';
const MAPBOX_BASE_URL = 'https://api.mapbox.com/styles/v1/redzonesoftware/';

export function Report() {
  const navigate = useNavigate();
  const { reportData, setReportData } = useRisk();
  const { map: localMap } = useMap();
  const [report, pdf] = reportData;
  console.log('report', report);
  console.log('**************************************');

  const { location, metadata, rzFeatures } = report.data;
  const {
    riskScoring,
    fireHistory,
    averageAnnualLoss,
    distanceToHigherRiskClasses,
    firewiseCommunity,
  } = rzFeatures;

  const getImageSource = (point, layer) => {
    const MAPBOX_LOCATION = `/static/pin-m-home+48C(${point.lng},${point.lat})/${point.lng},${point.lat},13.25,0/450x350`;

    return `${MAPBOX_BASE_URL}${layer}${MAPBOX_LOCATION}?access_token=${MAPBOX_TOKEN}`;
  };

  useEffect(() => () => setReportData(null), []);

  /* Map Methods */
  useMapEffect(
    (map) => {
      if (location) {
        map.flyTo({
          center: [location.longitude, location.latitude],
          zoom: 15,
        });
      }
    },
    (map) => {
      map.flyTo({
        zoom: map.style.z - 7,
      });
    },
    [location],
  );

  return (
    <div className="report" data-cy="risk-report-preview">
      {location && (
        <>
          <Marker
            key={JSON.stringify([location.longitude, location.latitude])}
            map={localMap}
            startingLngLat={[location.longitude, location.latitude]}
            draggable={false}
          />
          <Source
            id="user-point"
            type="geojson"
            key={JSON.stringify(location)}
            data={{
              type: 'FeatureCollection',
              features: [
                {
                  id: '951e6b6a716d83f19e0656d94e252226',
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    coordinates: [location.longitude, location.latitude],
                    type: 'Point',
                  },
                },
              ],
            }}
          >
            <Layer
              id="user-point"
              mapboxLayer={{
                id: 'layer',
                type: 'circle',
                paint: {
                  'circle-radius': 5,
                  'circle-color': '#d13239',
                },
              }}
            />
          </Source>
        </>
      )}
      <section>
        <h2 className="title header">Risk Report</h2>
        <button
          type="button"
          className="redwall primary header"
          data-cy="download-pdf-report"
          onClick={() => {
            const link = document.createElement('a');
            const hasAddress = metadata.input_location.address;
            // create a blobURI pointing to our Blob
            link.href = URL.createObjectURL(pdf.data);
            link.download = hasAddress
              ? `RZRisk3_${metadata.input_location.address}, ${metadata.input_location.city}, ${metadata.input_location.state}, ${metadata.input_location.zip}.PDF`
              : `RZRisk3_${location.latitude}|${location.longitude}.PDF`;
            // some browser needs the anchor to be in the doc
            document.body.append(link);
            link.click();
            link.remove();
            // in case the Blob uses a lot of memory
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
          }}
        >
          Download As PDF
        </button>
        {metadata.input_location.address && (
          <>
            <div className="content-row">
              <div className="content-row-label">Address:</div>
              <div className="content-row-value">
                {metadata.input_location.address}
              </div>
            </div>
            <div className="content-row">
              <div className="content-row-label">City:</div>
              <div className="content-row-value">
                {metadata.input_location.city}
              </div>
            </div>
            <div className="content-row">
              <div className="content-row-label">State:</div>
              <div className="content-row-value">
                {metadata.input_location.state}
              </div>
            </div>
            <div className="content-row">
              <div className="content-row-label">Zip Code:</div>
              <div className="content-row-value">
                {metadata.input_location.zip}
              </div>
            </div>
          </>
        )}
        <div className="content-row">
          <div className="content-row-label">Latitude:</div>
          <div className="content-row-value">
            {metadata.input_location.latitude}
          </div>
        </div>
        <div className="content-row">
          <div className="content-row-label">Longitude:</div>
          <div className="content-row-value">
            {metadata.input_location.longitude}
          </div>
        </div>
      </section>

      <section>
        <h2 className="title">Risk Results</h2>
        <div className="content-row">
          <div className="content-row-label">Overall Score:</div>
          <div className="content-row-value">
            {riskScoring.data.overallScore} - {riskScoring.data.overallClass}
          </div>
        </div>
        <div className="content-row">
          <div className="content-row-label">Severity Score:</div>
          <div className="content-row-value">
            {riskScoring.data.severityScore} - {riskScoring.data.severityClass}
          </div>
        </div>
        <div className="content-row">
          <div className="content-row-label">Fire Frequency:</div>
          <div className="content-row-value">
            {riskScoring.data.frequencyScore} -{' '}
            {riskScoring.data.frequencyClass}
          </div>
        </div>
        <div className="content-row">
          <div className="content-row-label">Past Fire Activity:</div>
          <div className="content-row-value">
            {riskScoring.data.pastFireScore} - {riskScoring.data.pastFireClass}
          </div>
        </div>
        <div className="content-row">
          <div className="content-row-label">Predicted Loss Type:</div>
          <div className="content-row-value">{riskScoring.data.lossType}</div>
        </div>
        {averageAnnualLoss && (
          <>
            <div className="content-row">
              <div className="content-row-label">Gross Annual Loss:</div>
              <div className="content-row-value" data-cy="aal-gross">
                {averageAnnualLoss.data.grossAnnualLoss}
              </div>
            </div>
            <div className="content-row">
              <div className="content-row-label">Ground Up Annual Loss:</div>
              <div className="content-row-value" data-cy="aal-groundup">
                {averageAnnualLoss.data.groundUpAnnualLoss}
              </div>
            </div>
          </>
        )}
        {distanceToHigherRiskClasses &&
          getDistanceForHigherRisk(riskScoring, distanceToHigherRiskClasses)
            .length > 0 && (
            <div className="content-row">
              <div className="content-row-label">Distance To Higher Risk:</div>
              <div
                className="content-row-value"
                data-cy="distance-plus-fire-history"
              >
                {
                  getDistanceForHigherRisk(
                    riskScoring,
                    distanceToHigherRiskClasses,
                  )[0].distanceFromPoint
                }{' '}
                Miles
              </div>
            </div>
          )}
        {firewiseCommunity && (
          <div className="content-row">
            <div className="content-row-label">Firewise Community:</div>
            <div className="content-row-value" data-cy="firewise-community">
              {firewiseCommunity.data.firewiseCommunity ? 'Yes' : 'NO'}
            </div>
          </div>
        )}
      </section>

      <section>
        <div className="content-row has-image">
          <div className="content-row-image">
            <img
              src={getImageSource(
                {
                  lat: location.latitude,
                  lng: location.longitude,
                },
                MAPBOX_LAYER_ID_OVERALL,
              )}
              alt="satellite view of location"
            />
            <div className="image-caption">RZ Overall Risk</div>
          </div>
          <div className="content-row-image">
            <img
              src={getImageSource(
                {
                  lat: location.latitude,
                  lng: location.longitude,
                },
                MAPBOX_LAYER_ID_SATELLITE,
              )}
              alt="satellite view of location"
            />
            <div className="image-caption">Satellite View</div>
          </div>
        </div>
      </section>

      {fireHistory?.data.length > 0 && (
        <section data-cy="fire-history-table">
          <h2 className="title">Fire History Summary</h2>
          <div className="content">
            <table className="content-table">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Incident Name</th>
                  <th>Acres</th>
                  <th>Dist (mi)</th>
                </tr>
              </thead>
              <tbody>
                {Object.values(fireHistory.data)
                  .sort((a, b) => a.fireYear - b.fireYear)
                  .slice(Math.max(fireHistory?.data.length - 10, 0))
                  .map((obj) => (
                    <tr key={obj.fireName}>
                      <td title={obj.fireYear}>{obj.fireYear}</td>
                      <td title={obj.fireName}>{obj.fireName}</td>
                      <td title={obj.finalAcres}>{obj.finalAcres}</td>
                      <td title={obj.distanceFromPoint}>
                        {obj.distanceFromPoint}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </section>
      )}

      <button
        type="button"
        className="redwall secondary risk-startover"
        onClick={() => navigate('/risk/report')}
        data-cy="start-over"
      >
        <ArrowIcon />
        Start Over
      </button>
    </div>
  );
}
