import './DataTableControlBody.scss';

export function DataTableControlBody(props) {
  const {
    children,
    className = '',
    resetText = 'Reset All',
    title,
    onReset,
  } = props;

  return (
    <div className={`component data-table-control-body ${className}`.trim()}>
      <div className="header">
        <h3>{title}</h3>
        {onReset && (
          <button type="button" className="redwall unstyled" onClick={onReset}>
            {resetText}
          </button>
        )}
      </div>
      {children}
    </div>
  );
}
