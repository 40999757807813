export function FireCircleIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 4.50016C9.5 5.42135 8.21875 6.74942 8.21925 9.48948C8.21925 9.48948 7 9.56667 7 8.18489C6 9.10608 5.5 9.79696 5.5 11.409C5.5 12.5084 5.97411 13.5628 6.81802 14.3402C7.66193 15.1176 8.61538 15.5002 10 15.5002C11.3846 15.5002 12.3381 15.1176 13.182 14.3402C14.0259 13.5628 14.5 12.5084 14.5 11.409C14.5 8.18489 11.75 7.26371 11.5 4.50016Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 9.50016C10.3333 9.83514 9.90625 10.3181 9.90642 11.3145C9.90642 11.3145 9.5 11.3425 9.5 10.8401C9.16667 11.175 9 11.4263 9 12.0125C9 12.4123 9.15804 12.7957 9.43934 13.0784C9.72064 13.3611 10.0385 13.5002 10.5 13.5002C10.9615 13.5002 11.2794 13.3611 11.5607 13.0784C11.842 12.7957 12 12.4123 12 12.0125C12 10.8401 11.0833 10.5051 11 9.50016Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
