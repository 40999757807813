import { useEffect, useState } from 'react';
import { Layer, Map as RedzoneMap, Source, useMapEffect } from '@redzone/map';
import { useMapState } from '@redzone/shared';
import { popupComponents } from '../../constants/popup-components.js';
import './Map.scss';

export function Map(props) {
  const { baseLayer } = props;
  const { selectedCustomLayers, setBaseLayer } = useMapState();
  const [popup, setPopup] = useState(null);

  const handleLayerClick = (layerId, ev) => {
    const popupComponent = popupComponents[layerId];
    const { features } = ev;

    if (popupComponent && features?.length > 0) {
      setPopup({
        componentProps: { features, center: ev.center },
        component: popupComponent,
        layerId,
      });
    }
  };

  useMapEffect(
    () => {
      setBaseLayer(baseLayer);
    },
    () => {},
    [],
  );

  useEffect(() => {
    const layerIds = Object.keys(selectedCustomLayers);
    if (!layerIds.includes(popup?.layerId)) {
      setPopup(null);
    }
  }, [JSON.stringify(selectedCustomLayers)]);

  // NOTE: The base layer of the map is handled in MapStateContext
  return (
    <div className="map-container light-theme">
      <RedzoneMap />

      {Object.entries(selectedCustomLayers).map(([sourceId, sourceLayer]) => (
        <Source key={sourceId} id={sourceId} passive {...sourceLayer.source}>
          {(typeof sourceLayer.mapboxLayers === 'function'
            ? sourceLayer.mapboxLayers()
            : sourceLayer.mapboxLayers
          ).map((layer) => (
            <Layer
              key={layer.id}
              id={layer.id}
              filter={
                typeof layer.filter === 'function'
                  ? layer.filter(dataByLayerId[sourceLayer.id])
                  : layer.filters
              }
              mapboxLayer={layer}
              onClick={(ev) => {
                if (!sourceLayer.onClick) return;
                sourceLayer.onClick(ev, handleLayerClick);
              }}
            />
          ))}
        </Source>
      ))}

      {popup ? (
        <popup.component {...popup.componentProps} setPopup={setPopup} />
      ) : null}
    </div>
  );
}
