import React from 'react';
import './IconInput.scss';

export function IconInput(props) {
  const {
    icon: Icon,
    iconSide,
    iconLight,
    innerRef,
    value,
    fontSize,
    ...rest
  } = props;

  return (
    <div className="icon-input component">
      <input
        className={`redwall ${fontSize ?? 'md'} regular ${
          Icon && `${iconSide}`
        } `.trim()}
        ref={innerRef}
        value={value}
        {...rest}
      />
      {Icon && (
        <Icon
          className={`icon ${iconSide ?? ''} ${
            iconLight ? 'light' : ''
          }`.trim()}
        />
      )}
    </div>
  );
}
