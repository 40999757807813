import './CardSection.scss';

export function CardSection(props) {
  const { title, children, className } = props;

  return (
    <section className={`card-section ${className ?? ''}`.trim()}>
      {title && <h2 className="title">{title}</h2>}
      {children}
    </section>
  );
}
