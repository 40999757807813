import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { omit } from 'lodash';
import { CalendarIcon, XIcon } from '../../../../icons.js';

export function DateTimeFilterCollector(props) {
  const { value = {}, setValue } = props;
  const [preset, setPreset] = useState(
    value.start || value.end ? 'custom' : 'any',
  );

  return (
    <div className="datetime-collector">
      <div className="preset-row">
        <input
          id="datetime-any"
          className="preset"
          type="radio"
          onChange={() => {
            setValue({});
            setPreset('any');
          }}
          checked={preset === 'any'}
        />
        <label htmlFor="datetime-any">Any</label>
      </div>
      <div className="preset-row">
        <input
          id="datetime-custom"
          className="preset"
          type="radio"
          onChange={() => {
            setPreset('custom');
          }}
          checked={preset === 'custom'}
        />
        <label htmlFor="datetime-custom">Custom</label>
      </div>
      <div className="custom-row input-row datetime-row">
        {['start', 'end'].map((time) => (
          <div className="datetime-collector" key={time}>
            <label htmlFor={`datetime-${time}`}>{time}</label>
            <DatePicker
              id={`datetime-${time}`}
              onChange={(val) => {
                setPreset('custom');

                if (!val) {
                  setValue(omit(value, [time]));
                } else {
                  setValue({ ...value, [time]: val });
                }
              }}
              selected={value[time]}
              showIcon
              icon={
                // eslint-disable-next-line
                <label className="icon-label" htmlFor={`datetime-${time}`}>
                  <CalendarIcon />
                </label>
              }
              showTimeInput
              dateFormat="MM/dd/yyyy, h:mm aa"
              placeholderText="mm/dd/yy, 12:00 AM"
            />
            {value[time] && (
              <button
                type="button"
                className="redwall unstyled"
                aria-label="clear"
                onClick={() => {
                  setValue(omit(value, [time]));
                }}
              >
                <XIcon />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
