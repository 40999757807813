export function RadioIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0002 11.6667C10.9206 11.6667 11.6668 10.9205 11.6668 10C11.6668 9.07954 10.9206 8.33334 10.0002 8.33334C9.07969 8.33334 8.3335 9.07954 8.3335 10C8.3335 10.9205 9.07969 11.6667 10.0002 11.6667Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5333 6.46667C13.9982 6.93104 14.367 7.48248 14.6186 8.08947C14.8703 8.69646 14.9998 9.34709 14.9998 10.0042C14.9998 10.6612 14.8703 11.3119 14.6186 11.9189C14.367 12.5259 13.9982 13.0773 13.5333 13.5417M6.46668 13.5333C6.0018 13.069 5.633 12.5175 5.38138 11.9105C5.12976 11.3035 5.00024 10.6529 5.00024 9.99584C5.00024 9.33876 5.12976 8.68813 5.38138 8.08114C5.633 7.47415 6.0018 6.9227 6.46668 6.45834M15.8917 4.10834C17.4539 5.67107 18.3316 7.7903 18.3316 10C18.3316 12.2097 17.4539 14.3289 15.8917 15.8917M4.10834 15.8917C2.54609 14.3289 1.66846 12.2097 1.66846 10C1.66846 7.7903 2.54609 5.67107 4.10834 4.10834"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
