import { Children, cloneElement, useState } from 'react';
import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import './Accordion.scss';

export function AccordionLabelTitleChevron(label, onClick, className = null) {
  return (
    <div className="accordion-label title-chevron">
      <h2 className="label">{label}</h2>
      <button
        aria-label="toggle box"
        type="button"
        className="toggle-holder"
        onClick={onClick}
      >
        <ChevronIcon orientation="right" />
      </button>
    </div>
  );
}

export function AccordionSection(props) {
  const { className, default: _, label, onClick, open, ...rest } = props;
  const fullClassName = [className, 'accordion-body'].filter(Boolean).join(' ');

  return (
    <div className={`accordion-section ${open ? 'open' : 'closed'}`}>
      <button
        type="button"
        className={`accordion-label-container ${label}`}
        onClick={onClick}
        data-cy={`accordion-section-${label}`}
      >
        <div className="accordion-label">{label}</div>
        <div className="accordion-chevron">
          <ChevronIcon orientation="down" />
        </div>
      </button>
      <div className="accordion-body-container">
        <div {...rest} className={fullClassName} />
      </div>
    </div>
  );
}

export function Accordion(props) {
  const {
    children,
    className: rawClassName,
    expanded: controlledExpanded,
    setExpanded: controlledSetExpanded,
    label,
    onClick,
    ...rest
  } = props;
  const childArray = Children.toArray(children);
  const defaultChild = childArray.find((child) => child.props.default);

  const internalExpandedIdState = useState(defaultChild?.props?.id);

  const [expanded, setExpanded] =
    controlledExpanded == null
      ? internalExpandedIdState
      : [controlledExpanded, controlledSetExpanded];

  const className = [rawClassName, 'accordion'].filter(Boolean).join(' ');

  return (
    <div {...rest} className={className}>
      {childArray.map((child, idx) => {
        const id = child.props.id ?? idx;

        return cloneElement(child, {
          ...child.props,
          open: expanded === id,
          onClick: () => {
            if (onClick) onClick(id);
            setExpanded(id === expanded ? null : id);
          },
        });
      })}
    </div>
  );
}
