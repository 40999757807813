export function SelectPoliciesIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99951 3C4.46908 3 3.96037 3.21071 3.5853 3.58579C3.21023 3.96086 2.99951 4.46957 2.99951 5M18.9995 3C19.5299 3 20.0387 3.21071 20.4137 3.58579C20.7888 3.96086 20.9995 4.46957 20.9995 5M20.9995 19C20.9995 19.5304 20.7888 20.0391 20.4137 20.4142C20.0387 20.7893 19.5299 21 18.9995 21M4.99951 21C4.46908 21 3.96037 20.7893 3.5853 20.4142C3.21023 20.0391 2.99951 19.5304 2.99951 19M8.99951 3H9.99951M8.99951 21H9.99951M13.9995 3H14.9995M13.9995 21H14.9995M2.99951 9V10M20.9995 9V10M2.99951 14V15M20.9995 14V15"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
