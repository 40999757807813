import React, { useState, useEffect } from 'react';
import { Portal } from './Portal.jsx';
import './HoverTooltip.scss';

export function HoverTooltip(props) {
  const { children, title } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({
    left: 0,
    top: 0,
  });

  const [tooltipTrianglePosition, setTooltipTrianglePosition] = useState({
    left: 0,
    top: 0,
  });

  const toggleTooltip = (e) => {
    const rect = e.target.getBoundingClientRect();

    setTooltipPosition({
      left: rect.x,
      top: rect.y + window.scrollY - 10,
    });

    setTooltipTrianglePosition({
      left: rect.left,
      top: rect.y - 10,
    });

    setShowTooltip((prev) => !prev);
  };

  useEffect(() => {
    if (showTooltip) {
      const rect = document.querySelector(".tooltip-body-wrapper").getBoundingClientRect();

      if (rect.x < 0) {
        setTooltipPosition({
          left: (rect.x * -1) + tooltipPosition.left + 2,
          top: tooltipPosition.top,
        });
      }

      if (rect.y < 0) {
        setTooltipPosition({
          left: tooltipPosition.left,
          top: (rect.y * -1) + tooltipPosition.top + 2,
        });
      }
    }
  }, [showTooltip])

  return (
    <div
      className="hover-tooltip component"
      onMouseEnter={(e) => toggleTooltip(e)}
      onMouseLeave={(e) => toggleTooltip(e)}
    >
      {title}
      {showTooltip && (
        <Portal className="hover-tooltip portal">
          <>
            <div
              className="tooltip-body-wrapper"
              style={{
                left: tooltipPosition.left,
                top: tooltipPosition.top,
              }}
            >
              <div className="tooltip-body">{children}</div>
            </div>
            <div
              className="tooltip-triangle"
              style={{
                left: tooltipTrianglePosition.left,
                top: tooltipTrianglePosition.top,
              }}
            ></div>
          </>
        </Portal>
      )}
    </div>
  );
}
