/* eslint-disable import/no-cycle */

import { mapValues, orderBy } from 'lodash';
import { createContext, useContext, useMemo, useState } from 'react';
import FuzzySearch from 'fuzzy-search';
import { filterWithCollectors } from '../../filter-helpers.js';
import { classStr } from '../../helpers.js';
import { pipeThru } from '../../utils.js';
import { DataCollectionTable } from './DataCollectionTable.jsx';
import { DataCollectionControls } from './DataCollectionControls.jsx';
import './DataCollection.scss';

const DataCollectionContext = createContext({});

export function useDataCollection() {
  return useContext(DataCollectionContext);
}

export const RAW = Symbol('raw');

/**
 *
 * This component creates filters and sorting around a collection of data. It
 * takes in several parameters; most importantly `data`. The `children` param
 * is a function that receives the filtered and sorted data items. This isn't
 * exactly standard and could be refactored if there's another way.
 *
 * Usage can be gleaned from finding examples of use throughout the code.
 */

export function DataCollection(props) {
  const {
    children,
    className,
    columns,
    data: rawData,
    header,
    initialFilters,
    rowKey,
    filters: controlledFilters,
    setFilters: controlledSetFilters,
    sort: controlledSort,
    setSort: controlledSetSort,
    search: controlledSearch,
    setSearch: controlledSetSearch,
    ...rest
  } = props;

  const [internalFilters, internalSetFilters] = useState(initialFilters ?? {});
  const [filters, setFilters] = controlledFilters
    ? [controlledFilters, controlledSetFilters]
    : [internalFilters, internalSetFilters];

  const [internalSort, setInternalSort] = useState([]);
  const [sort, setSort] = controlledSort
    ? [controlledSort, controlledSetSort]
    : [internalSort, setInternalSort];

  const [internalSearch, setInternalSearch] = useState('');
  const [search, setSearch] = controlledSearch
    ? [controlledSearch, controlledSetSearch]
    : [internalSearch, setInternalSearch];
  const searchFn = (data) => {
    if (!search) return data;

    const searchableColumns = Object.keys(columns).filter(
      (col) => columns[col].searchable,
    );

    const searcher = new FuzzySearch(data, searchableColumns);
    return searcher.search(search);
  };

  const sortPropertyFns = sort.map(
    (s) => columns[s.property].sortValue ?? s.property,
  );

  const sortFn =
    sort.length > 0
      ? (data) =>
          orderBy(
            data,
            sortPropertyFns,
            sort.map((s) => s.direction),
          )
      : (data) => data;

  const data = pipeThru(rawData, [
    (data) =>
      data.map((datum) => ({
        ...mapValues(columns, ({ value }) => value(datum)),
        [RAW]: datum,
      })),
    (data) => filterWithCollectors(data, filters, columns),
    searchFn,
    sortFn,
  ]);

  const contextValue = useMemo(
    () => ({
      columns,
      data,
      filters,
      rawData,
      rowKey,
      setFilters,
      sort,
      setSort,
      search,
      setSearch,
    }),
    [columns, data, filters, sort, search],
  );

  return (
    <div
      {...rest}
      className={classStr({
        'data-collection': true,
        component: true,
        stacked: true,
        ...(className ? { [className]: true } : {}),
      })}
    >
      <DataCollectionContext.Provider value={contextValue}>
        {children ?? (
          <>
            <DataCollectionControls header={header} rowKey={rowKey} />
            <DataCollectionTable />
          </>
        )}
      </DataCollectionContext.Provider>
    </div>
  );
}
