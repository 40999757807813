import {
  isDeductibleValid,
  isDeductibleWithTivValid,
} from '../components/helpers/helpers.js';

export const AAL_TIV_CONFIG = {
  totalInsuredValue: {
    id: 'totalInsuredValue',
    label: 'Total Insured Value',
    type: 'text',
    placeholder: '400,000',
  },
  buildingInsuredValue: {
    id: 'buildingInsuredValue',
    label: 'Building Insured Value',
    type: 'text',
    placeholder: '280,000',
  },
  contentsInsuredValue: {
    id: 'contentsInsuredValue',
    label: 'Contents Insured Value',
    type: 'text',
    placeholder: '80,000',
  },
  lossOfUseInsuredValue: {
    id: 'lossOfUseInsuredValue',
    label: 'Loss Of Use Insured Value',
    type: 'text',
    placeholder: '40,000',
  },
  siteDeductibleAsValue: {
    label: 'Site Deductible As Value',
    validation: isDeductibleWithTivValid,
  },
  siteDeductibleAsPctOfTotal: {
    label: 'Site Deductible As Percentage Of Total',
    validation: isDeductibleValid,
  },
};

export const INIT_AAL_VALUES = {
  totalInsuredValue: '',
  buildingInsuredValue: '',
  contentsInsuredValue: '',
  lossOfUseInsuredValue: '',
  siteDeductibleAsValue: '',
  siteDeductibleAsPctOfTotal: '',
};
