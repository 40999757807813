/*
  Relies on a div with id="portal-root" in the index.html file
*/

import { createPortal } from 'react-dom';

export function Portal({ children, className }) {
  return createPortal(
    <div className={className}>{children}</div>,
    document.body,
    'portal-root',
  );
}
