import { useEffect, useState } from 'react';
import { RedwallPopup } from './RedwallPopup.jsx';
import { shortCompactLocalizeNumber } from '../../utils/formatter.js';

export function HistoricalFiresPerimeterPopup(props) {
  const { features, center } = props;
  const [selectedFireIdx, setSelectedFireIdx] = useState(0);
  const selectedFire = features[selectedFireIdx];

  useEffect(() => {
    setSelectedFireIdx(0);
  }, [features]);

  if (!features || !selectedFire) return null;

  return (
    <RedwallPopup
      title="INCIDENT DETAILS"
      name="FIRE"
      coordinates={center.coordinates}
      features={features}
      setSelectedFeatureIdx={setSelectedFireIdx}
      selectedFeatureIdx={selectedFireIdx}
    >
      <table>
        <tbody>
          <tr>
            <td className="label">Name</td>
            <td className="value">{selectedFire.properties.INCIDENT}</td>
          </tr>
          <tr>
            <td className="label">Year</td>
            <td className="value">{selectedFire.properties.FIRE_YEAR_INT}</td>
          </tr>
          <tr>
            <td className="label">Acres</td>
            <td className="value">
              {!selectedFire.properties.GIS_ACRES ??
              Number.isNaN(selectedFire.properties.GIS_ACRES)
                ? 'N/A'
                : shortCompactLocalizeNumber(selectedFire.properties.GIS_ACRES)}
            </td>
          </tr>
        </tbody>
      </table>
    </RedwallPopup>
  );
}
