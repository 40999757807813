import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  useRouteError,
  Navigate,
} from 'react-router-dom';
import { RedwallLayout, About } from '@redzone/shared';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useRisk } from '../../context/RiskContext.jsx';
import { App } from './App.jsx';
import { Risk } from '../risk/Risk.jsx';
import { Main } from '../risk/Main.jsx';
import { Report } from '../risk/Report.jsx';

function TopError() {
  const error = useRouteError();
  console.error(error);

  return (
    <div>
      <div>Error</div>
      <div>{JSON.stringify(error?.message ?? error)}</div>
    </div>
  );
}

export function AuthenticationGuard({ component }) {
  const Component = withAuthenticationRequired(component);

  return <Component />;
}

function DataGuard() {
  const { reportData } = useRisk();
  return !reportData ? <Navigate to="/risk/report" /> : <Report />;
}

function InitialRedirect() {
  const initURL = localStorage.getItem('initialURL');
  localStorage.setItem('initialURL', '/');

  return <Navigate to={initURL} />;
}

const routes = (
  <Route
    path="/"
    element={<AuthenticationGuard component={App} />}
    errorElement={<TopError />}
  >
    <Route index element={<Navigate to="risk" replace />} />
    <Route
      path="risk"
      element={
        <RedwallLayout className="map">
          <Risk />
        </RedwallLayout>
      }
    >
      <Route index element={<Navigate to="report" replace />} />
      <Route path="report" element={<Main />} />
      <Route path="results" element={<DataGuard />} />
    </Route>

    <Route path="/about" element={<About appName="risk-app" />} />
    <Route path="init" element={<InitialRedirect />} />
    <Route path="*" element={<Navigate to="/risk" />} />
  </Route>
);

export const router = createBrowserRouter(createRoutesFromElements(routes));
