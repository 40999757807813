import React, { useEffect, useState } from 'react';
import { CheckIcon } from '@redzone/shared/icons';
import './Step.scss';

const STEP_STATES = {
  ACTIVE: 'active',
  COMPLETE: 'complete',
  INCOMPLETE: 'incomplete',
};

export function Step(props) {
  const {
    titleText,
    description,
    step,
    currentStep,
    totalSteps,
    isComplete,
    isOptional,
  } = props;
  const showConnector = currentStep <= totalSteps && isComplete;
  const isActive = currentStep === step;

  const [stepState, setStepState] = useState();

  useEffect(() => {
    setStepState(() => {
      if (currentStep === step) {
        return STEP_STATES.ACTIVE;
      }
      if (isComplete) {
        return STEP_STATES.COMPLETE;
      }
      return STEP_STATES.INCOMPLETE;
    });
  }, [step, currentStep, isComplete]);

  return (
    <>
      <div
        className={`redwall unstyled legend-container ${stepState}`}
        aria-labelledby={`Step ${step}: ${titleText}`}
        data-cy={`step-${step}`}
      >
        <div className={`step-badge ${stepState}`}>
          {isComplete ? (
            <CheckIcon className="checkmark" />
          ) : (
            <span>{step}</span>
          )}
        </div>
        <header className="semibold">
          {titleText}
          {isOptional && <span className="is-optional">(Optional)</span>}
        </header>
      </div>
      {isActive && <div className="step-description">{description}</div>}
      <div className={`step-connector ${showConnector ? 'show' : ''}`} />
    </>
  );
}
