import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { StaticImage } from './StaticImage.jsx';
import { Menu } from './Menu.jsx';
import { LogoutIcon } from '../icons/LogoutIcon.jsx';
import { HelpIcon } from '../icons/HelpIcon.jsx';
import { InfoIcon20dp } from '../icons/InfoIcon20dp.jsx';
import './Header.scss';

export function Header(props) {
  const {
    children,
    menuList,
    className,
    title,
    user,
    logout,
    version,
    helpLink,
  } = props;
  const menuRef = useRef();

  const userElement = {
    key: 'user',
    withBorder: true,
    element: (
      <div className="user-container name">
        <p>{user?.name ?? user?.nickname}</p>
        <span>{user?.email}</span>
      </div>
    ),
  };

  const versionElement = {
    key: 'version',
    withBorder: true,
    element: (
      <div className="user-container version">
        <span>v{version}</span>
      </div>
    ),
  };

  const logoutElement = {
    key: 'logout',
    element: (
      <div className="logout-container">
        <button
          type="button"
          onClick={() => logout()}
          className="redwall unstyled logout-button"
        >
          <LogoutIcon /> Logout
        </button>
      </div>
    ),
  };

  const aboutElement = {
    key: 'about',
    element: (
      <div className="user-container">
        <span className="about-item">
          <Link to="/about" target="_blank" replace>
            <InfoIcon20dp /> About
          </Link>
        </span>
      </div>
    ),
  };

  const helpElement = {
    key: 'Help',
    element: (
      <div className="user-container">
        <span>
          <Link to={helpLink} replace target="_blank">
            <HelpIcon /> Help
          </Link>
        </span>
      </div>
    ),
  };

  return (
    <header className={`${className} header component`}>
      <div className="logo">
        <Link className="name-container" to="/" reloadDocument>
          <span className="name">RZ</span>
          <span className="product">{title}</span>
        </Link>
        <span className="powered-by">Powered by</span>
        <Link
          to="https://www.redzone.co/"
          className="logo-link"
          target="_blank"
        >
          <StaticImage
            className="redzone-logo"
            src="/images/logos/rz_logo_200_wt.png"
            alt="RedZone Logo"
          />
        </Link>
      </div>
      {children}
      {user && (
        <div className="user-menu-container" ref={menuRef}>
          <Menu
            id="user-menu"
            title={user?.name ?? 'Unknown User'} // get user info
            list={[
              userElement,
              ...(menuList ?? []),
              helpElement,
              aboutElement,
              logoutElement,
              versionElement,
            ]}
          />
        </div>
      )}
    </header>
  );
}
