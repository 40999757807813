import { useState } from 'react';
import { pickBy } from 'lodash-es';
import { Select } from '../../Select.jsx';
import { PlusIcon, XIcon } from '../../../icons.js';
import { DataTableControlBody } from './DataTableControlBody.jsx';
import './SortCollector.scss';

export function SortCollector(props) {
  const { columns, sort, setSort } = props;

  const sortableColumns = pickBy(columns, (col) => col.sortable);
  const properties = Object.entries(sortableColumns).map(
    ([value, { title }]) => ({
      value,
      label: title,
    }),
  );

  const [sortRows, setSortRows] = useState(sort.length === 0 ? [{}] : sort);

  const unusedSortProperties = properties.filter(
    (i) => !sortRows.map((item) => item.property).includes(i.value),
  );

  const updateSort = (nextRows) => {
    setSortRows(nextRows.length === 0 ? [{}] : nextRows);
    setSort(nextRows.filter((sr) => sr.property && sr.direction));
  };

  const handleChange = (idx, newVal) => {
    updateSort(sortRows.map((val, i) => (i === idx ? newVal : val)));
  };

  const handleRemove = (idx) => {
    updateSort(sortRows.filter((_, i) => i !== idx));
  };

  const isEmpty = (obj) => Object.keys(obj).length === 0;

  return (
    <DataTableControlBody
      title="Sort by"
      onReset={() => updateSort([])}
      className="component sort-collector"
    >
      <div className="body">
        {sortRows.map((value, idx) => {
          const selectedOption = properties.filter(
            (i) => i.value === value.property,
          );
          const options = [
            { label: 'Select Property' },
            !isEmpty(value) && selectedOption[0],
            ...unusedSortProperties,
          ].filter((i) => i);

          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`${value.property}-${value.direction}-${idx}`}
              className="multi-input-row input-row"
            >
              <Select
                id={`sort-property-${idx}`}
                className="property compact"
                options={options}
                value={value.property}
                onChange={(newProp) =>
                  handleChange(idx, {
                    property: newProp,
                    direction: value.direction,
                  })
                }
                placeholder="Select Property"
              />
              <Select
                id={`sort-direction-${idx}`}
                className="direction compact"
                options={[
                  { label: 'Select Direction' },
                  { label: 'Ascending', value: 'asc' },
                  { label: 'Descending', value: 'desc' },
                ]}
                value={value.direction}
                onChange={(newDir) =>
                  handleChange(idx, {
                    property: value.property,
                    direction: newDir,
                  })
                }
                placeholder="Select Direction"
              />
              <button
                type="button"
                aria-label="remove sorting"
                className="redwall unstyled remove"
                onClick={() => handleRemove(idx)}
              >
                <XIcon />
              </button>
            </div>
          );
        })}
      </div>

      <div className="footer">
        <button
          type="button"
          onClick={() => setSortRows([...sortRows, {}])}
          className="redwall unstyled add-sorting"
          disabled={sortRows.length === properties.length}
        >
          <PlusIcon /> Add Sorting
        </button>
      </div>
    </DataTableControlBody>
  );
}
