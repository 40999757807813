import { useEffect, useState } from 'react';
import { Popup } from '@redzone/map';
import { format, isValid } from 'date-fns';

const formatDate = (timeSt) => {
  const date = new Date(timeSt);
  return isValid(date) ? format(date, 'MMM d, h:mmaaa') : '';
};
export function HistoricalFiresPopup(props) {
  const { features, center } = props;
  const [selectedFireIdx, setSelectedFireIdx] = useState(0);
  const selectedFire = features[selectedFireIdx];

  useEffect(() => {
    setSelectedFireIdx(0);
  }, [features]);

  if (!features) return null;

  return (
    <Popup coordinates={center.coordinates}>
      <div className="popup">
        <h3>
          {features.length > 1 && (
            <button
              type="button"
              className="prev-button"
              aria-label="previous fire"
              id="prev-fire-button"
              onClick={() => {
                setSelectedFireIdx(
                  selectedFireIdx === 0
                    ? features.length - 1
                    : selectedFireIdx - 1,
                );
              }}
            >
              &lt;
            </button>
          )}
          <div className="title">
            Fire {selectedFireIdx + 1} of {features.length}
          </div>
          {features.length > 1 && (
            <button
              type="button"
              className="next-button"
              aria-label="next fire"
              id="next-fire-button"
              onClick={() => {
                setSelectedFireIdx((selectedFireIdx + 1) % features.length);
              }}
            >
              &gt;
            </button>
          )}
        </h3>
        <table>
          <tbody>
            <tr>
              <td className="label">Incident Name</td>
              <td>{selectedFire.properties.IncidentName}</td>
            </tr>
            <tr>
              <td className="label">Incident Size</td>
              <td>{selectedFire.properties.IncidentSize}</td>
            </tr>
            <tr>
              <td className="label">Fire Discovery Date</td>
              <td>
                {formatDate(selectedFire.properties.FireDiscoveryDateTime)}
              </td>
            </tr>
            <tr>
              <td className="label">Containment Date</td>
              <td>{formatDate(selectedFire.properties.ContainmentDateTime)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Popup>
  );
}
