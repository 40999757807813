export function SearchIcon({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8541 14.9146C12.5765 15.9577 10.9446 16.5833 9.16667 16.5833C5.07055 16.5833 1.75 13.2628 1.75 9.16667C1.75 5.07055 5.07055 1.75 9.16667 1.75C13.2628 1.75 16.5833 5.07055 16.5833 9.16667C16.5833 10.9446 15.9578 12.5764 14.9147 13.8539L18.0303 16.9695C18.3232 17.2624 18.3232 17.7373 18.0303 18.0302C17.7374 18.3231 17.2626 18.3231 16.9697 18.0302L13.8541 14.9146ZM3.25 9.16667C3.25 5.89898 5.89898 3.25 9.16667 3.25C12.4344 3.25 15.0833 5.89898 15.0833 9.16667C15.0833 10.7628 14.4513 12.2114 13.4238 13.2757C13.3962 13.2965 13.3698 13.3194 13.3447 13.3445C13.3196 13.3697 13.2966 13.3961 13.2758 13.4237C12.2115 14.4512 10.7629 15.0833 9.16667 15.0833C5.89898 15.0833 3.25 12.4344 3.25 9.16667Z"
      />
    </svg>
  );
}
