import * as ReactDOM from 'react-dom';
import './Modal.scss';

export function Modal(props) {
  const { children, className, onClose } = props;
  const appRootEl = document.getElementsByClassName('app-root')[0];

  return ReactDOM.createPortal(
    <div
      className={`component modal-layout${className ? ` ${className}` : ''}`}
    >
      <div className="btn-wrapper">
        <button className="close-button" type="button" onClick={onClose}>
          ✕
        </button>
      </div>
      {children}
    </div>,
    appRootEl,
  );
}
