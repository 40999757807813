import { useState, useEffect } from 'react';
import { RiskPopup }  from './RiskPopup.jsx';

export function IntermixPopup(props) {
  const { features, center } = props;
  const [selectedFeatureIdx, setSelectedFeatureIdx] = useState(0);
  const selectedFeature = features[selectedFeatureIdx];
  useEffect(() => {
    setSelectedFeatureIdx(0);
  }, [features]);

  if (!features) return null;

  return (
     <RiskPopup 
      title="Risk Description" 
      name="LAYER"
      coordinates={center.coordinates} 
      features={features}  
      selectedFeatureIdx={selectedFeatureIdx} 
      setSelectedFeatureIdx={setSelectedFeatureIdx}
      >
        <p>{selectedFeature.properties.riskdesc}</p>
      </RiskPopup>
  );
}
