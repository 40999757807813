const orientations = {
  up: '180deg',
  right: '90deg',
  down: 0,
  left: '-90deg',
};

export function ChevronIcon(props) {
  const { className, orientation, ...rest } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="none"
      className={`chevron-icon ${className ?? ''}`.trim()}
      style={{
        transform: `rotate(${orientations[orientation]})`,
        transition: 'all ease 0.2s',
      }}
      {...rest}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
