export function ThreeDotsIcon({ orientation = 'vertical' }) {
  const rotation = orientation === 'horizontal' ? '' : 'rotate(90 8 8)';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      transform={rotation}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16634 8.4165C3.29189 8.4165 2.58301 9.12539 2.58301 9.99984C2.58301 10.8743 3.29189 11.5832 4.16634 11.5832C5.04079 11.5832 5.74967 10.8743 5.74967 9.99984C5.74967 9.12539 5.04079 8.4165 4.16634 8.4165ZM4.08301 9.99984C4.08301 9.95381 4.12032 9.9165 4.16634 9.9165C4.21236 9.9165 4.24967 9.95381 4.24967 9.99984C4.24967 10.0459 4.21236 10.0832 4.16634 10.0832C4.12032 10.0832 4.08301 10.0459 4.08301 9.99984Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41699 9.99984C8.41699 9.12539 9.12587 8.4165 10.0003 8.4165C10.8748 8.4165 11.5837 9.12539 11.5837 9.99984C11.5837 10.8743 10.8748 11.5832 10.0003 11.5832C9.12587 11.5832 8.41699 10.8743 8.41699 9.99984ZM10.0003 9.9165C9.9543 9.9165 9.91699 9.95381 9.91699 9.99984C9.91699 10.0459 9.9543 10.0832 10.0003 10.0832C10.0463 10.0832 10.0837 10.0459 10.0837 9.99984C10.0837 9.95381 10.0463 9.9165 10.0003 9.9165Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 9.99984C14.25 9.12539 14.9589 8.4165 15.8333 8.4165C16.7078 8.4165 17.4167 9.12539 17.4167 9.99984C17.4167 10.8743 16.7078 11.5832 15.8333 11.5832C14.9589 11.5832 14.25 10.8743 14.25 9.99984ZM15.8333 9.9165C15.7873 9.9165 15.75 9.95381 15.75 9.99984C15.75 10.0459 15.7873 10.0832 15.8333 10.0832C15.8794 10.0832 15.9167 10.0459 15.9167 9.99984C15.9167 9.95381 15.8794 9.9165 15.8333 9.9165Z"
      />
    </svg>
  );
}
