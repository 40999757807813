import React, { useEffect, useState } from 'react';
import { CardSection, useMapState } from '@redzone/shared';
import './LegendCard.scss';

const legendSection = (key, values) => (
  <CardSection title={key} key={`legend_${key}`}>
    <div className="badge-list">
      {values.map((b) => (
        <div className="badge-item" key={`${b.id}_${b.label}`}>
          <div className="badge" style={{ backgroundColor: b.color }} />
          {b.label}
        </div>
      ))}
    </div>
  </CardSection>
);

const getFullConfig = (list) =>
  list.reduce((acc, val) => {
    if (acc[val.category]) {
      acc[val.category] = [...acc[val.category], val];
      return acc;
    }
    acc[val.category] = [val];
    return acc;
  }, {});

export function LegendCard() {
  const { selectedCustomLayers } = useMapState();
  const [legendsToShow, setLegendsToShow] = useState([]);
  const [fullLegendConfig, setFullLegendConfig] = useState([]);

  useEffect(() => {
    setLegendsToShow([...selectedCustomLayers.map((l) => l.id)]);
    setFullLegendConfig(getFullConfig([...selectedCustomLayers]));
  }, [selectedCustomLayers]);

  return (
    <div className="component legend-card">
      {Object.entries(fullLegendConfig).map(([k, v]) => legendSection(k, v))}

      {selectedCustomLayers.filter((i) => legendsToShow.includes(i.id))
        .length === 0 && (
        <div className="empty-state">
          <h3>No layers selected</h3>
          <p>Once you select a layer, its legend will appear here.</p>
        </div>
      )}
    </div>
  );
}
