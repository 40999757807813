import { useEffect, useRef, useState } from 'react';
import { Portal } from './Portal.jsx';
import './ActionMenu.scss';
import { ClickOutside } from './ClickOutside.jsx';

export function ActionMenu(props) {
  const { actions, position = 'bottom-right', title } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ left: 0, top: 0 });
  const menuBodyRef = useRef();
  const menuPortalRef = useRef();

  const onClickOutside = () => {
    setIsOpen(false);
  };

  ClickOutside([menuBodyRef, menuPortalRef], onClickOutside);

  const toggleMenu = (e) => {
    const button = e.target.closest('.action-menu-button') ?? e.target;
    const rect = button.getBoundingClientRect();

    setMenuPosition({
      left: rect.right,
      top: rect.height + rect.y + window.scrollY + 5,
    });

    setIsOpen((prev) => !prev);
  };

  return (
    <div
      className={`action-menu ${position} ${isOpen ? 'open' : 'closed'}`}
      ref={menuBodyRef}
    >
      <button
        type="button"
        className="action-menu-title action-menu-button"
        onClick={toggleMenu}
      >
        {title}
      </button>
      {isOpen && (
        <Portal className="portal redwall-layout">
          <div
            className="action-menu-body-wrapper"
            ref={menuPortalRef}
            style={{
              left: menuPosition.left,
              top: menuPosition.top,
            }}
          >
            <div className="action-menu-body">
              {actions.map((action) => (
                <button
                  key={action.label}
                  type="button"
                  onClick={(e) => {
                    action.handler(e);
                    setIsOpen(false);
                  }}
                  aria-label={action.label}
                  className="action"
                >
                  {action.icon && (
                    <div className="action-icon">{action.icon}</div>
                  )}
                  <div className="action-label">{action.label}</div>
                </button>
              ))}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}
