export function XIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M14.5303 6.5274C14.8232 6.23451 14.8232 5.75963 14.5303 5.46674C14.2374 5.17385 13.7625 5.17385 13.4696 5.46674L9.99852 8.93786L6.5274 5.46674C6.23451 5.17385 5.75963 5.17385 5.46674 5.46674C5.17385 5.75963 5.17385 6.23451 5.46674 6.5274L8.93786 9.99852L5.46674 13.4696C5.17385 13.7625 5.17385 14.2374 5.46674 14.5303C5.75963 14.8232 6.23451 14.8232 6.5274 14.5303L9.99852 11.0592L13.4696 14.5303C13.7625 14.8232 14.2374 14.8232 14.5303 14.5303C14.8232 14.2374 14.8232 13.7625 14.5303 13.4696L11.0592 9.99852L14.5303 6.5274Z" />
    </svg>
  );
}
