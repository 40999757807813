import axios from 'axios';

let setServicesPromiseReady;
const servicesReady = new Promise((resolve) => {
  setServicesPromiseReady = resolve;
});

function createTransport(baseURL) {
  const transport = axios.create({
    baseURL,
    withCredentials: true,
  });

  const getHeaders = (headers = {}) => {
    const accessToken = localStorage.getItem('access-token');

    return {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Credentials': true,
      ...headers,
    };
  };

  return {
    async get(path, options = {}) {
      await servicesReady;
      return transport.get(path, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    async getData(path, options = {}) {
      await servicesReady;
      const result = await this.get(path, options);
      return result.data;
    },

    async post(path, body = {}, options = {}) {
      await servicesReady;
      return transport.post(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    async put(path, body = {}, options = {}) {
      await servicesReady;
      return transport.put(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    async patch(path, body = {}, options = {}) {
      await servicesReady;
      return transport.patch(path, body, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },

    async delete(path, options = {}) {
      await servicesReady;
      return transport.delete(path, {
        ...options,
        headers: { ...getHeaders(options.headers) },
      });
    },
  };
}

export const setServicesReady = (accessToken) => {
  localStorage.setItem('access-token', accessToken);
  setServicesPromiseReady();
};

export const adminCPTransport = createTransport(
  `${process.env.REACT_APP_ADMIN_CP_SERVICE_DOMAIN}/cp/v1`,
);

export const adminServiceTransport = createTransport(
  `${process.env.REACT_APP_ADMIN_SERVICE_DOMAIN}/api/v1`,
);

export const authCPTransport = createTransport(
  `${process.env.REACT_APP_AUTH_CP_SERVICE_DOMAIN}/cp/v1`,
);

export const authServiceTransport = createTransport(
  `${process.env.REACT_APP_AUTH_SERVICE_DOMAIN}/api/v1`,
);

export const dispatchServiceTransport = createTransport(
  `${process.env.REACT_APP_DISPATCH_SERVICE_DOMAIN}/api/v1`,
);

export const facadeTransport = createTransport(
  process.env.REACT_APP_FACADE_DOMAIN,
);

export const mailerTransport = createTransport(
  `${process.env.REACT_APP_MAILER_SERVICE_DOMAIN}/api/v1`,
);

export const organizationServiceTransport = createTransport(
  `${process.env.REACT_APP_ORGANIZATION_SERVICE_DOMAIN}/api/v1`,
);

export const perilExposureServiceTransport = createTransport(
  `${process.env.REACT_APP_PERIL_EXPOSURE_SERVICE_DOMAIN}/api/v1`,
);

export const userMetadataCPTransport = createTransport(
  `${process.env.REACT_APP_USER_METADATA_CP_DOMAIN}/cp/v1`,
);
