import { useState } from 'react';
import { PointCollector, PinIcon } from '@redzone/shared';
import './PinSearch.scss';

export function PinSearch(props) {
  const { location, setLocation, pointLocation } = props;
  const [trackCollecting, setTrackCollecting] = useState(false);
  const [pin] = useState(null);

  return (
    <main className="pin-search">
      {trackCollecting && !pointLocation && (
        <div className="help-text">Place your pin on the map</div>
      )}
      {location ? (
        <div className="result-container">
          <dl>
            <dt>Latitude</dt>
            <dd>{pointLocation[1]}</dd>
          </dl>
          <dl>
            <dt>Longitude</dt>
            <dd>{pointLocation[0]}</dd>
          </dl>
        </div>
      ) : (
        <PointCollector
          value={pin}
          onChange={(e) => {
            if (e.features[0].geometry.coordinates.length === 0) return;
            setLocation(e);
          }}
          className="redwall primary md semibold"
          showValue
          data-cy="pin-collector"
          trackFn={setTrackCollecting}
        >
          <PinIcon /> Drop Map Pin
        </PointCollector>
      )}
    </main>
  );
}
