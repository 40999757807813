import { Source, Layer, useMapEffect } from '@redzone/map';

const sourceId = 'map-collector-value';

export function MapCollectorValue(props) {
  const { value } = props;
  if (!value) return null;

  return (
    <Source id={sourceId} type="geojson" data={value}>
      <Layer
        id="collector-layer"
        mapboxLayer={{
          id: 'layer',
          type: 'fill',
          paint: {
            'fill-color': '#088',
            'fill-opacity': 0.4,
            'fill-outline-color': 'blue',
          },
        }}
      />
    </Source>
  );
}
