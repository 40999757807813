import { RedwallInput } from './RedwallForm.jsx';
import {
  SFInput,
  attributeTypeToInputType,
  useSchemaForm,
} from './SchemaForm.jsx';

export function RedwallSFInput(props) {
  const { attribute, className, type, ...rest } = props;
  const { resource, setAttribute } = useSchemaForm();

  const value = resource[attribute] ?? '';

  const classNameWithEmpty =
    value === '' || !value ? `${className ?? ''} empty`.trim() : className;

  return type === 'number' ? (
    <RedwallInput
      {...rest}
      id={attribute}
      type={type}
      className={className}
      value={value}
      onChange={(e) => setAttribute(attribute, e.target.value)}
    />
  ) : (
    <SFInput {...props} className={classNameWithEmpty} />
  );
}

export function RedwallSFInputRow(props) {
  const { label, attribute, ...rest } = props;
  const { resourceSchema } = useSchemaForm();

  const attributeSchema = resourceSchema?.attributes?.[attribute];
  const shownLabel = label ?? attributeSchema?.title ?? attribute;

  const schemaProps = {};
  if (attributeSchema) {
    if (attributeSchema.maximum !== undefined)
      schemaProps.max = attributeSchema.maximum;
    if (attributeSchema.minimum !== undefined)
      schemaProps.min = attributeSchema.minimum;
    if (attributeSchema.type)
      schemaProps.type =
        attributeTypeToInputType[attributeSchema.type] ?? attributeSchema.type;
  }

  const inputProps = { ...schemaProps, ...rest };

  return (
    <div className="input-row">
      <label htmlFor={attribute}>{shownLabel}</label>
      <RedwallSFInput {...inputProps} attribute={attribute} />
    </div>
  );
}
