import { historicalFiresPerimeterLayer } from '@redzone/shared';
import { InterfacePopup } from '../components/popups/InterfacePopup.jsx';
import { IntermixPopup } from '../components/popups/IntermixPopup.jsx';
import { WildlandPopup } from '../components/popups/WildlandPopup.jsx';

const LAYERS = {
  historical_fires_perimeter: historicalFiresPerimeterLayer,
  interface: {
    id: 'interface',
    category: 'Risk Layers',
    label: 'Interface',
    source: {
      id: 'redzonesoftware.interface-us-09-2023',
      type: 'vector',
      url: 'mapbox://redzonesoftware.interface-us-09-2023',
    },
    mapboxLayer: {
      id: 'redzonesoftware.interface-us-09-2023',
      type: 'fill',
      source: 'redzonesoftware.interface-us-09-2023',
      'source-layer': 'interface',
      paint: {
        'fill-color': [
          'match',
          ['get', 'RISKDESC'],
          'Interface Smoke Risk',
          'rgba(255, 189, 232, 0.5)',
          'Interface Low',
          'rgba(255, 170, 0, 0.5)',
          'Interface Moderate',
          'rgba(255, 170, 0, 0.5)',
          'Interface High',
          'rgba(255, 170, 0, 0.5)',
          '#808080',
        ],
      },
    },
    popup: InterfacePopup,
    onClick: (e, fn) =>
      fn('interface', {
        center: {
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        features: e.features,
      }),
  },
  intermix: {
    id: 'intermix',
    category: 'Risk Layers',
    label: 'Intermix',
    source: {
      id: 'redzonesoftware.intermix-us-09-2023',
      type: 'vector',
      url: 'mapbox://redzonesoftware.intermix-us-09-2023',
    },
    mapboxLayer: {
      id: 'redzonesoftware.intermix-us-09-2023',
      type: 'fill',
      source: 'redzonesoftware.intermix-us-09-2023',
      'source-layer': 'intermix',
      paint: {
        'fill-color': [
          'match',
          ['get', 'riskdesc'],
          'Intermix Low',
          'rgba(165, 255, 117, 0.5)',
          'Intermix Moderate',
          'rgba(255, 255, 0, 0.5)',
          'Intermix High',
          'rgba(255, 170, 0, 0.5)',
          'Intermix Very High',
          'rgba(255, 0, 0, 0.5)',
          '#808080',
        ],
      },
    },
    popup: IntermixPopup,
    onClick: (e, fn) =>
      fn('intermix', {
        center: {
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        features: e.features,
      }),
  },
  wildland: {
    id: 'wildland',
    category: 'Risk Layers',
    label: 'Wildland west',
    source: {
      id: 'redzonesoftware.88h85ayp',
      type: 'vector',
      url: 'mapbox://redzonesoftware.88h85ayp',
    },
    mapboxLayer: {
      id: 'redzonesoftware.88h85ayp',
      type: 'fill',
      source: 'redzonesoftware.88h85ayp',
      'source-layer': 'west',
      paint: {
        'fill-color': [
          'match',
          ['get', 'riskdesc'],

          'Wildland East Very High',
          'rgba(255, 0, 0, 0.5)',
          'Wildland West Very High',
          'rgba(255, 0, 0, 0.5)',
          'Wildland Very High',
          'rgba(255, 0, 0, 0.5)',

          'Wildland East High',
          'rgba(255, 170, 0, 0.5)',
          'Wildland West High',
          'rgba(255, 170, 0, 0.5)',
          'Wildland High',
          'rgba(255, 170, 0, 0.5)',

          'Wildland East Moderate',
          'rgba(255, 255, 0, 0.5)',
          'Wildland West Moderate',
          'rgba(255, 255, 0, 0.5)',
          'Wildland Moderate',
          'rgba(255, 255, 0, 0.5)',

          'Wildland East Low',
          'rgba(165, 255, 117, 0.5)',
          'Wildland West Low',
          'rgba(165, 255, 117, 0.5)',
          'Wildland Low',
          'rgba(165, 255, 117, 0.5)',

          '#808080',
        ],
      },
    },
    popup: WildlandPopup,
    onClick: (e, fn) =>
      fn('wildland', {
        center: {
          coordinates: [e.lngLat.lng, e.lngLat.lat],
        },
        features: e.features,
      }),
  },
};

/* this is a placeholder until we get the risk layers working with tegola. */
export const MAP_LAYERS = [LAYERS.historical_fires_perimeter];
