import React from 'react';
import { IconInput } from '@redzone/shared';
import './CoordinatesSearch.scss';

const isValidCoordinates = (lngV, latV) => {
  const isNumber = (v) => !Number.isNaN(Number(v));
  const isLng = (v) => isNumber(v) && v <= 180 && v >= -180;
  const isLat = (v) => isNumber(v) && v <= 90 && v >= -90;

  return isLng(lngV) && isLat(latV);
};

export function CoordinatesSearch(props) {
  const { setLocation, lng, setLng, lat, setLat } = props;
  const isValid = isValidCoordinates(lng, lat);

  return (
    <div className="lnglat-search">
      <div className="input-wrapper">
        <div className="input-label">Latitude</div>
        <IconInput
          name="lat"
          placeholder="ex: 37.7577"
          type="text"
          value={lat}
          onChange={(e) => {
            setLat(e.target.value);
          }}
          data-cy="lat-input"
        />
        <div className="input-label">Longitude</div>
        <IconInput
          name="lng"
          placeholder="ex: -122.4376"
          type="text"
          value={lng}
          onChange={(e) => {
            setLng(e.target.value);
          }}
          data-cy="lng-input"
        />
      </div>
      <button
        className="redwall secondary md semibold"
        type="button"
        disabled={!isValid || lat === '' || lng === ''}
        onClick={() =>
          setLocation({
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: { type: 'Point', coordinates: [lng, lat] },
              },
            ],
          })
        }
        data-cy="go-to-location"
      >
        Go To Location
      </button>
      {/* TODO need error styles */}
      {!isValid && <div className="error">Invalid Latitude and Longitude</div>}
    </div>
  );
}
