import React, { useState } from 'react';
import { AcreageIcon } from './AcreageIcon.jsx';
import { AddIcon } from './AddIcon.jsx';
import { ArrowIcon } from './ArrowIcon.jsx';
import { BuildingIcon } from './BuildingIcon.jsx';
import { CalendarIcon } from './CalendarIcon.jsx';
import { CheckIcon } from './CheckIcon.jsx';
import { ChevronIcon } from './ChevronIcon.jsx';
import { ClockIcon } from './ClockIcon.jsx';
import { CloseButtonIcon } from './CloseButtonIcon.jsx';
import { ControlsIcon } from './ControlsIcon.jsx';
import { DollarIcon } from './DollarIcon.jsx';
import { DoneIcon } from './DoneIcon.jsx';
import { DownloadIcon } from './DownloadIcon.jsx';
import { EarthquakeIcon } from './EarthquakeIcon.jsx';
import { EnvelopeIcon } from './EnvelopeIcon.jsx';
import { ExpandIcon } from './ExpandIcon.jsx';
import { FilterIcon } from './FilterIcon.jsx';
import { FireCircleIcon } from './FireCircleIcon.jsx';
import { FireGroupIcon } from './FireGroupIcon.jsx';
import { FireIcon } from './FireIcon.jsx';
import { HelpIcon } from './HelpIcon.jsx';
import { HurricaneIcon } from './HurricaneIcon.jsx';
import { InfoIcon } from './InfoIcon.jsx';
import { LayersIcon } from './LayersIcon.jsx';
import { LegendIcon } from './LegendIcon.jsx';
import { LinkIcon } from './LinkIcon.jsx';
import { LogoutIcon } from './LogoutIcon.jsx';
import { MapPinIcon } from './MapPinIcon.jsx';
import { NewsIcon } from './NewsIcon.jsx';
import { PercentIcon } from './PercentIcon.jsx';
import { PinIcon } from './PinIcon.jsx';
import { PlusIcon } from './PlusIcon.jsx';
import { PolygonIcon } from './PolygonIcon.jsx';
import { RadioIcon } from './RadioIcon.jsx';
import { RiskSearchIcon } from './RiskSearchIcon.jsx';
import { RulerIcon } from './RulerIcon.jsx';
import { SearchIcon } from './SearchIcon.jsx';
import { SelectPoliciesIcon } from './SelectPoliciesIcon.jsx';
import { SlidersIcon } from './SlidersIcon.jsx';
import { SocialIcon } from './SocialIcon.jsx';
import { SortArrowsIcon } from './SortArrowsIcon.jsx';
import { SortUpIcon } from './SortUpIcon.jsx';
import { SpinnerIcon } from './SpinnerIcon.jsx';
import { ThreeDotsIcon } from './ThreeDotsIcon.jsx';
import { VideoIcon } from './VideoIcon.jsx';
import { XFillIcon } from './XFillIcon.jsx';
import { XIcon } from './XIcon.jsx';
import { XSocialIcon } from './XSocialIcon.jsx';

import vectorPolygon from './vector_polygon_icon_137060.svg';
import './IconsPreview.scss';

export function IconsPreview() {
  const [fillColor, setFillColor] = useState('none');
  const [strokeColor, setStrokeColor] = useState('black');
  const [height, setHeight] = useState(50);
  const [width, setWidth] = useState(50);
  const icons = [
    {
      name: 'AcreageIcon',
      component: <AcreageIcon />,
      props: {},
    },
    {
      name: 'AddIcon',
      component: <AddIcon />,
      props: { color: '#000000' },
    },
    {
      name: 'ArrowIcon',
      component: <ArrowIcon />,
      props: { orientation: 'up || right || down || default (left)' },
    },
    {
      name: 'BuildingIcon',
      component: <BuildingIcon />,
      props: {},
    },
    {
      name: 'CalendarIcon',
      component: <CalendarIcon />,
      props: {},
    },
    {
      name: 'CheckIcon',
      component: <CheckIcon />,
      props: {},
    },
    {
      name: 'ChevronIcon',
      component: <ChevronIcon />,
      props: { orientation: 'up || right || down (default) || left' },
    },
    {
      name: 'ClockIcon',
      component: <ClockIcon />,
      props: {},
    },
    {
      name: 'CloseButtonIcon',
      component: <CloseButtonIcon />,
      props: {},
    },
    {
      name: 'ControlsIcon',
      component: <ControlsIcon />,
      props: {},
    },
    {
      name: 'DollarIcon',
      component: <DollarIcon />,
      props: { className: 'className' },
    },
    {
      name: 'DoneIcon',
      component: <DoneIcon />,
      props: {},
    },
    {
      name: 'DownloadIcon',
      component: <DownloadIcon />,
      props: {},
    },
    {
      name: 'EarthquakeIcon',
      component: <EarthquakeIcon />,
      props: {},
    },
    {
      name: 'EnvelopeIcon',
      component: <EnvelopeIcon />,
      props: {},
    },
    {
      name: 'ExpandIcon',
      component: <ExpandIcon />,
      props: {},
    },
    {
      name: 'FilterIcon',
      component: <FilterIcon />,
      props: {},
    },
    {
      name: 'FireCircleIcon',
      component: <FireCircleIcon />,
      props: {},
    },
    {
      name: 'FireGroupIcon',
      component: <FireGroupIcon />,
      props: {},
    },
    {
      name: 'FireIcon',
      component: <FireIcon />,
      props: {},
    },
    {
      name: 'HelpIcon',
      component: <HelpIcon />,
      props: {},
    },
    {
      name: 'HurricaneIcon',
      component: <HurricaneIcon />,
      props: {},
    },
    {
      name: 'InfoIcon',
      component: <InfoIcon />,
      props: {},
    },
    {
      name: 'LayersIcon',
      component: <LayersIcon />,
      props: {},
    },
    {
      name: 'LegendIcon',
      component: <LegendIcon />,
      props: {},
    },
    {
      name: 'LinkIcon',
      component: <LinkIcon />,
      props: {},
    },
    {
      name: 'LogoutIcon',
      component: <LogoutIcon />,
      props: {},
    },
    {
      name: 'MapPinIcon',
      component: <MapPinIcon />,
      props: {},
    },
    {
      name: 'NewsIcon',
      component: <NewsIcon />,
      props: {},
    },
    {
      name: 'PercentIcon',
      component: <PercentIcon />,
      props: { className: 'className' },
    },
    {
      name: 'PinIcon',
      component: <PinIcon />,
      props: {},
    },
    {
      name: 'PlusIcon',
      component: <PlusIcon />,
      props: {},
    },
    {
      name: 'PolygonIcon',
      component: <PolygonIcon />,
      props: {},
    },
    {
      name: 'RadioIcon',
      component: <RadioIcon />,
      props: {},
    },
    {
      name: 'RiskSearchIcon',
      component: <RiskSearchIcon />,
      props: {},
    },
    {
      name: 'RulerIcon',
      component: <RulerIcon />,
      props: {},
    },
    {
      name: 'SearchIcon',
      component: <SearchIcon />,
      props: {},
    },
    {
      name: 'SelectPoliciesIcon',
      component: <SelectPoliciesIcon />,
      props: {},
    },
    {
      name: 'SlidersIcon',
      component: <SlidersIcon />,
      props: {},
    },
    {
      name: 'SocialIcon',
      component: <SocialIcon />,
      props: {},
    },
    {
      name: 'SortArrowsIcon',
      component: <SortArrowsIcon />,
      props: 'see SortArrowsIcon.jsx',
    },
    {
      name: 'SortUpIcon',
      component: <SortUpIcon />,
      props: {},
    },
    {
      name: 'SpinnerIcon',
      component: <SpinnerIcon />,
      props: {},
    },
    {
      name: 'ThreeDotsIcon',
      component: <ThreeDotsIcon />,
      props: { orientation: 'default (vertical) || horizontal' },
    },
    {
      name: 'VideoIcon',
      component: <VideoIcon />,
      props: {},
    },
    {
      name: 'XFillIcon',
      component: <XFillIcon />,
      props: {},
    },
    {
      name: 'XIcon',
      component: <XIcon />,
      props: {},
    },
    {
      name: 'XSocialIcon',
      component: <XSocialIcon />,
      props: {},
    },
  ];

  const handleFillChange = (e) => {
    console.log(e.target.value);
    setFillColor(e.target.value);
  };
  const handleStrokeChange = (e) => {
    setStrokeColor(e.target.value);
  };

  return (
    <div
      style={{ height: `${window.innerHeight}px`, overflow: 'auto' }}
      className="icons-preview"
    >
      <div className="style-selector">
        Note: not every icon has fill or stroke properties. Some have bounding
        rect properties.
        <form>
          <label>
            Fill Color:
            <input
              type="color"
              name="fillColor"
              value={fillColor}
              onChange={handleFillChange}
            />
            <input
              type="text"
              name="fillColor"
              value={fillColor}
              onChange={handleFillChange}
            />
          </label>
          <label>
            Stroke Color:
            <input
              type="color"
              name="strokeColor"
              value={strokeColor}
              onChange={handleStrokeChange}
            />
            <input
              type="text"
              name="strokeColor"
              value={strokeColor}
              onChange={handleStrokeChange}
            />
          </label>
        </form>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Icon</th>
            <th>Props</th>
          </tr>
        </thead>
        <tbody>
          {icons.map((icon) => (
            <tr key={icon.name}>
              <td>{icon.name}</td>
              <td
                style={{
                  fill: fillColor,
                  stroke: strokeColor,
                }}
              >
                {icon.component}
              </td>
              <td>{JSON.stringify(icon.props)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
