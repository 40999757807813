import { ChevronIcon } from '../icons/ChevronIcon.jsx';
import './Drawer.scss';

export function Drawer({
  className,
  hasToggleButton,
  open,
  position,
  setOpen,
  loading = false,
  error = false,
  children = [],
}) {
  const toggleDirections = {
    closed: position,
    open: position === 'left' ? 'right' : 'left',
  };

  return (
    <div
      className={`drawer-container light-theme ${position} ${
        open ? 'open' : 'closed'
      } ${className ?? ''}`.trim()}
    >
      {hasToggleButton && (
        <button
          className="drawer-toggle-button"
          onClick={() => setOpen(!open)}
          type="button"
          aria-label={open ? 'close left drawer' : 'open left drawer'}
        >
          <ChevronIcon
            orientation={open ? toggleDirections.open : toggleDirections.closed}
          />
        </button>
      )}
      <div className="drawer-wrapper">
        <section className="drawer">{children}</section>
      </div>
    </div>
  );
}
