import './Card.scss';
import { XIcon } from '../icons/XIcon.jsx';

export function Card(props) {
  const { title, children, icon, open, onClose, className } = props;

  return open ? (
    <div className={`component card ${className ?? ''}`.trim()}>
      <div className="card-header">
        {icon}
        <h1 className="title">{title}</h1>
        <button
          aria-label={`close ${title} card`}
          className="close-button"
          type="button"
          onClick={onClose}
        >
          <XIcon />
        </button>
      </div>

      <div className="card-body">{children}</div>
    </div>
  ) : null;
}
