import { useState } from 'react';
import { RedwallInput } from '../../../RedwallForm.jsx';

export function NumberRangeFilterCollector(props) {
  const {
    setValue,
    value = {},
    presetFilterValues = [],
    customPreset = {},
  } = props;

  const isAny = !value.min && value.min !== 0 && !value.max && !value.max !== 0;
  const foundPreset = presetFilterValues.findIndex(
    (pv) => pv.value.min === value.min && pv.value.max === value.max,
  );

  const [preset, setPreset] = useState(
    isAny ? 'any' : foundPreset > -1 ? foundPreset : 'custom',
  );

  const keyDownHandler = (ev) => {
    // numbers and decimal point only
    const numberRegex = /^([\d.])+$/;

    switch (ev.key) {
      // allow these keys
      case 'Backspace':
      case 'Delete':
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'ArrowUp':
      case 'ArrowDown':
      case 'Tab':
      case 'Shift':
      case 'Control':
      case 'Alt':
      case 'Meta':
        break;
      // numbers and decimal point only
      default:
        if (!numberRegex.test(ev.key)) {
          ev.preventDefault();
          ev.stopPropagation();
        }
    }
  };

  return (
    <div className="number-collector">
      <div className="preset-row">
        <input
          id="number-range-any"
          className="preset"
          type="radio"
          onChange={() => {
            setValue({});
            setPreset('any');
          }}
          checked={preset === 'any'}
        />
        <label htmlFor="number-range-any">Any</label>
      </div>

      {presetFilterValues.map((pv, idx) => (
        <div className="preset-row" key={pv.label}>
          <input
            id={`number-range-${idx}`}
            className="preset"
            type="radio"
            onChange={() => {
              setPreset(idx);
              setValue(pv.value);
            }}
            checked={preset === idx}
          />
          <label htmlFor={`number-range-${idx}`}>{pv.label}</label>
        </div>
      ))}

      <div className="preset-row">
        <input
          id="number-range-custom"
          className="preset"
          type="radio"
          onChange={() => {
            setPreset('custom');
          }}
          checked={preset === 'custom'}
        />
        <label htmlFor="number-range-custom">Custom</label>
      </div>
      <div className="custom-row input-row multi-input-row">
        <RedwallInput
          className="redwall"
          type="number"
          min={customPreset?.start?.min}
          max={customPreset?.start?.max}
          placeholder="Min"
          value={value.min == null ? '' : String(value.min)}
          onChange={(e) => {
            if (setPreset !== 'custom') setPreset('custom');
            if (e.target.value !== '' && Number(e.target.value) != null) {
              setValue({ ...value, min: Number(e.target.value) });
            } else {
              const { min, ...nextValue } = value;
              setValue(nextValue);
            }
          }}
        />
        <span> to </span>
        <RedwallInput
          className="redwall"
          type="number"
          placeholder="Max"
          min={customPreset?.end?.min}
          max={customPreset?.end?.max}
          value={value.max == null ? '' : String(value.max)}
          onChange={(e) => {
            if (setPreset !== 'custom') setPreset('custom');
            if (e.target.value !== '' && Number(e.target.value) != null) {
              setValue({ ...value, max: Number(e.target.value) });
            } else {
              const { max, ...nextValue } = value;
              setValue(nextValue);
            }
          }}
          onKeyDown={keyDownHandler}
        />
      </div>
    </div>
  );
}
