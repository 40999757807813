import { useEffect } from 'react';

export function ClickOutside(refOrRefs, onClickOutside) {
  const refs = Array.isArray(refOrRefs) ? refOrRefs : [refOrRefs];

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        refs.every((ref) => ref.current && !ref.current.contains(event.target))
      ) {
        onClickOutside(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refOrRefs, onClickOutside]);
}
