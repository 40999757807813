import { Header } from '../components/Header.jsx';
import { MemoryTabs, Tab } from '../components/Tabs.jsx';
import { RedwallLayout } from '../components/RedwallLayout.jsx';

import './About.scss';

const AboutUs = () => {
  return (
    <>
      <p>
        RedZone provides solutions for real-time disaster monitoring,
        collaborative mapping, and smart hazard assessments. Our mission is to
        help you sort through the chaos of natural disaster response.
      </p>

      <h3>History</h3>
      <p>
        RedZone Software was formed in 2002 by Clark Woodward, a geographer, and
        volunteer firefighter. Clark’s first opportunity in the geospatial
        industry was at the National Earthquake Information Center in Golden,
        Colorado, and his experience in crisis mapping led him to found RedZone,
        with the mission of providing intelligence analysis about disaster
        events throughout the U.S. and abroad.
      </p>
      <p>
        RedZone’s first software was a handheld data collection application
        (RZSurvey) specifically for fire departments to assess home
        defensibility in the Wildland Urban Interface (WUI). Clark pioneered
        this project as a WUI resident outside of Boulder, Colorado, and a
        member of the Four Mile Fire Department. The RedZone home assessment
        initative formed the foundation for a series of preplanning maps cached
        in each department station.
      </p>

      <h3>Four Mile Fire</h3>
      <p>
        In 2010, a destructive wildfire swept through Four Mile Canyon. Within
        an hour of the first alert, RedZone staff were in the incident command
        post creating real-time fire maps as part of the Incident Command Team.
        Our proudest moment as a company was knowing that the RedZone preplan
        maps were being distributed to first responders as soon as they arrived
        on scene. The work started in 2002 helped to increase firefighter safety
        and to provide clarity in a chaotic fire scene that destroyed more than
        170 homes in two destructive days.
      </p>

      <h3>RZIntel</h3>
      <p>
        In 2011, RedZone Software opened an emergency operations center
        (RZIntel). RZIntel is headquartered in San Diego, California, and the
        team provides round-the-clock, real-time intelligence about disasters
        throughout the U.S. and abroad. RZIntel is staffed by experienced
        incident management specialists who analyze incident activity using
        remote monitoring techniques and deploy on-scene personnel as needed.
        This interactive decision-support group provides a unique approach to
        protecting private assets threatened by impending natural disasters.
      </p>

      <h3>RedZone Today</h3>
      <p>
        The team at RedZone is staffed with qualified incident commanders,
        software designers, emergency responders, cartographers, and analysts.
        Our core service is that we enable our customers to understand the
        potential risk to their assets, to manage the risk, to respond rapidly
        to incidents, and to protect their assets and mitigate damage/claims.
        RedZone has developed a suite of GIS products for both mobile and online
        use. While RedZone historically has focused on the wildland fire and
        public safety markets, the company has expanded to support all natural
        hazards. Our products provide integrated risk-analysis, real-time alerts
        and incident management tools that facilitate response planning, improve
        public relations, mitigate risk and reduce loss. RedZone saves lives and
        protects property by enabling our customers to act quickly and
        effectively.
      </p>

      <h3>Corporate Values</h3>
      <p>
        At RedZone, we like to say that we keep one foot in the black and one
        foot in the boardroom - and it is not a cliche: We make complex natural
        disasters simple to understand. We work closely with our customers to
        pass on our valuable fire and emergency response experience to
        executives in private enterprise.
      </p>
      <p>
        RedZone cares deeply about the Boulder community, and you’ll find our
        staff volunteering as emergency responders for local Incident Management
        Teams responding to major disasters in our community, including the 2013
        Boulder Flood, 2012 Flagstaff Fire and other regional disaster events.
        During the 2013 Boulder Flood, RedZone created a free iOS app that
        allowed local first responders to navigate through the devastating
        floods that spilled over Boulder Creek and the surrounding watersheds
        and communities of Lyons and Longmont.
      </p>

      <h3>The RedZone Product Suite</h3>
      <p>
        Our complex network of products includes:
        <br />
        RZRisk – comprehensive wildfire hazard assessment in CA.
        <br />
        RZAlert – nationwide, real-time disaster tracking, notification and
        threat analysis. The web’s top platform for real-time exposure analysis
        of assets impacted by wildfires, earthquakes, hurricanes, and tornadoes.
        <br />
        RZMobile - tactical mobile mapping software for first responders, risk
        managers and claims management teams to conduct assessments in the field
        before or after disasters.
      </p>
    </>
  );
};

const Terms = () => {
  return (
    <>
      <p>
        This web site is owned and operated by RedZone Software LLC, (RedZone or
        we). We have created this page to share with you our privacy and legal
        policies regarding your personal information and the contents on this
        site.
      </p>

      <h3>Privacy Policy</h3>
      <p>
        We do not collect personal information about you unless you voluntarily
        provide it to us or authorize us to collect it. We consider the
        following to be personal information: your name, email address, phone
        number, date of birth, job, personal interests, e-mail address. We treat
        personal information confidentially.
      </p>
      <p>
        We do collect and track certain non-personally identifiable information
        including, but not limited to, user IP numbers and browser type, access
        times, domain names, usage habits, and other information which does not
        specifically identify any individual.
      </p>
      <p>
        We may use cookies to customize your use of the web site and for other
        purposes to improve your use of the service, and may also use web
        beacons, web bugs, action tags and third-party cookies. You may stop or
        restrict the placement of cookies on your computer or flush them from
        your browser by adjusting your web browser preferences, in which case
        you may still use our Service, but it may interfere with some of its
        functionality. We do not use cookies to automatically retrieve personal
        information from your computer without your knowledge.
      </p>

      <h3>Legal Disclaimer</h3>
      <p>
        All the information and contents posted on this web site are the
        property of RedZone Software and are protected by U.S. and foreign
        copyright laws. By accessing this web site, you agree to the following
        terms and conditions. You shall not use this web site if you do not
        agree.
      </p>
      <ol>
        <li>
          This web site and its contents may NOT be copied or distributed in any
          manner without the prior written consent of RedZone Software.
        </li>
        <li>
          RedZone Software MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT
          TO THIS WEB SITE, ITS CONTENTS OR THE MANUSCRIPT, WHICH ARE PROVIDED
          FOR USE "AS IS" AND IS WITHOUT WARRANTY OF ANY KIND.
        </li>
        <li>
          Portions of this web site may publish readers' comments and opinion.
          RedZone Software does not assume or have any responsibility or any
          liability for the readers' comments or opinions.
        </li>
      </ol>

      <h3>Change of Policies</h3>
      <p>
        The above policies may change from time to time. RedZone Software will
        post those changes on this page.
      </p>
      <p>This page was last updated on February 27, 2014.</p>
    </>
  );
};

const Privacy = () => {
  return (
    <>
      <p>
        RedZone Software maintains high standards for the protection of privacy
        over the Internet. The purpose of this statement is to explain the types
        of information RedZone Software obtains about visitors to our websites,
        how the information is obtained, how it is used, and how visitors can
        restrict its use or disclosure.
      </p>
      <p>
        The only information RedZone Software obtains about individual visitors
        to our websites is information supplied voluntarily by the visitor. This
        means that you can visit our websites without telling us who you are or
        revealing any information about yourself -- we DO NOT track where you go
        on our sites, so you never receive unsolicited emails from us. To gauge
        the effectiveness of our websites, we do collect some
        non-individually-identifiable generic information about our visitors.
        Our web servers automatically recognize a visitor's domain name (such as
        .com, .edu, etc.), the web page from which a visitor enters our site,
        which pages a visitor visits on our site, and, on some sites, how much
        time a visitor spends on each page. This information does not reveal a
        visitor's identity. We aggregate this information and use it to evaluate
        and improve our websites.
      </p>
      <p>
        You can choose to provide individually-identifiable information to
        RedZone Software in a number of ways through our websites - by ordering
        a service from us, sending us email, or registering to receive news.
        When visitors supply information about themselves for a specific
        purpose, RedZone Software uses the information for that purpose (such as
        to provide the information the visitor has requested). In addition, when
        visitors use our websites to order services, or to request information
        about our services, we may use the individually-identifiable information
        as we would use the same information obtained off-line--for example, to
        evaluate your service needs and contact you regarding additional
        services you may find useful. You have the right to cancel any service
        you sign up for (details for canceling are included with the sign-up
        confirmation email).
      </p>
      <p>
        We do not sell or disclose individually-identifiable information
        obtained on-line about our visitors to anyone outside of RedZone
        Software unless it is required by law or disclosure is necessary to
        protect the safety of customers, employees, or property. If you provide
        individually-identifiable information to us in the context of an event
        RedZone Software sponsors with another company, such as a contest, or if
        you register on a co-sponsored site or feature, you may also be
        providing the individually-identifiable information to the co-sponsor.
        RedZone Software is committed to safeguarding customer privacy on all
        RedZone Software websites. We require our employees to protect the
        privacy of information about our customers and expect our partners and
        suppliers to do so as well. You can feel confident that your
        individually-identifiable information will be protected when you access
        our webpages or order services from us over the Internet. We employ
        security measures to safeguard your transactions with us.
      </p>
      <p>
        The RedZone Software websites may contain links to other sites. We are
        not responsible for the content or the privacy practices employed by
        other sites.
      </p>
      <p>
        To improve the services it can offer you, RedZone Software may opt to
        expand its capabilities for obtaining information about users in the
        future. RedZone Software will update this privacy statement continually
        to ensure that you are aware of developments in this area. RedZone
        Software has adopted Privacy Principles that state our commitment and
        define our policy on safeguarding customer privacy in all of our
        businesses.
      </p>
    </>
  );
};

export function About(props) {
  const { appName } = props;
  return (
    <RedwallLayout className="no-map">
      <div className="about page">
        <Header
          className={appName}
          helpLink="https://docs.redzone.co/rzrisk3-api/index.html"
          title="Risk3+"
        />

        <main className="about-tabs">
          <MemoryTabs>
            <Tab id="about" label="About Us" default>
              <div className="content">
                <AboutUs />
              </div>
            </Tab>
            <Tab id="terms" label="Terms Of Service" path="terms-of-service">
              <div className="content">
                <Terms />
              </div>
            </Tab>
            <Tab id="privacy" label="Privacy Policy" path="privacy-policy">
              <div className="content">
                <Privacy />
              </div>
            </Tab>
          </MemoryTabs>
        </main>
      </div>
    </RedwallLayout>
  );
}
