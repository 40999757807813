import { useState } from 'react';
import { pickBy } from 'lodash';
import {
  ControlsIcon,
  DownloadIcon,
  SearchIcon,
  SortUpIcon,
  ThreeDotsIcon,
} from '../../icons.js';
import { ActionMenu } from '../ActionMenu.jsx';
import { SortCollector } from './controls/SortCollector.jsx';
import { FilterCollector } from './controls/FilterCollector.jsx';
import { classStr } from '../../helpers.js';
import { DataTableControlBody } from './controls/DataTableControlBody.jsx';
import { SearchCollector } from './controls/SearchCollector.jsx';
import { useDataCollection } from './DataCollection.jsx'; // eslint-disable-line import/no-cycle
import { exportTableCsv } from './table-helpers.js';
import './DataCollectionControls.scss';

export function DataCollectionControl(props) {
  return props.children;
}

export function DataCollectionControls(props) {
  const { actions, extraControls = [], header } = props;
  const {
    columns,
    data,
    filters,
    setFilters,
    search,
    setSearch,
    sort,
    setSort,
  } = useDataCollection();

  const allActions = [
    ...(actions ?? []),
    {
      icon: <DownloadIcon />,
      label: 'Download CSV',
      handler: () => exportTableCsv(data, Object.values(columns)),
    },
  ];

  const [selectedControl, setSelectedControl] = useState(null);

  const selectedExtra = extraControls.find(
    (control) => selectedControl === control.props.id,
  );

  const filterableColumns = pickBy(columns, (c) => c.filterable);
  const sortableColumns = pickBy(columns, (c) => c.sortable);
  const searchableColumns = pickBy(columns, (c) => c.searchable);

  const filtersModified = Object.values(filters).some(
    ({ params }) =>
      params !== undefined &&
      (typeof params !== 'object' || Object.keys(params).length > 0),
  );
  const sortModified = sort.length > 0;
  const searchModified = search !== '';

  return (
    <div className="component data-table-controls">
      <div className="control-labels-row">
        {header && <div className="header">{header}</div>}
        <ul className="control-labels">
          {extraControls.map((control) => (
            <li key={control.props.id}>
              <button
                aria-label={control.props.label}
                type="button"
                className={
                  selectedControl === control.props.id
                    ? 'control-button open'
                    : 'control-button closed'
                }
                onClick={() => {
                  setSelectedControl(
                    selectedControl === control.props.id
                      ? null
                      : control.props.id,
                  );
                }}
              >
                {control.props.icon}
              </button>
            </li>
          ))}
          {Object.keys(searchableColumns).length > 0 && (
            <li className="search-container">
              <button
                aria-label="search"
                type="button"
                className={classStr({
                  'control-button': true,
                  open: selectedControl === 'search',
                  modified: searchModified,
                })}
                onClick={() =>
                  setSelectedControl(
                    selectedControl === 'search' ? null : 'search',
                  )
                }
              >
                <SearchIcon />
              </button>
            </li>
          )}
          {Object.keys(sortableColumns).length > 0 && (
            <li>
              <button
                aria-label="sort"
                type="button"
                className={classStr({
                  'control-button': true,
                  open: selectedControl === 'sort',
                  modified: sortModified,
                })}
                onClick={() =>
                  setSelectedControl(selectedControl === 'sort' ? null : 'sort')
                }
              >
                <SortUpIcon />
              </button>
            </li>
          )}
          {Object.keys(filterableColumns).length > 0 && (
            <li>
              <button
                aria-label="filter"
                type="button"
                className={classStr({
                  'control-button': true,
                  open: selectedControl === 'filter',
                  modified: filtersModified,
                })}
                onClick={() =>
                  setSelectedControl(
                    selectedControl === 'filter' ? null : 'filter',
                  )
                }
              >
                <ControlsIcon />
              </button>
            </li>
          )}
          <li>
            <ActionMenu
              title={<ThreeDotsIcon orientation="horizontal" />}
              actions={allActions}
            />
          </li>
        </ul>
      </div>

      <div className="control-body">
        {selectedControl === 'filter' && (
          <FilterCollector
            columns={columns}
            filters={filters}
            setFilters={setFilters}
          />
        )}

        {selectedControl === 'sort' && (
          <SortCollector columns={columns} sort={sort} setSort={setSort} />
        )}

        {selectedControl === 'search' && (
          <SearchCollector
            search={search}
            setSearch={setSearch}
            columns={searchableColumns}
          />
        )}

        {selectedExtra && <DataTableControlBody {...selectedExtra.props} />}
      </div>
    </div>
  );
}
