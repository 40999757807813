import mapboxgl from 'mapbox-gl';
import { useEffect, useRef } from 'react';

export function Marker(props) {
  const { map, startingLngLat, onChange, draggable } = props;
  const marker = useRef();

  useEffect(() => {
    marker.current = new mapboxgl.Marker({
      draggable: draggable ?? true,
    })
      .setLngLat(startingLngLat)
      .addTo(map);

    if (onChange) {
      marker.current.on('dragend', (e) => onChange(e, marker.current));
    }

    return () => {
      marker.current.remove();
      marker.current = null;
    };
  }, []);
}
