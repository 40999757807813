import {
  Header,
  Drawer,
  LayersIcon,
  LegendIcon,
  CardContainer,
  Card,
  LayersCard,
  Select,
} from '@redzone/shared';
import { Outlet } from 'react-router-dom';
import { useRisk, useRiskState } from '../../context/RiskContext.jsx';
import { useUser } from '../../context/UserContext.jsx';
import { Map } from '../map/Map.jsx';
import { LegendCard } from '../tools/LegendCard.jsx';
import packageJsonData from '../../../package.json';
import './Risk.scss';

function OrgSelect({ orgs, selectedOrg, setSelectedOrg }) {
  if (orgs.length === 1) return null;

  const orgsList = orgs.map((o) => ({
    value: o,
    label: o,
  }));

  return (
    <div className="risk-container risk-org-select">
      <Select
        id="org-select"
        className="secondary"
        onChange={(value) => setSelectedOrg(orgs.filter((o) => o === value)[0])}
        options={orgsList}
        value={selectedOrg}
      />
    </div>
  );
}

export function Risk() {
  const { logout, user, orgs, selectedOrg, setSelectedOrg } = useUser();

  const [baseLayer] = useRiskState('baseLayer');
  const { isContentDrawerOpen, setIsContentDrawerOpen } = useRisk();

  return (
    <>
      <Header
        className="risk-app"
        logout={logout}
        user={user}
        helpLink="https://docs.redzone.co/rzrisk3-api/index.html"
        title="Risk3+"
        version={packageJsonData.version}
      >
        {orgs && (
          <OrgSelect
            orgs={orgs}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
          />
        )}
      </Header>

      <Drawer
        position="left"
        className="content-drawer risk-content-drawer"
        open={isContentDrawerOpen}
        setOpen={() => setIsContentDrawerOpen(!isContentDrawerOpen)}
      >
        <Outlet />
      </Drawer>

      <CardContainer>
        <Card id="layers" title="Map Layers" icon={<LayersIcon />}>
          <LayersCard id="layers" />
        </Card>
        <Card id="legend" title="Map Legends" icon={<LegendIcon />}>
          <LegendCard />
        </Card>
      </CardContainer>

      <Map baseLayer={baseLayer} />
    </>
  );
}
