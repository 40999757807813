import { useEffect, useRef } from 'react';
import { XIcon } from '../icons.js';
import './Dialog.scss';

export function Dialog(props) {
  const { children, className, onClose, open, title } = props;
  const dialogRef = useRef();

  useEffect(() => {
    if (open && !dialogRef.current.open) {
      dialogRef.current.showModal();
    } else if (!open && dialogRef.current.open) {
      dialogRef.current.close();
    }
  }, [open]);

  return (
    <dialog
      className={`component dialog${className ? ` ${className}` : ''}`}
      ref={dialogRef}
    >
      <header>
        <h2>{title}</h2>
        <button
          aria-label="Close"
          type="button"
          className="redwall unstyled close-button"
          onClick={() => onClose()}
        >
          <XIcon />
        </button>
      </header>

      {children}
    </dialog>
  );
}
