import { useMapEffect, useMap, Source, Layer } from '@redzone/map';
import { useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import getBbox from '@turf/bbox';
import intersects from '@turf/boolean-intersects';

const polygonLayer = (
  <Layer
    id="collector-layer"
    mapboxLayer={{
      id: 'layer',
      type: 'fill',
      paint: {
        'fill-color': '#088',
        'fill-opacity': 0.4,
        'fill-outline-color': 'blue',
      },
    }}
  />
);

const lineLayer = (
  <Layer
    id="measure-lines"
    mapboxLayer={{
      id: 'measure-lines',
      type: 'line',
      source: 'collector-src',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#300',
        'line-width': 8,
      },
    }}
  />
);

const pointLayer = (
  <Layer
    id="point"
    mapboxLayer={{
      id: 'point',
      type: 'circle',
      source: 'collector-src',
      paint: {
        'circle-radius': 3,
        'circle-color': '#d13239',
      },
    }}
  />
);

const typeMap = {
  line: { defaultMode: 'draw_line_string', layer: lineLayer },
  polygon: { defaultMode: 'draw_polygon', layer: polygonLayer },
  point: { defaultMode: 'draw_point', layer: pointLayer },
};

export function MapCollector(props) {
  const { id, layers, onChange, radius, showValue, type, value, ...rest } =
    props;
  const { defaultMode, layer } = typeMap[type];
  const [collecting, setCollecting] = useState(false);
  const { map } = useMap();

  const draw = new MapboxDraw({
    controls: {
      line_string: false,
      point: false,
      polygon: true,
      trash: true,
      combine_features: false,
      uncombine_features: false,
    },
    defaultMode,
  });

  useMapEffect(
    (readyMap) => {
      if (collecting) readyMap.addControl(draw);
    },
    [collecting],
  );

  const handleFinish = () => {
    const polygon = draw.getAll();
    const bbox = getBbox(polygon);
    const mapboxBbox = [
      map.project([bbox[0], bbox[1]]),
      map.project([bbox[2], bbox[3]]),
    ];

    const boundedFeatures = map.queryRenderedFeatures(mapboxBbox, {
      layers,
    });
    const features = boundedFeatures.filter((f) => intersects(polygon, f));

    onChange(polygon, features);
    setCollecting(false);
    map.removeControl(draw);
  };

  return collecting ? (
    <button {...rest} type="button" onClick={handleFinish}>
      Finish
    </button>
  ) : (
    <>
      <button
        type="button"
        {...rest}
        onClick={() => {
          setCollecting(true);
        }}
      />
      {showValue && (
        <Source id="collector-src" type="geojson" data={value}>
          {layer}
        </Source>
      )}
    </>
  );
}
