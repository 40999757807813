import React, { cloneElement, useState } from 'react';
import { toggle } from '../helpers.js';
import './ToolDrawer.scss';

export function ToolDrawer(props) {
  const { setOpen, children, onClose } = props;
  const child = React.Children.only(children);

  return cloneElement(child, {
    className: child.props?.className
      ? `${child.props.className} tool-drawer`
      : 'tool-drawer',
    setOpen,
    onClose,
  });
}

export function ToolDrawersContainer(props) {
  const { children, labelClassName } = props;
  const childArray = React.Children.toArray(children);
  const [toolsToShow, setToolsToShow] = useState([]);

  if (!childArray.every((child) => child.type === ToolDrawer)) {
    throw new Error(
      'All children of a `ToolDrawerContainer` must be a `ToolDrawer` component.',
    );
  }

  const fullLabelClassName = ['tool-drawer-labels', labelClassName]
    .filter(Boolean)
    .join(' ');

  const toggleTool = (toolId) => {
    setToolsToShow(toggle(toolsToShow, toolId));
  };

  const labels = childArray.map((child) => {
    const { id, icon, helpText } = child.props;

    const tabClassName = [
      'tab-label',
      toolsToShow.includes(id) ? 'selected' : null,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <li className={tabClassName} key={id}>
        <button
          type="button"
          className="tab-label-button"
          onClick={() => toggleTool(id)}
        >
          {icon}
          <div className="help-text">{helpText}</div>
        </button>
      </li>
    );
  });

  return (
    <>
      <div className={fullLabelClassName}>
        <ul className="standard-unordered-list tab-labels-list">{labels}</ul>
      </div>
      <div className="drawer-container right">
        {childArray
          .filter((child) => toolsToShow.includes(child.props.id))
          .map((i) => (
            <div className="tool-card" key={i.props.id}>
              {cloneElement(i, {
                onClose: () => toggleTool(i.props.id),
              })}
            </div>
          ))}
      </div>
    </>
  );
}
