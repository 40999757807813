import './NoPermissions.scss';

export function NoPermissions({ logout }) {
  return (
    <div className="component no-permissions" data-cy="access-denied">
      <div className="no-permissions-container">
        <span>Access Denied.</span>
        <span>
          Please contact{' '}
          <a href="mailto: techsupport@redzone.co">techsupport@redzone.co</a> if
          you require assistance.
        </span>
        <span />
        <button
          type="button"
          className="redwall primary"
          onClick={() => logout()}
        >
          LOGOUT
        </button>
      </div>
    </div>
  );
}
