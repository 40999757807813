import React, { useEffect, useState } from 'react';
import { Accordion, AccordionSection, IconInput } from '@redzone/shared';
import { useUser } from '../../context/UserContext.jsx';
import { Step } from './Step.jsx';
import { AalParams } from './AalParams.jsx';
import {
  INSIGHT_CONFIG,
  INSIGHT_CONFIG_SECTIONS,
  INSIGHT_INIT_VALUES,
} from '../../constants/Insight-config.js';
import { AAL_TIV_CONFIG, INIT_AAL_VALUES } from '../../constants/aal-config.js';

import {
  isDeductibleValid,
  isDeductibleWithTivValid,
} from '../helpers/helpers.js';

import './RefineParams.scss';

export function RefineParams(props) {
  const {
    titleText,
    setArgs,
    currentStep,
    totalSteps,
    setCurrentStep,
    handleNextStep,
    isOptional,
    pointLocation,
    insightValues,
    setInsightValues,
    aalValues,
    setAalValues,
  } = props;

  const { isInsight, isAal } = useUser();
  const isComplete = currentStep > 2;
  const isActive = currentStep === 2;

  const [primaryExpanded, setPrimaryExpanded] = useState('Primary');
  const [inputErrors, setInputErrors] = useState({});

  const [aalKey, setAalKey] = useState(1);

  const description = 'Please select any supplemental parameters.';

  useEffect(() => {
    if (pointLocation) return;
    // reset values if the user moved the marker
    setInsightValues(INSIGHT_INIT_VALUES);
    setAalValues(INIT_AAL_VALUES);
    setPrimaryExpanded('Primary');
  }, [pointLocation]);

  const validateTivComponents = () => {
    const {
      buildingInsuredValue,
      contentsInsuredValue,
      lossOfUseInsuredValue,
    } = aalValues;

    const areAllIntegers = [
      buildingInsuredValue,
      contentsInsuredValue,
      lossOfUseInsuredValue,
    ].every((i) => Number.isInteger(Number(i)));

    return {
      ...inputErrors,
      [AAL_TIV_CONFIG.totalInsuredValue.label]: !areAllIntegers,
    };
  };

  const validateInsightData = () =>
    Object.entries(insightValues)
      .map(([k, v]) => {
        const prop = INSIGHT_CONFIG.filter((i) => i.id === k)[0];
        if (!prop.validation) return;
        return { [prop.label]: prop.validation(v) };
      })
      .filter((i) => i);

  const validateData = () => {
    const aalErrorsList = validateTivComponents();
    const insightErrorsList = validateInsightData();
    const aalDeductibleValue = aalValues.siteDeductibleAsValue && {
      [AAL_TIV_CONFIG.siteDeductibleAsValue.label]:
        !isDeductibleWithTivValid(aalValues),
    };
    const aalDeductiblePercent = aalValues.siteDeductibleAsPctOfTotal && {
      [AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label]: !isDeductibleValid(
        aalValues.siteDeductibleAsPctOfTotal,
      ),
    };

    const allErrors = {
      ...inputErrors,
      ...aalErrorsList,
      ...aalDeductibleValue,
      ...aalDeductiblePercent,
      ...insightErrorsList.reduce(
        (prev, current) => ({
          ...prev,
          ...current,
        }),
        {},
      ),
    };
    setInputErrors(allErrors);
    return Object.values(allErrors).some((i) => i);
  };

  return (
    <div
      className={`step-wrapper ${!isComplete && 'incomplete'} ${
        isActive && 'active'
      } component refine-params`}
    >
      <Step
        titleText={titleText}
        description={description}
        step={2}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        totalSteps={totalSteps}
        isComplete={isComplete}
        isActive={isActive}
        isOptional={isOptional}
      />
      <div
        className={`step-container ${!isComplete && isActive ? 'show' : ''}`}
      >
        {isInsight && (
          <>
            <div className="params-title">RZRISK3+ Parameters</div>
            <div className="insight-accordion-container">
              {INSIGHT_CONFIG_SECTIONS.map((section, i) => (
                <Accordion
                  key={`${section.name}-key_${JSON.stringify(pointLocation)}`}
                  expanded={i === 0 ? primaryExpanded : null}
                  setExpanded={i === 0 ? setPrimaryExpanded : null}
                >
                  <AccordionSection label={section.name} id={section.name}>
                    {INSIGHT_CONFIG.filter((config) =>
                      section.ids.includes(config.id),
                    ).map((config) => (
                      <ul className="insight-param" key={config.id}>
                        <label
                          className={`${config.comments ? 'has-comments' : ''}`}
                        >
                          {config.label}
                        </label>
                        {config.comments && (
                          <div className="option-comment">
                            *{config.comments}
                          </div>
                        )}
                        {config.options.map((option) => (
                          <li
                            className="insight-option input-row"
                            key={`${config.id}-${option.value}`}
                          >
                            {option.type === 'number' ? (
                              <>
                                {inputErrors[config.label] && (
                                  <p className="option-error">
                                    {config.errorMessage}
                                  </p>
                                )}
                                <IconInput
                                  type="number"
                                  id={`${config.id}-${option.value}`}
                                  className={
                                    inputErrors[config.id] ? 'invalid' : ''
                                  }
                                  name={config.id}
                                  placeholder={option.placeholder}
                                  value={
                                    insightValues[config.id] === 0
                                      ? ''
                                      : insightValues[config.id]
                                  }
                                  onChange={(e) => {
                                    setInsightValues({
                                      ...insightValues,
                                      [config.id]:
                                        e.target.value === ''
                                          ? 0
                                          : e.target.value,
                                    });
                                  }}
                                  onKeyUp={() => {
                                    if (inputErrors[config.label]) {
                                      setInputErrors({
                                        ...inputErrors,
                                        [config.label]: config.validation(
                                          insightValues[config.id],
                                        ),
                                      });
                                    }
                                  }}
                                  onBlur={() =>
                                    setInputErrors({
                                      ...inputErrors,
                                      [config.label]: config.validation(
                                        insightValues[config.id],
                                      ),
                                    })
                                  }
                                  data-cy={`${config.id}-${option.value}`}
                                />
                              </>
                            ) : (
                              <input
                                type="radio"
                                id={`${config.id}-${option.value}`}
                                name={config.id}
                                value={option.value}
                                checked={
                                  option.value === insightValues[config.id]
                                }
                                onChange={(e) => {
                                  setInsightValues({
                                    ...insightValues,
                                    [config.id]: Number(e.target.value),
                                  });
                                }}
                                data-cy={`${config.id}-${option.value}`}
                              />
                            )}
                            <label
                              htmlFor={`${config.id}-${option.value}`}
                              className="params-label"
                            >
                              {option.label}
                            </label>
                          </li>
                        ))}
                      </ul>
                    ))}
                  </AccordionSection>
                </Accordion>
              ))}
            </div>
          </>
        )}

        {isAal && (
          <>
            <div className="params-title">Average Annual Loss</div>
            <AalParams
              key={aalKey}
              aalValues={aalValues}
              setAalValues={setAalValues}
              inputErrors={inputErrors}
              setInputErrors={setInputErrors}
              validateTivComponents={validateTivComponents}
            />
          </>
        )}

        {Object.values(inputErrors).some((i) => i) && (
          <ul className="input-errors" data-cy="input-errors">
            {Object.entries(inputErrors)
              .filter(([k, v]) => v)
              .map(([k]) => (
                <li key={k}>{k} is invalid.</li>
              ))}
          </ul>
        )}

        <div className="button-container">
          <button
            type="button"
            className="redwall primary"
            onClick={() => {
              if (validateData()) return;
              handleNextStep({
                insightScoring: insightValues,
                averageAnnualLoss: aalValues,
              });
            }}
            data-cy="save-and-continue"
          >
            Save and Continue
          </button>
          <button
            type="button"
            className="redwall secondary"
            onClick={() => {
              setAalKey(aalKey + 1);
              setArgs(null);
              setAalValues(INIT_AAL_VALUES);
              setInsightValues(INSIGHT_INIT_VALUES);
              setInputErrors({});
              document
                .getElementsByClassName('select-location')[0]
                .scrollIntoView({
                  behavior: 'smooth',
                });
            }}
            data-cy="clear-params"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
}
