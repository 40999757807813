import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { jwtDecode } from 'jwt-decode';
import { NoPermissions } from '../components/NoPermissions.jsx';
import { authTransport } from '../stores/transport.js';
import {
  RISK_FE_PERMISSION,
  RISK_SCORING_PERMISSION,
  INSIGHT_SCORING_PERMISSION,
  AVERAGE_ANNUAL_LOSS_PERMISSION,
} from '../constants/permissions.js';
import { hasPermission } from '../components/helpers/helpers.js';

const User = createContext({});

export function useUser() {
  return useContext(User);
}

export function UserProvider({ children }) {
  const {
    logout: auth0Logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [token, setToken] = useState(null);

  const [isInsight, setIsInsight] = useState(false);
  const [isAal, setIsAal] = useState(false);
  const [orgs, setOrgs] = useState(null);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [grants, setGrants] = useState(null);
  const [noPermissions, setNoPermissions] = useState(false);
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);

  const getUser = useCallback(async () => {
    try {
      setLoadingUser(true);
      setNoPermissions(false);

      const accessToken = await getAccessTokenSilently();
      localStorage.setItem('access-token', accessToken);
      setToken(accessToken);
      console.log('accessToken', accessToken);

      const idToken = await getIdTokenClaims();
      const userDetails = jwtDecode(idToken.__raw); // eslint-disable-line no-underscore-dangle
      setUser(userDetails);

      const { grants: grantsData } = await authTransport.getData(
        '/RZUSER00/user/info',
      );
      setGrants(grantsData);

      /* NOTE (Baad):
        we are checking the RISK_FE_PERMISSION against the `RZUSER00` organization because its attached to the synthetic org.
        this might change soon, so we will need to update this. we need to follow up with Todd.
       */
      if (!hasPermission(grantsData, 'RZUSER00', RISK_FE_PERMISSION))
        throw new Error('this user does not have risk permissions');

      const orgsWithRoles = grantsData.filter((grant) =>
        grant.roles.some((role) =>
          role.permissions.some(
            (perm) => perm.scope === RISK_SCORING_PERMISSION,
          ),
        ),
      );
      if (orgsWithRoles.length === 0) throw new Error('no risk permissions');
      const orgList = [...new Set(orgsWithRoles.map((i) => i.org_key))];
      setOrgs(orgList);
      setSelectedOrg(orgList[0]);
    } catch (e) {
      console.log('NO RISK PERMISSIONS', token, e);
      setNoPermissions(true);
    } finally {
      setLoadingUser(false);
    }
  }, []);

  const logout = () => {
    auth0Logout({
      logoutParams: { returnTo: `${window.location.origin}/risk` },
    });
  };

  useEffect(() => {
    if (!selectedOrg) return;
    setIsInsight(
      hasPermission(grants, selectedOrg, INSIGHT_SCORING_PERMISSION),
    );
    setIsAal(
      hasPermission(grants, selectedOrg, AVERAGE_ANNUAL_LOSS_PERMISSION),
    );
  }, [selectedOrg]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const value = useMemo(() => ({
    logout,
    token,
    user,
    isInsight,
    isAal,
    noPermissions,
    loadingUser,
    orgs,
    selectedOrg,
    setSelectedOrg,
    grants,
  }));

  return (
    <User.Provider value={value}>
      {noPermissions ? <NoPermissions logout={logout} /> : children}
    </User.Provider>
  );
}
