export function CloseButtonIcon() {
  return (
    <svg
      viewBox="0 0 128 128"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="50%" cy="50%" r="50%" />
      <line x1="40" x2="88" y1="88" y2="40" strokeWidth="15" />
      <line x1="40" x2="88" y1="40" y2="88" strokeWidth="15" />
    </svg>
  );
}
