import React from 'react';
import { XFillIcon, SearchIcon } from '../icons.js';
import './IconInput.scss';
import './SearchInput.scss';

export function SearchInput(props) {
  const { innerRef, value, onClear, fontSize, ...rest } = props;

  return (
    <div className="search-input icon-input component">
      <input
        className={`redwall ${fontSize ?? 'md'} regular left`}
        ref={innerRef}
        value={value}
        {...rest}
      />
      <SearchIcon className="icon left light" />
      {value !== '' && (
        <button
          className="clear-button redwall unstyled"
          onClick={() => onClear()}
        >
          <XFillIcon className="clear-icon" />
        </button>
      )}
    </div>
  );
}
