import { useEffect, useRef } from 'react';
import { DataTableControlBody } from './DataTableControlBody.jsx';
import { SearchInput } from '@redzone/shared';
import './SearchCollector.scss';

export function SearchCollector(props) {
  const { columns, search, setSearch } = props;
  const columnNames = Object.values(columns).map((c) => c.title);

  const searchInputRef = useRef();

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  return (
    <DataTableControlBody
      title="Search"
      onReset={() => setSearch('')}
      resetText="Clear"
      className="component search-collector primary-form"
    >
      <div className="input-row">
        <SearchInput
          aria-label="search"
          id="data-table-search"
          innerRef={searchInputRef}
          type="search"
          fontSize="sm"
          value={search}
          placeholder={`Search ${columnNames.join(', ')}...`}
          onChange={(e) => setSearch(e.target.value)}
          onClear={() => setSearch('')}
        />
      </div>
    </DataTableControlBody>
  );
}
