import { useState } from "react";

export function BooleanCollector(props) {
  const { value, setValue } = props;
  const [preset, setPreset] = useState(
    value === undefined ? 'any' : value === false ? 'no' : 'yes',
  );

  return (
    <div className="boolean-collector">
      <div className="preset-row">
        <input
          id="number-range-any"
          className="preset"
          type="radio"
          onChange={() => {
            setValue(undefined);
            setPreset('any');
          }}
          checked={preset === 'any'}
        />
        <label htmlFor="number-range-any">Any</label>
      </div>
      <div className="preset-row">
        <input
          id="number-range-yes"
          className="preset"
          type="radio"
          onChange={() => {
            setValue(true);
            setPreset('yes');
          }}
          checked={preset === 'yes'}
        />
        <label htmlFor="number-range-yes">Yes</label>
      </div>
      <div className="preset-row">
        <input
          id="number-range-no"
          className="preset"
          type="radio"
          onChange={() => {
            setValue(false);
            setPreset('no');
          }}
          checked={preset === 'no'}
        />
        <label htmlFor="number-range-no">No</label>
      </div>
    </div>
  );
}