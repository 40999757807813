export function VideoIcon() {
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.1668 5.83334L13.3335 10L19.1668 14.1667V5.83334Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 4.16666H2.50016C1.57969 4.16666 0.833496 4.91285 0.833496 5.83332V14.1667C0.833496 15.0871 1.57969 15.8333 2.50016 15.8333H11.6668C12.5873 15.8333 13.3335 15.0871 13.3335 14.1667V5.83332C13.3335 4.91285 12.5873 4.16666 11.6668 4.16666Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
