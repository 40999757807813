export function PinIcon() {
  return (
    <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1365_2320)">
        <path d="M4.3125 7.6875L1.3125 10.6875ZM10.6875 5.0625L6.9375 1.3125C6.9375 1.3125 6.1875 2.8125 5.4375 3.5625C4.6875 4.3125 2.0625 5.0625 2.0625 5.0625L6.9375 9.9375C6.9375 9.9375 7.6875 7.3125 8.4375 6.5625C9.1875 5.8125 10.6875 5.0625 10.6875 5.0625Z" />
        <path
          d="M4.3125 7.6875L1.3125 10.6875M10.6875 5.0625L6.9375 1.3125C6.9375 1.3125 6.1875 2.8125 5.4375 3.5625C4.6875 4.3125 2.0625 5.0625 2.0625 5.0625L6.9375 9.9375C6.9375 9.9375 7.6875 7.3125 8.4375 6.5625C9.1875 5.8125 10.6875 5.0625 10.6875 5.0625Z"
          strokeWidth="1.125"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1365_2320">
          <rect width="12" height="12" transform="matrix(-1 0 0 1 12 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}
