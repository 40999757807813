export function SortUpIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3033 3.46967C15.5962 3.17678 16.0711 3.17678 16.364 3.46967L18.864 5.96967C19.1569 6.26256 19.1569 6.73744 18.864 7.03033C18.5711 7.32322 18.0962 7.32322 17.8033 7.03033L16.5837 5.81066V12.3333C16.5837 12.7475 16.2479 13.0833 15.8337 13.0833C15.4194 13.0833 15.0837 12.7475 15.0837 12.3333V5.81066L13.864 7.03033C13.5711 7.32322 13.0962 7.32322 12.8033 7.03033C12.5104 6.73744 12.5104 6.26256 12.8033 5.96967L15.3033 3.46967ZM0.916992 5.66667C0.916992 5.25245 1.25278 4.91667 1.66699 4.91667H5.00033C5.41454 4.91667 5.75033 5.25245 5.75033 5.66667C5.75033 6.08088 5.41454 6.41667 5.00033 6.41667H1.66699C1.25278 6.41667 0.916992 6.08088 0.916992 5.66667ZM0.916992 9C0.916992 8.58579 1.25278 8.25 1.66699 8.25H8.33366C8.74787 8.25 9.08366 8.58579 9.08366 9C9.08366 9.41421 8.74787 9.75 8.33366 9.75H1.66699C1.25278 9.75 0.916992 9.41421 0.916992 9ZM0.916992 12.3333C0.916992 11.9191 1.25278 11.5833 1.66699 11.5833H11.667C12.0812 11.5833 12.417 11.9191 12.417 12.3333C12.417 12.7475 12.0812 13.0833 11.667 13.0833H1.66699C1.25278 13.0833 0.916992 12.7475 0.916992 12.3333ZM0.916992 15.6667C0.916992 15.2525 1.25278 14.9167 1.66699 14.9167H15.0003C15.4145 14.9167 15.7503 15.2525 15.7503 15.6667C15.7503 16.0809 15.4145 16.4167 15.0003 16.4167H1.66699C1.25278 16.4167 0.916992 16.0809 0.916992 15.6667Z"
      />
    </svg>
  );
}
