import React, { useEffect, useState } from 'react';
import { CardSection, useMapState } from '@redzone/shared';
import './LegendCard.scss';

const legendConfig = [
  // TODO: comment those out until we can have a toggelable Risk layer
  // {
  //   layerId: 'risk',
  //   title: 'INTERFACE',
  //   list: [
  //     { color: 'color-idx-4', label: 'high' },
  //     { color: 'color-idx-4', label: 'moderate' },
  //     { color: 'color-idx-4', label: 'low' },
  //     { color: 'color-idx-5', label: 'smoke risk' },
  //   ],
  // },
  // {
  //   layerId: 'risk',
  //   title: 'INTERMIX',
  //   list: [
  //     { color: 'color-idx-0', label: 'very high' },
  //     { color: 'color-idx-1', label: 'high' },
  //     { color: 'color-idx-2', label: 'moderate' },
  //     { color: 'color-idx-3', label: 'low' },
  //   ],
  // },
  // {
  //   layerId: 'risk',
  //   title: 'WILDLAND',
  //   list: [
  //     { color: 'color-idx-6', label: 'very high' },
  //     { color: 'color-idx-7', label: 'high' },
  //     { color: 'color-idx-8', label: 'moderate' },
  //     { color: 'color-idx-9', label: 'low' },
  //   ],
  // },
  {
    layerId: 'historical_fires_perimeter',
    title: 'HISTORICAL FIRE PERIMETER',
    list: [
      { color: 'color-idx-10', label: `2020-${new Date().getFullYear()}` },
      { color: 'color-idx-11', label: '2010-2020' },
      { color: 'color-idx-12', label: '2000-2010' },
      { color: 'color-idx-13', label: '1973-2000' },
    ],
  },
];

const legendSection = (i) => (
  <CardSection title={i.title} key={`legend_${i.layerId}_${i.title}`}>
    <div className="badge-list">
      {i.list.map((b) => (
        <div className="badge-item" key={`${i.title}_${b.label}`}>
          <div className={`badge ${b.color}`} />
          {b.label}
        </div>
      ))}
    </div>
  </CardSection>
);

export function LegendCard() {
  const { selectedCustomLayers, baseLayer } = useMapState();
  const [legendsToShow, setLegendsToShow] = useState([]);

  useEffect(() => {
    setLegendsToShow([
      ...selectedCustomLayers.map((l) => l.layerId),
      baseLayer,
    ]);
  }, [selectedCustomLayers, baseLayer]);

  return (
    <div className="component legend-card">
      {legendConfig
        .filter((i) => legendsToShow.includes(i.layerId))
        .map((i) => legendSection(i))}

      {legendConfig.filter((i) => legendsToShow.includes(i.layerId)).length ===
        0 && (
        <div className="empty-state">
          <h3>No layers selected</h3>
          <p>Once you select a layer, its legend will appear here.</p>
        </div>
      )}
    </div>
  );
}
