import React, { useState } from 'react';
import {
  Accordion,
  AccordionSection,
  IconInput,
  DollarIcon,
  PercentIcon,
} from '@redzone/shared';
import { AAL_TIV_CONFIG } from '../../constants/aal-config';
import {
  isDeductibleValid,
  isDeductibleWithTivValid,
} from '../helpers/helpers';

export function AalParams(props) {
  const {
    aalValues,
    setAalValues,
    inputErrors,
    setInputErrors,
    validateTivComponents,
  } = props;

  const [deductibleType, setDeductibleType] = useState('');
  const [tivMode, setTivMode] = useState(true);

  return (
    <div className="insight-accordion-container">
      <Accordion key="Insured Value-key-accordion">
        <AccordionSection label="Insured Value" id="Insured Value">
          <ul className="insight-param aal-param">
            <li className="insight-option aal-option input-row" key="tivMode">
              <input
                type="radio"
                id="tivMode-radio"
                name="tivMode-radio"
                value={tivMode}
                checked={tivMode}
                onChange={() => {
                  setTivMode(!tivMode);
                  setAalValues({
                    ...aalValues,
                    [AAL_TIV_CONFIG.totalInsuredValue.id]: '',
                    buildingInsuredValue: '',
                    contentsInsuredValue: '',
                    lossOfUseInsuredValue: '',
                  });
                }}
                data-cy="tiv-mode-value"
              />
              <label htmlFor="tivMode-radio" className="params-label">
                {AAL_TIV_CONFIG.totalInsuredValue.label}
              </label>
            </li>
            {tivMode && (
              <li
                className="insight-option aal-option-input"
                key={AAL_TIV_CONFIG.totalInsuredValue.id}
              >
                <IconInput
                  type="number"
                  pattern="[0-9]*"
                  min="0"
                  id={AAL_TIV_CONFIG.totalInsuredValue.id}
                  name={AAL_TIV_CONFIG.totalInsuredValue.id}
                  placeholder={AAL_TIV_CONFIG.totalInsuredValue.placeholder}
                  value={
                    aalValues.totalInsuredValue === 0
                      ? ''
                      : aalValues.totalInsuredValue
                  }
                  disabled={!tivMode}
                  onChange={(e) => {
                    setAalValues({
                      ...aalValues,
                      [AAL_TIV_CONFIG.totalInsuredValue.id]: Number(
                        e.target.value,
                      ),
                    });
                  }}
                  icon={DollarIcon}
                  onWheel={(e) => e.target.blur()}
                  data-cy="tiv-value-input"
                />
              </li>
            )}
            <li
              className="insight-option aal-option input-row"
              key="components"
            >
              <input
                type="radio"
                id="components-radio"
                name="components-radio"
                value={!tivMode}
                checked={!tivMode}
                onChange={() => {
                  setAalValues({
                    ...aalValues,
                    totalInsuredValue: '',
                  });
                  setTivMode(!tivMode);
                }}
                data-cy="tiv-mode-components"
              />
              <label htmlFor="components-radio" className="params-label">
                Total Insured Value Components
              </label>
            </li>
            {!tivMode && (
              <>
                {inputErrors[AAL_TIV_CONFIG.totalInsuredValue.label] && (
                  <div className="option-comment">
                    * Value must be an integer
                  </div>
                )}
                <label className="aal-label">
                  {AAL_TIV_CONFIG.buildingInsuredValue.label}
                </label>
                <li
                  className="insight-option aal-option-input input-row"
                  key={AAL_TIV_CONFIG.buildingInsuredValue.id}
                >
                  <IconInput
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    id={AAL_TIV_CONFIG.buildingInsuredValue.id}
                    name={AAL_TIV_CONFIG.buildingInsuredValue.id}
                    placeholder={
                      AAL_TIV_CONFIG.buildingInsuredValue.placeholder
                    }
                    value={
                      aalValues.buildingInsuredValue === 0
                        ? ''
                        : aalValues.buildingInsuredValue
                    }
                    disabled={tivMode}
                    onChange={(e) => {
                      setAalValues({
                        ...aalValues,
                        [AAL_TIV_CONFIG.buildingInsuredValue.id]: Number(
                          e.target.value,
                        ),
                      });
                    }}
                    onKeyUp={() => {
                      if (inputErrors[AAL_TIV_CONFIG.totalInsuredValue.label]) {
                        setInputErrors({
                          ...inputErrors,
                          [AAL_TIV_CONFIG.totalInsuredValue.label]:
                            !Number.isInteger(
                              Number(
                                aalValues[
                                  AAL_TIV_CONFIG.buildingInsuredValue.id
                                ],
                              ),
                            ),
                        });
                      }
                    }}
                    onBlur={() => validateTivComponents()}
                    icon={DollarIcon}
                    onWheel={(e) => e.target.blur()}
                    data-cy="building-value-input"
                  />
                </li>
                <label className="aal-label">
                  {AAL_TIV_CONFIG.contentsInsuredValue.label}
                </label>
                <li
                  className="insight-option aal-option-input input-row"
                  key={AAL_TIV_CONFIG.contentsInsuredValue.id}
                >
                  <IconInput
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    id={AAL_TIV_CONFIG.contentsInsuredValue.id}
                    name={AAL_TIV_CONFIG.contentsInsuredValue.id}
                    placeholder={
                      AAL_TIV_CONFIG.contentsInsuredValue.placeholder
                    }
                    value={
                      aalValues[AAL_TIV_CONFIG.contentsInsuredValue.id] === 0
                        ? ''
                        : aalValues[AAL_TIV_CONFIG.contentsInsuredValue.id]
                    }
                    disabled={tivMode}
                    onChange={(e) => {
                      setAalValues({
                        ...aalValues,
                        [AAL_TIV_CONFIG.contentsInsuredValue.id]: Number(
                          e.target.value,
                        ),
                      });
                    }}
                    onKeyUp={() => {
                      if (inputErrors[AAL_TIV_CONFIG.totalInsuredValue.label]) {
                        setInputErrors({
                          ...inputErrors,
                          [AAL_TIV_CONFIG.totalInsuredValue.label]:
                            !Number.isInteger(
                              Number(
                                aalValues[
                                  AAL_TIV_CONFIG.buildingInsuredValue.id
                                ],
                              ),
                            ),
                        });
                      }
                    }}
                    onBlur={() => validateTivComponents()}
                    icon={DollarIcon}
                    onWheel={(e) => e.target.blur()}
                    data-cy="contents-value-input"
                  />
                </li>
                <label className="aal-label">
                  {AAL_TIV_CONFIG.lossOfUseInsuredValue.label}
                </label>
                <li
                  className="insight-option aal-option-input input-row"
                  key={AAL_TIV_CONFIG.lossOfUseInsuredValue.id}
                >
                  <IconInput
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    id={AAL_TIV_CONFIG.lossOfUseInsuredValue.id}
                    name={AAL_TIV_CONFIG.lossOfUseInsuredValue.id}
                    placeholder={
                      AAL_TIV_CONFIG.lossOfUseInsuredValue.placeholder
                    }
                    value={
                      aalValues.lossOfUseInsuredValue === 0
                        ? ''
                        : aalValues.lossOfUseInsuredValue
                    }
                    disabled={tivMode}
                    onChange={(e) => {
                      setAalValues({
                        ...aalValues,
                        [AAL_TIV_CONFIG.lossOfUseInsuredValue.id]: Number(
                          e.target.value,
                        ),
                      });
                    }}
                    onKeyUp={() => {
                      if (inputErrors[AAL_TIV_CONFIG.totalInsuredValue.label]) {
                        setInputErrors({
                          ...inputErrors,
                          [AAL_TIV_CONFIG.totalInsuredValue.label]:
                            !Number.isInteger(
                              Number(
                                aalValues[
                                  AAL_TIV_CONFIG.buildingInsuredValue.id
                                ],
                              ),
                            ),
                        });
                      }
                    }}
                    onBlur={() => validateTivComponents()}
                    icon={DollarIcon}
                    onWheel={(e) => e.target.blur()}
                    data-cy="loss-of-use-value-input"
                  />
                </li>
              </>
            )}
          </ul>
        </AccordionSection>
      </Accordion>
      <Accordion>
        <AccordionSection label="Deductible" id="deductible">
          <ul className="insight-param aal-param" key="deductibles-list">
            <li
              className="insight-option aal-option input-row"
              key="siteDeductibleAsValue"
            >
              <input
                type="radio"
                id="siteDeductibleAsValue-radio"
                name="siteDeductibleAsValue-radio"
                value="siteDeductibleAsValue"
                checked={deductibleType === 'siteDeductibleAsValue'}
                onChange={() => setDeductibleType('siteDeductibleAsValue')}
                data-cy="deductible-type-value"
              />
              <label
                htmlFor="siteDeductibleAsValue-radio"
                className="params-label"
              >
                {AAL_TIV_CONFIG.siteDeductibleAsValue.label}
              </label>
            </li>
            {deductibleType === 'siteDeductibleAsValue' && (
              <>
                {inputErrors[AAL_TIV_CONFIG.siteDeductibleAsValue.label] && (
                  <div
                    className="option-comment"
                    data-cy="deductible-range-error"
                  >
                    * Value must be less or Equal to Total Insured Value
                  </div>
                )}
                <li
                  className="insight-option aal-option-input input-row"
                  key="siteDeductibleAsValue-input"
                >
                  <IconInput
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    id="siteDeductibleAsValue"
                    name="siteDeductibleAsValue"
                    value={
                      aalValues.siteDeductibleAsValue === 0
                        ? ''
                        : aalValues.siteDeductibleAsValue
                    }
                    onChange={(e) => {
                      const { siteDeductibleAsPctOfTotal, ...rest } = aalValues;
                      const newAalValues = {
                        ...rest,
                        siteDeductibleAsValue: Number(e.target.value),
                      };
                      setAalValues(newAalValues);
                    }}
                    onBlur={() => {
                      setInputErrors({
                        ...inputErrors,
                        [AAL_TIV_CONFIG.siteDeductibleAsValue.label]:
                          !isDeductibleWithTivValid(aalValues),
                      });
                    }}
                    onKeyUp={() => {
                      if (
                        inputErrors[
                          [AAL_TIV_CONFIG.siteDeductibleAsValue.label]
                        ]
                      ) {
                        setInputErrors({
                          ...inputErrors,
                          [AAL_TIV_CONFIG.siteDeductibleAsValue.label]:
                            !isDeductibleWithTivValid(aalValues),
                        });
                      }
                    }}
                    icon={DollarIcon}
                    data-cy="deductible-value-input"
                  />
                </li>
              </>
            )}
            <li
              className="insight-option aal-option input-row"
              key="siteDeductibleAsPctOfTotal"
            >
              <input
                type="radio"
                id="siteDeductibleAsPctOfTotal-radio"
                name="siteDeductibleAsPctOfTotal-radio"
                value="siteDeductibleAsPctOfTotal"
                checked={deductibleType === 'siteDeductibleAsPctOfTotal'}
                onChange={() => setDeductibleType('siteDeductibleAsPctOfTotal')}
                data-cy="deductible-type-pct"
              />
              <label
                htmlFor="siteDeductibleAsPctOfTotal-radio"
                className="params-label"
              >
                {AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label}
              </label>
            </li>
            {deductibleType === 'siteDeductibleAsPctOfTotal' && (
              <>
                {inputErrors[
                  AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label
                ] && (
                  <div
                    className="option-comment"
                    data-cy="deductible-pct-error"
                  >
                    * Value must be between 0-100
                  </div>
                )}
                <li
                  className="insight-option aal-option-input input-row"
                  key="siteDeductibleAsPctOfTotal-input"
                >
                  <IconInput
                    type="number"
                    pattern="[0-9]*"
                    min="0"
                    max="100"
                    id="siteDeductibleAsPctOfTotal"
                    name="siteDeductibleAsPctOfTotal"
                    value={
                      aalValues.siteDeductibleAsPctOfTotal === 0
                        ? ''
                        : aalValues.siteDeductibleAsPctOfTotal
                    }
                    onChange={(e) => {
                      const { siteDeductibleAsValue, ...rest } = aalValues;
                      setAalValues({
                        ...rest,
                        siteDeductibleAsPctOfTotal: Number(e.target.value),
                      });
                    }}
                    onKeyUp={() => {
                      if (
                        inputErrors[
                          AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label
                        ]
                      ) {
                        setInputErrors({
                          ...inputErrors,
                          [AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label]:
                            !isDeductibleValid(e.target.value),
                        });
                      }
                    }}
                    onBlur={() => {
                      setInputErrors({
                        ...inputErrors,
                        [AAL_TIV_CONFIG.siteDeductibleAsPctOfTotal.label]:
                          !isDeductibleValid(
                            aalValues.siteDeductibleAsPctOfTotal,
                          ),
                      });
                    }}
                    iconSide="right"
                    icon={PercentIcon}
                    data-cy="deductible-pct-input"
                  />
                </li>
              </>
            )}
          </ul>
        </AccordionSection>
      </Accordion>
    </div>
  );
}
