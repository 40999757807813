export function ClockIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.99939 17.0015C13.8654 17.0015 16.9994 13.8675 16.9994 10.0015C16.9994 6.13547 13.8654 3.00146 9.99939 3.00146C6.1334 3.00146 2.99939 6.13547 2.99939 10.0015C2.99939 13.8675 6.1334 17.0015 9.99939 17.0015Z"
        stroke="#79716B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99939 5.80225V10.0022L12.7994 11.4022"
        stroke="#79716B"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
