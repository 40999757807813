import { useRef, useState } from 'react';
import getCircle from '@turf/circle';
import { Layer, Source, useMap, useMapEffect } from '@redzone/map';
import { RedwallInput } from '@redzone/shared';
import './InnerCircleCollector.scss';

export function InnerCircleCollector(props) {
  const { id, instructions, toggleCollecting, onChange } = props;
  const [radius, setRadius] = useState(10);
  const [collecting, setCollecting] = useState(true);
  const [center, setCenter] = useState();
  const [error, setError] = useState(false);
  const mouseEventActive = useRef(false);
  const { map } = useMap();
  const radiusRef = useRef(10);

  radiusRef.current = radius;

  const sourceId = `${id}-circle-source`;
  const layerId = `${id}-circle-layer`;

  const circlePolygon = center
    ? getCircle(center, radius, { units: 'miles' })
    : null;
  const data = {
    type: 'FeatureCollection',
    features: circlePolygon ? [circlePolygon] : [],
  };

  const source = {
    id: sourceId,
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  };

  const handleMove = (e) => {
    const mapboxSource = map.getSource(sourceId);
    if (mapboxSource) {
      setCenter([e.lngLat.lng, e.lngLat.lat]);
    }
  };

  const handleClear = () => {
    setCenter(null);
    setCollecting(true);
  };

  const handlePlacement = (e) => {
    handleMove(e);
    setCollecting(false);
  };

  const handleFinish = () => {
    if (center) {
      setError(false);
      onChange(data);
    } else {
      setError(true);
    }
  };

  useMapEffect(
    (readyMap) => {
      if (collecting && !mouseEventActive.current) {
        readyMap.on('mousemove', handleMove);
        readyMap.on('click', handlePlacement);
        mouseEventActive.current = true;
      }
    },
    (readyMap) => {
      if (mouseEventActive.current) {
        readyMap.off('mousemove', handleMove);
        readyMap.off('click', handlePlacement);
        mouseEventActive.current = false;
      }
    },
    [collecting],
  );

  return (
    <div className="inner-circle-collector component">
      {instructions && <div className="instructions">{instructions}</div>}
      <form className="neutral">
        <div className="input-row">
          <label htmlFor="select-policies-radius">Radius (miles)</label>
          <RedwallInput
            id="select-policies-radius"
            className="redwall secondary select-policies-radius"
            type="number"
            min="0"
            value={radius}
            onChange={(e) => setRadius(e.target.value)}
          />
        </div>
      </form>

      <footer>
        <button
          type="button"
          className="redwall secondary"
          onClick={handleFinish}
        >
          Finish
        </button>
        <button
          type="button"
          className="redwall unstyled"
          onClick={handleClear}
        >
          Clear
        </button>
        <button
          type="button"
          className="redwall unstyled cancel"
          onClick={toggleCollecting}
        >
          Cancel
        </button>
      </footer>

      {error && (
        <div className="error">
          Place the circle before pressing &ldquo;Finish.&rdquo;
        </div>
      )}

      <Source {...source} data={data}>
        <Layer
          id={layerId}
          mapboxLayer={{
            id: layerId,
            type: 'fill',
            paint: {
              'fill-color': '#008888',
              'fill-opacity': 0.2,
              'fill-outline-color': 'blue',
            },
          }}
        />
      </Source>
    </div>
  );
}
